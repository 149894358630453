import React from 'react'
import { toast } from 'react-toastify'
import { truncateString } from '@dextoroprotocol/sdk/utils'
import {
	Container,
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'
import SuccessIcon from 'assets/svg/app/success-new.svg'

type tnsDetailsProps = { funds?: string; blockExplorerLink?: string }

function ToastContent({ message, tnsDetails }: { message: string; tnsDetails?: tnsDetailsProps }) {
	return (
		<Container onClick={() => window.open(tnsDetails?.blockExplorerLink, '_blank')}>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv type="success">
						<SuccessIcon />
					</NotificationIconDiv>
					{message}
				</NotificationTitle>
				{tnsDetails ? (
					<NotificationDetails text={`Your withdraw of ${tnsDetails?.funds} has been confirmed.`} />
				) : (
					<></>
				)}
			</NotificationContent>
		</Container>
	)
}

export const notifyWithdraw = (message: string, tnsDetails?: tnsDetailsProps) => {
	const truncated = truncateString(message)
	toast.info(<ToastContent message={truncated} tnsDetails={tnsDetails} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'notifications',
	})
}
