import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useState, useMemo } from 'react'
import { truncateAddress, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'
import { ethers } from 'ethers'

import LinkIcon from 'assets/svg/app/link-new.svg'
import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import { FlexDivRowCentered } from 'components/layout/flex'
import Button from 'components/Button'
import ProgressSteps from 'components/ProgressSteps'
import Loader from 'components/Loader'
import { EXTERNAL_LINKS } from 'constants/links'
import { DEFAULT_TX_FEE } from 'constants/defaults'
import { txFees } from 'constants/oneclick'
import { MaxButton } from 'sections/futures/Trade/DepositWithdrawCrossMargin'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectSmartAccountAddress,
	selectSmartAccountBalance,
	selectWalletBalance,
} from 'state/oneClickTrading/selectors'
import Connector from 'containers/Connector'
import logError from 'utils/logError'
import { setSmartAccountBalance, setWalletBalance } from 'state/oneClickTrading/reducer'
import useWindowSize from 'hooks/useWindowSize'

import { FundProps } from './types'

const Fund: React.FC<FundProps> = ({ isModal }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const walletBalance = useAppSelector(selectWalletBalance)
	const { signer, provider } = Connector.useContainer()
	const { deviceType } = useWindowSize()

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const [amount, setAmount] = useState('')
	const [txCount, setTxCount] = useState(0)
	const [processing, setProcessing] = useState(false)

	const handleTxCount = ({ count, eth }: { count: number; eth: number }) => {
		setTxCount(count)
		setAmount(eth.toString())
	}

	const handleMaxBalance = () => {
		const balance = wei(walletBalance).sub(DEFAULT_TX_FEE)
		setAmount(
			balance.gt(0) ? parseFloat(Number(truncateNumbers(balance, 5)).toString()).toString() : ''
		)
	}

	const handleDeposit = async () => {
		setProcessing(true)
		try {
			const tx = await signer?.sendTransaction({
				to: smartAccountAddress,
				value: ethers.utils.parseEther(amount),
			})
			if (tx) {
				const txReceipt = await provider.waitForTransaction(tx.hash)
				if (txReceipt) {
					setProcessing(false)
					dispatch(setSmartAccountBalance(wei(smartAccountBalance).add(amount)))
					dispatch(setWalletBalance(wei(walletBalance).sub(amount)))
				}
			}
		} catch (e) {
			logError(e)
			setProcessing(false)
		}
	}

	return (
		<Container>
			<StyledText>{t('dashboard.oneclick.fund.text')}</StyledText>
			<Card mobile={isMobile}>
				<div>
					<StyledLabeltext>{t('dashboard.oneclick.fund.address')}</StyledLabeltext>
					<Link
						href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
						target="_blank"
					>
						<StyledBody fontSize={14}>
							<StyledValueSpan>{truncateAddress(smartAccountAddress)}</StyledValueSpan>
							<LinkIcon />
						</StyledBody>
					</Link>
				</div>
				<div>
					<StyledLabeltext>{t('dashboard.oneclick.fund.eth-balance')}</StyledLabeltext>
					<StyledValuetext>
						{Number(truncateNumbers(smartAccountBalance, 5))}
						<EquityCardCurrencyBadge>
							<span>ETH</span>
						</EquityCardCurrencyBadge>
					</StyledValuetext>
				</div>
			</Card>
			<BalanceWrapper>
				<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
				<BalanceContainer>
					<InputContainer
						placeholder="0.00"
						value={amount}
						onChange={(_, v) => setAmount(v !== '' ? v : '')}
						right={
							<StyledMaxButton onClick={handleMaxBalance} amount={amount}>
								{t('futures.market.trade.margin.modal.max')}
							</StyledMaxButton>
						}
					/>
					<EquityRowsWrap>
						<EquityCardRow>
							<EquityCardLabel>
								{t('dashboard.oneclick.fund.available')}
								<EquityCardCurrencyBadge>
									<span>ETH</span>
								</EquityCardCurrencyBadge>
							</EquityCardLabel>
							<EquityCardValue>{Number(truncateNumbers(walletBalance, 5))}</EquityCardValue>
						</EquityCardRow>
					</EquityRowsWrap>
				</BalanceContainer>
			</BalanceWrapper>
			<CardTxContainer>
				{txFees.map((tx, index) => (
					<CardTx
						key={index}
						isActive={txCount === tx.count}
						mobile={isMobile}
						onClick={() => handleTxCount(tx)}
					>
						<Body fontSize={14} color={txCount === tx.count ? 'primary' : 'secondary'}>
							{tx.count} Txs
						</Body>
						<Body fontSize={13} color={txCount === tx.count ? 'primary' : 'secondary'}>
							{tx.eth} ETH
						</Body>
					</CardTx>
				))}
			</CardTxContainer>
			{isModal && <ProgressSteps step={2} totalSteps={3} complete={false} />}
			<StyledButton
				onClick={handleDeposit}
				disabled={
					wei(amount === '' ? 0 : amount).gt(walletBalance) ||
					wei(amount === '' ? 0 : amount).eq(0) ||
					processing
				}
				isLoading={processing}
			>
				{processing ? <Loader /> : t('dashboard.oneclick.fund.deposit')}
			</StyledButton>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Card = styled.div<{ mobile: boolean }>`
	& > div {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: ${(props) => props.theme.colors.selectedTheme.modal.backgroundColor.dark};
	border-radius: 8px;
	padding: 12px;
	border: none;
	width: 100%;
`

const StyledBody = styled(Body)`
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		width: 16px;
		height: 16px;
		margin-bottom: 2px;
		path {
			stroke: currentColor;
		}
	}
`

const CardTxContainer = styled.div`
	display: flex;
	justify-content: start;
	gap: 8px;
	align-items: center;
`

const CardTx = styled.div<{ isActive: boolean; mobile: boolean }>`
	width: 100%;
	border: ${(props) =>
		props.isActive ? '1px solid #6966ff' : props.theme.colors.selectedTheme.border};
	border-radius: 8px;
	text-align: center;
	padding: ${({ mobile }) => (mobile ? '8px' : '12px')};
	cursor: pointer;
	user-select: none;
	background: ${(props) => props.theme.colors.selectedTheme.modal.manage.buttonFill};
`

const StyledText = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
	margin-bottom: -8px;
`

const StyledLabeltext = styled.p`
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-weight: 400;
	font-size: 13px;
	margin: 0px;
`
const StyledValuetext = styled.p`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-size: 14px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-feature-settings: 'zero' 0;
	margin: 0px;
	display: flex;
	align-items: center;
	justify-content: start;
`
const StyledValueSpan = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-size: 14px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-feature-settings: 'zero' 0;
	margin: 0px;
`

const BalanceText = styled.p`
	font-size: 14px;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
`
const InputContainer = styled(NumericInput)`
	height: 40px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	border: 0;
	font-size: 14px;
	input {
		color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
		font-size: 14px;
		::placeholder {
			color: ${(props) => props.theme.colors.selectedTheme.modal.text.gray};
			font-family: ${(props) => props.theme.fonts.regular};
			font-weight: 400;
			font-size: 14px;
		}
	}
`
const StyledMaxButton = styled.span<{ amount: string }>`
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		!props.amount
			? props.theme.colors.selectedTheme.modal.text.gray
			: props.theme.colors.selectedTheme.modal.text.primaryWhite};
	font-family: ${(props) => props.theme.fonts.regular};
	text-transform: uppercase;
	letter-spacing: 0.04em;
	cursor: pointer;
	font-weight: 400;
`
const BalanceWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
	font-feature-settings: 'zero' 0;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
	flex-direction: column;
	> div {
		width: 100%;
	}
	gap: 12px;
	padding-bottom: 8px;
`
const EquityRowsWrap = styled.div`
	padding: 0px 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
const EquityCardRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
`

const EquityCardLabel = styled.span`
	font-size: 13px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	position: relative;
	display: flex;
	align-items: center;
`

const EquityCardValue = styled.span`
	font-size: 14px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-weight: 400;
	display: flex;
	gap: 2px;
	align-items: center;
`
const EquityCardCurrencyBadge = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.gray};
	font-size: 10px;
	margin-left: 8px;
	padding: 2px 4px 2px 4px;
	border-radius: 4px;
	height: 20px;
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
	}
`

const StyledButton = styled(Button)<{ isLoading?: boolean }>`
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	${(props) =>
		props.disabled
			? css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.disabled} !important;
					border: 0 !important;
					color: #ffffff80 !important;
			  `
			: css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.active} !important;
					border: 0 !important;
					color: #ffffff !important;
			  `};
	${(props) =>
		props.isLoading &&
		css`
			background-color: transparent !important;
			border: ${(props) => props.theme.colors.selectedTheme.border}!important;
		`};
`

export default Fund
