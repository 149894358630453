import { PositionSide } from '@dextoroprotocol/sdk/types'
import styled, { css } from 'styled-components'

import Body from 'components/Text/Body'

type PositionProps = {
	side: PositionSide
	mobile?: boolean
	variant?: 'badge' | 'text'
	ClassName?: string
	fontSize?: number
	style?: React.CSSProperties
}

const PositionType: React.FC<PositionProps> = ({
	side = PositionSide.LONG,
	mobile = false,
	variant = 'badge',
	ClassName,
	fontSize,
	style,
}) => {
	return (
		<StyledText
			side={side}
			variant={variant}
			className={ClassName}
			fontSize={fontSize}
			style={style}
		>
			{side === 'long' ? 'Buy' : 'Sell'}
		</StyledText>
	)
}

//   font-variant: all-small-caps;
export const StyledText = styled.div<{
	side: PositionSide
	variant: 'badge' | 'text'
	fontSize: number | undefined
}>`
	border-radius: 4px;
	padding: 3px 6px 2px 6px;
  	letter-spacing: 0;
	text-transform: UPPERCASE;
	transition: all .15s ease-in-out!important;
	font-feature-settings: "zero" 0;
	user-select: none;
	font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '11px')};
	line-height: 16px;
	font-family: ${(props) => props.theme.fonts.bold};

	${(props) =>
		props.side === PositionSide.LONG
			? css`
					color: ${props.theme.colors.selectedTheme.green};
					background: #3fb68b1a;
			  `
			: css`
					color: ${props.theme.colors.selectedTheme.red};
					background: #ff53531a;
			  `};
	};

	${(props) =>
		props.variant === 'text' &&
		css`
			padding: 0;
			background: transparent;
		`}
`
// padding: 2px 4px 1px 4px;

export default PositionType
