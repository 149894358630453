import DextoroSDK from '@dextoroprotocol/sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { notifyError } from 'components/ErrorNotifier'
import { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import { selectLeaderboardSearchTerm } from './selectors'

export const fetchLeaderboard = createAsyncThunk<
	Awaited<ReturnType<DextoroSDK['stats']['getLeaderboard']>>,
	void,
	ThunkConfig
>('stats/fetchLeaderboard', async (_, { getState, extra: { sdk } }) => {
	const searchTerm = selectLeaderboardSearchTerm(getState())
	const wallet = selectWallet(getState()) ?? ''

	try {
		const res = await sdk.stats.getLeaderboard(searchTerm, wallet)
		return res
	} catch (error) {
		logError(error)
		notifyError(error)
		throw error
	}
})
