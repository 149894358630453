// Some of this file is copied from:
// https://github.com/Synthetixio/js-monorepo/blob/master/packages/queries/queries/network/useEthGasPriceQuery.ts
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wei } from '@synthetixio/wei';
import { NetworkIdByName } from '../types/common';
const MULTIPLIER = wei(2);
export const computeGasFee = (baseFeePerGas, maxPriorityFeePerGas) => ({
    maxPriorityFeePerGas: wei(maxPriorityFeePerGas, 9).toBN(),
    maxFeePerGas: wei(baseFeePerGas, 9).mul(MULTIPLIER).add(wei(maxPriorityFeePerGas, 9)).toBN(),
    baseFeePerGas: baseFeePerGas,
});
export const getGasPriceFromProvider = (provider) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const gasPrice = yield provider.getGasPrice();
        return {
            fastest: { gasPrice },
            fast: { gasPrice },
            average: { gasPrice },
        };
    }
    catch (e) {
        throw new Error('Could not retrieve gas price from provider');
    }
});
// This is mostly copied over from the Synthetix queries.
// See: https://github.com/Synthetixio/js-monorepo/blob/master/packages/queries/queries/network/useEthGasPriceQuery.ts
export const getEthGasPrice = (networkId, provider) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // If network is Mainnet then we use EIP1559
        if (networkId === NetworkIdByName.mainnet) {
            const block = yield provider.getBlock('latest');
            if (block === null || block === void 0 ? void 0 : block.baseFeePerGas) {
                return {
                    fastest: computeGasFee(block.baseFeePerGas, 6),
                    fast: computeGasFee(block.baseFeePerGas, 4),
                    average: computeGasFee(block.baseFeePerGas, 2),
                };
            }
        }
        return getGasPriceFromProvider(provider);
    }
    catch (e) {
        throw new Error(`Could not fetch and compute network fee. ${e}`);
    }
});
