import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NOTIFICATION_URI } from 'constants/notifications'
import { Notifications } from './types'
import logError from 'utils/logError'
import { notifyError } from 'components/ErrorNotifier'

// Fetch notifications
export const fetchNotifications = createAsyncThunk(
	'notifications/fetchNotifications',
	async (userId: string) => {
		try {
			const response = await axios.get<Notifications[]>(`${NOTIFICATION_URI}?userId=${userId}`)
			return response.data
		} catch (error) {
			logError(error)
			notifyError(error.message)
			throw error
		}
	}
)

// Create a new notification
export const createNotification = createAsyncThunk(
	'notifications/createNotification',
	async (newNotification: Notifications) => {
		try {
			const response = await axios.post(`${NOTIFICATION_URI}`, newNotification)
			return response.data
		} catch (error) {
			logError(error)
			notifyError(error?.message)
			throw error
		}
	}
)

// Clear a notification or multiple notifications
export const clearNotifications = createAsyncThunk(
	'notifications/clearNotifications',
	async ({ userId, id }: { userId: string; id?: string }) => {
		try {
			const endpoint = id
				? `${NOTIFICATION_URI}/clear?userId=${userId}&notificationId=${id}`
				: `${NOTIFICATION_URI}/clear?userId=${userId}`
			const response = await axios.patch(endpoint)
			return response.data
		} catch (error) {
			logError(error)
			notifyError(error?.message)
			throw error
		}
	}
)
