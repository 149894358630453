import Wei from '@synthetixio/wei'
import {
	FuturesMarketAsset,
	FuturesMarketKey,
	PositionSide,
	SmartMarginOrderType,
} from '@dextoroprotocol/sdk/types'
import { formatCurrency, getDisplayAsset } from '@dextoroprotocol/sdk/utils'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'

export const NOTIFICATION_URI = `https://staking-rewards-dashboard-data-7933333ea0a8.herokuapp.com/api/notifications`

export enum notificationType {
	DEPOSIT_SUCCESS = 'deposit success',
	WITHDRAW_SUCCESS = 'withdraw success',
	MARKET_ORDER = 'market order',
	LIMIT_ORDER = 'limit order',
	STOP_MARKET_ORDER = 'stop market order',
}

export enum priceType {
	MARKET = 'market',
	LIMIT = 'limit',
	STOP_MARKET = 'stop market',
}

export const DEPOSIT_SUCCESS = 'Deposit success!'
export const WITHDRAW_SUCCESS = 'Withdraw success!'

export const getDepositContent = async (tnsDetails?: string) => ({
	type: 'deposit success',
	message: DEPOSIT_SUCCESS,
	details: `Your deposit of ${tnsDetails} has been confirmed and is now available for trading.`,
})

export const getWithdrawContent = async (tnsDetails?: string) => ({
	type: 'withdraw success',
	message: WITHDRAW_SUCCESS,
	details: `Your withdraw of ${tnsDetails} has been confirmed.`,
})

type props = {
	key: FuturesMarketKey
	asset: FuturesMarketAsset
	positionType: PositionSide
	orderType: SmartMarginOrderType
	price: string
	size: Wei
}

export const getMarketOrderContent = async (tnsDetails: props) => ({
	type: tnsDetails.orderType,
	message:
		tnsDetails.orderType === 'limit'
			? notificationType.LIMIT_ORDER
			: tnsDetails.orderType === 'stop_market'
			? notificationType.STOP_MARKET_ORDER
			: notificationType.MARKET_ORDER,
	details: tnsDetails.asset,
	marketKey: tnsDetails.key,
	marketAsset: tnsDetails.asset,
	positionType: tnsDetails.positionType,
	price: tnsDetails.price === 'market' ? priceType.MARKET : tnsDetails.price,
	size: formatCurrency(getDisplayAsset(tnsDetails?.key) || '', tnsDetails?.size.abs() ?? ZERO_WEI),
})
