var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defaultAbiCoder } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
import { wei } from '@synthetixio/wei';
import { Contract as EthCallContract } from 'ethcall';
import { ethers } from 'ethers';
import { UNSUPPORTED_NETWORK } from '../common/errors';
import { DEXTORO_TRACKING_CODE } from '../constants/futures';
import { PERIOD_IN_SECONDS } from '../constants/period';
import PerpsMarketABI from '../contracts/abis/PerpsV2Market.json';
import { PerpsV2Market__factory } from '../contracts/types';
import { queryFuturesTrades, queryIsolatedMarginTransfers, queryPositionHistory, queryTrades, queryFundingRateHistory, } from '../queries/futures';
import { appAdjustedLeverage, mapFuturesPositions, mapTrades, getPerpsV3SubgraphUrl, marginTypeToSubgraphType, PerpsV3SymbolToMarketKey, MarketAssetByKey, mapPerpsV3Position, sameSide, formatV3AsyncOrder, formatSettlementStrategy, } from '../utils/futures';
import { getReasonFromCode } from '../utils/synths';
import { queryPerpsV3Markets, querySettlementStrategies } from '../queries/perpsV3';
import { weiFromWei } from '../utils';
import { ZERO_ADDRESS } from '../constants';
import { V3_PERPS_ID_TO_V2_MARKET_KEY, } from '../constants/perpsv3';
export default class PerpsV3Service {
    constructor(sdk) {
        this.settlementStrategies = {
            lastUpdated: 0,
            strategies: [],
        };
        this.getSkewAdjustedPrice = (price, marketAddress, marketKey) => __awaiter(this, void 0, void 0, function* () {
            const marketContract = new EthCallContract(marketAddress, PerpsMarketABI);
            const { PerpsV2MarketSettings } = this.sdk.context.multicallContracts;
            if (!PerpsV2MarketSettings)
                throw new Error(UNSUPPORTED_NETWORK);
            const [marketSkew, skewScale] = yield this.sdk.context.multicallProvider.all([
                marketContract.marketSkew(),
                PerpsV2MarketSettings.skewScale(formatBytes32String(marketKey)),
            ]);
            const skewWei = wei(marketSkew);
            const scaleWei = wei(skewScale);
            return price.mul(skewWei.div(scaleWei).add(1));
        });
        this.sdk = sdk;
    }
    get subgraphUrl() {
        return getPerpsV3SubgraphUrl(this.sdk.context.networkId);
    }
    getMarkets() {
        return __awaiter(this, void 0, void 0, function* () {
            const perpsV3Markets = yield queryPerpsV3Markets(this.sdk);
            // TODO: Combine settlement strategies and markets query
            const strategies = yield this.getSettlementStrategies();
            const futuresMarkets = perpsV3Markets.reduce((acc, { perpsMarketId, marketSymbol, marketName, maxFundingVelocity, makerFee, takerFee, skewScale, }) => {
                const marketKey = PerpsV3SymbolToMarketKey[marketSymbol];
                if (!marketKey)
                    return acc;
                acc.push({
                    version: 3,
                    marketId: Number(perpsMarketId),
                    marketKey: marketKey,
                    marketName: marketName,
                    settlementStrategies: strategies.filter((s) => s.marketId === Number(perpsMarketId)),
                    asset: MarketAssetByKey[marketKey],
                    assetHex: '',
                    currentFundingRate: wei(0.0001),
                    currentFundingVelocity: wei(maxFundingVelocity).div(24 * 24),
                    feeRates: {
                        makerFee: weiFromWei(makerFee || 0),
                        takerFee: weiFromWei(takerFee || 0),
                        makerFeeDelayedOrder: weiFromWei(makerFee || 0),
                        takerFeeDelayedOrder: weiFromWei(takerFee || 0),
                        makerFeeOffchainDelayedOrder: weiFromWei(makerFee || 0),
                        takerFeeOffchainDelayedOrder: weiFromWei(takerFee || 0),
                    },
                    openInterest: {
                        // TODO: Assign open interest
                        shortPct: 0,
                        longPct: 0,
                        shortUSD: wei(0),
                        longUSD: wei(0),
                        long: wei(0),
                        short: wei(0),
                    },
                    marketDebt: wei(0),
                    marketSkew: wei(0),
                    contractMaxLeverage: wei(25),
                    appMaxLeverage: appAdjustedLeverage(wei(25)),
                    marketSize: wei(0),
                    marketLimitUsd: wei(1000000),
                    marketLimitNative: wei(100),
                    minInitialMargin: wei(50),
                    keeperDeposit: wei(4),
                    isSuspended: false,
                    marketClosureReason: getReasonFromCode(2),
                    settings: {
                        maxMarketValue: wei(1000),
                        skewScale: weiFromWei(skewScale),
                        delayedOrderConfirmWindow: 20000,
                        offchainDelayedOrderMinAge: 20000,
                        offchainDelayedOrderMaxAge: 20000,
                        minDelayTimeDelta: 100,
                        maxDelayTimeDelta: 100, // TODO: assign
                    },
                });
                return acc;
            }, []);
            return futuresMarkets;
        });
    }
    getPositions(accountId, marketIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const proxy = this.sdk.context.multicallContracts.perpsV3MarketProxy;
            if (!this.sdk.context.isL2 || !proxy) {
                throw new Error(UNSUPPORTED_NETWORK);
            }
            const positionCalls = marketIds.map((id) => proxy.getOpenPosition(accountId, id));
            // TODO: Combine these two?
            const positionDetails = (yield this.sdk.context.multicallProvider.all(positionCalls));
            // map the positions using the results
            const positions = yield Promise.all(positionDetails.reduce((acc, res, i) => {
                const pos = mapPerpsV3Position(marketIds[i], ...res);
                if (pos)
                    acc.push(pos);
                return acc;
            }, []));
            return positions;
        });
    }
    getMarketFundingRatesHistory(marketAsset, periodLength = PERIOD_IN_SECONDS.TWO_WEEKS) {
        return __awaiter(this, void 0, void 0, function* () {
            const minTimestamp = Math.floor(Date.now() / 1000) - periodLength;
            return queryFundingRateHistory(this.sdk, marketAsset, minTimestamp);
        });
    }
    getSettlementStrategies() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.settlementStrategies.lastUpdated > Date.now() - PERIOD_IN_SECONDS.ONE_HOUR * 1000) {
                return this.settlementStrategies.strategies;
            }
            const strategies = yield querySettlementStrategies(this.sdk);
            const formattedStrats = strategies.map(formatSettlementStrategy);
            this.settlementStrategies = {
                lastUpdated: Date.now(),
                strategies: formattedStrats,
            };
            return formattedStrats;
        });
    }
    getAverageFundingRates(_markets, _prices, _period) {
        return __awaiter(this, void 0, void 0, function* () {
            return [];
            // const fundingRateInputs: FundingRateInput[] = markets.map(
            // 	({ asset, market, currentFundingRate }) => {
            // 		const price = prices[asset]
            // 		return {
            // 			marketAddress: market,
            // 			marketKey: MarketKeyByAsset[asset],
            // 			price: price,
            // 			currentFundingRate: currentFundingRate,
            // 		}
            // 	}
            // )
            //WHAT?
            // const fundingRateQueries = fundingRateInputs.map(({ marketAddress, marketKey }) => {
            // 	return gql`
            // 			# last before timestamp
            // 			${marketKey}_first: fundingRateUpdates(
            // 				first: 1
            // 				where: { market: "${marketAddress}", timestamp_lt: $minTimestamp }
            // 				orderBy: sequenceLength
            // 				orderDirection: desc
            // 			) {
            // 				timestamp
            // 				funding
            // 			}
            // 			# first after timestamp
            // 			${marketKey}_next: fundingRateUpdates(
            // 				first: 1
            // 				where: { market: "${marketAddress}", timestamp_gt: $minTimestamp }
            // 				orderBy: sequenceLength
            // 				orderDirection: asc
            // 			) {
            // 				timestamp
            // 				funding
            // 			}
            // 			# latest update
            // 			${marketKey}_latest: fundingRateUpdates(
            // 				first: 1
            // 				where: { market: "${marketAddress}" }
            // 				orderBy: sequenceLength
            // 				orderDirection: desc
            // 			) {
            // 				timestamp
            // 				funding
            // 			}
            // 		`
            // })
            // const periodLength = PERIOD_IN_SECONDS[period]
            // const minTimestamp = Math.floor(Date.now() / 1000) - periodLength
            // const marketFundingResponses: Record<string, FundingRateUpdate[]> = await request(
            // 	this.futuresGqlEndpoint,
            // 	gql`
            // 	query fundingRateUpdates($minTimestamp: BigInt!) {
            // 		${fundingRateQueries.reduce((acc: string, curr: string) => {
            // 			return acc + curr
            // 		})}
            // 	}
            // `,
            // 	{ minTimestamp: minTimestamp }
            // )
            // const periodTitle = period === Period.ONE_HOUR ? '1H Funding Rate' : 'Funding Rate'
            // const fundingRateResponses = fundingRateInputs.map(
            // 	({ marketKey, currentFundingRate, price }) => {
            // 		if (!price) return null
            // 		const marketResponses = [
            // 			marketFundingResponses[`${marketKey}_first`],
            // 			marketFundingResponses[`${marketKey}_next`],
            // 			marketFundingResponses[`${marketKey}_latest`],
            // 		]
            // 		const responseFilt = marketResponses
            // 			.filter((value: FundingRateUpdate[]) => value.length > 0)
            // 			.map((entry: FundingRateUpdate[]): FundingRateUpdate => entry[0])
            // 			.sort((a: FundingRateUpdate, b: FundingRateUpdate) => a.timestamp - b.timestamp)
            // 		const fundingRate =
            // 			responseFilt && !!currentFundingRate
            // 				? calculateFundingRate(
            // 						minTimestamp,
            // 						periodLength,
            // 						responseFilt,
            // 						price,
            // 						currentFundingRate
            // 				  )
            // 				: currentFundingRate ?? null
            // 		const fundingPeriod =
            // 			responseFilt && !!currentFundingRate ? periodTitle : 'Inst. Funding Rate'
            // 		const fundingRateResponse: FundingRateResponse = {
            // 			asset: marketKey,
            // 			fundingTitle: fundingPeriod,
            // 			fundingRate: fundingRate,
            // 		}
            // 		return fundingRateResponse
            // 	}
            // )
            // return fundingRateResponses.filter((funding): funding is FundingRateResponse => !!funding)
        });
    }
    getDailyVolumes() {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
            // const minTimestamp = Math.floor(calculateTimestampForPeriod(PERIOD_IN_HOURS.ONE_DAY) / 1000)
            // const response = await getFuturesAggregateStats(
            // 	this.futuresGqlEndpoint,
            // 	{
            // 		first: 999999,
            // 		where: {
            // 			period: `${PERIOD_IN_SECONDS.ONE_HOUR}`,
            // 			timestamp_gte: `${minTimestamp}`,
            // 		},
            // 	},
            // 	{
            // 		id: true,
            // 		marketKey: true,
            // 		asset: true,
            // 		volume: true,
            // 		trades: true,
            // 		timestamp: true,
            // 		period: true,
            // 		feesCrossMarginAccounts: true,
            // 		feesDextoro: true,
            // 		feesSynthetix: true,
            // 	}
            // )
            // return response ? calculateVolumes(response) : {}
        });
    }
    getPerpsV3AccountIds(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const accountProxy = this.sdk.context.contracts.perpsV3AccountProxy;
            const accountMulticall = this.sdk.context.multicallContracts.perpsV3AccountProxy;
            if (!accountProxy || !accountMulticall)
                throw new Error(UNSUPPORTED_NETWORK);
            if (!walletAddress)
                return [];
            const accountCount = yield accountProxy.balanceOf(walletAddress);
            const calls = Number(accountCount) > 0
                ? [...Array(Number(accountCount)).keys()].map((index) => {
                    return accountMulticall.tokenOfOwnerByIndex(walletAddress, index);
                })
                : [];
            const accountIds = (yield this.sdk.context.multicallProvider.all(calls));
            return accountIds.map((id) => id.toNumber());
        });
    }
    getAccountOwner(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const owner = yield marketProxy.getAccountOwner(id);
            if (owner === ZERO_ADDRESS)
                return null;
            return owner;
        });
    }
    getMarginTransfers(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const address = walletAddress !== null && walletAddress !== void 0 ? walletAddress : this.sdk.context.walletAddress;
            return queryIsolatedMarginTransfers(this.sdk, address);
        });
    }
    getAvailableMargin(accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const availableMargin = yield marketProxy.getAvailableMargin(accountId);
            return wei(availableMargin);
        });
    }
    getPendingAsyncOrder(accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const order = yield marketProxy.getOrder(accountId);
            return formatV3AsyncOrder(order);
        });
    }
    getPendingAsyncOrders(accountId, marketIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const proxy = this.sdk.context.multicallContracts.perpsV3MarketProxy;
            if (!proxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const orders = (yield this.sdk.context.multicallProvider.all(marketIds.map((market) => proxy.getOrder(market, accountId))));
            return orders.filter((o) => o.request.sizeDelta.abs().gt(0)).map(formatV3AsyncOrder);
        });
    }
    getTradePreview(marketId, size, settlementStrategy) {
        return __awaiter(this, void 0, void 0, function* () {
            const proxy = this.sdk.context.multicallContracts.perpsV3MarketProxy;
            const price = this.sdk.prices.getOffchainPrice(V3_PERPS_ID_TO_V2_MARKET_KEY[marketId]);
            if (!proxy)
                throw new Error(UNSUPPORTED_NETWORK);
            if (!price)
                throw new Error('No price for market');
            const [fees, skew, fill] = (yield this.sdk.context.multicallProvider.all([
                proxy.getOrderFees(marketId),
                proxy.skew(marketId),
                proxy.fillPrice(marketId, size.toBN(), price.toBN()),
            ]));
            const notional = size.mul(price);
            const feeSide = sameSide(notional, wei(skew)) ? wei(fees.takerFee) : wei(fees.makerFee);
            const fee = notional.mul(feeSide);
            const fillPrice = wei(fill);
            const settlementFee = wei(settlementStrategy.settlementReward);
            return {
                fillPrice,
                fee,
                settlementFee,
            };
        });
    }
    getPositionHistory(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryPositionHistory(this.sdk, walletAddress, 'eoa');
            return response ? mapFuturesPositions(response) : [];
        });
    }
    // TODO: Support pagination
    getTradesForMarket(marketAsset, walletAddress, accountType, pageLength = 16) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryTrades(this.sdk, {
                marketAsset,
                walletAddress,
                accountType: marginTypeToSubgraphType(accountType),
                pageLength,
            });
            return response ? mapTrades(response) : [];
        });
    }
    getAllTrades(walletAddress, accountType, pageLength = 16) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryTrades(this.sdk, {
                walletAddress,
                accountType: marginTypeToSubgraphType(accountType),
                pageLength,
            });
            return response ? mapTrades(response) : [];
        });
    }
    // This is on an interval of 15 seconds.
    getFuturesTrades(marketKey, minTs, maxTs) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryFuturesTrades(this.sdk, marketKey, minTs, maxTs);
            return response ? mapTrades(response) : null;
        });
    }
    // TODO: Get delayed order fee
    getOrderFee(marketAddress, size) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketContract = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            const orderFee = yield marketContract.orderFee(size.toBN(), 0);
            return wei(orderFee.fee);
        });
    }
    getDepositAllowances(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            const susdContract = this.sdk.context.contracts.SNXUSD;
            if (!susdContract || !marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const snxusd = yield susdContract.allowance(walletAddress, marketProxy.address);
            return { snxusd: wei(snxusd) };
        });
    }
    // Contract mutations
    approveDeposit(asset, amount = BigNumber.from(ethers.constants.MaxUint256)) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            const assetContract = this.sdk.context.contracts[asset];
            if (!assetContract || !marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            return this.sdk.transactions.createContractTxn(assetContract, 'approve', [
                marketProxy.address,
                amount,
            ]);
        });
    }
    depositToAccount(accountId, synthId, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.modifyCollateral(accountId, synthId, amount);
        });
    }
    withdrawFromAccount(accountId, synthId, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.modifyCollateral(accountId, synthId, amount.neg());
        });
    }
    closePosition(marketAddress, priceImpactDelta) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            return market.closePositionWithTracking(priceImpactDelta.toBN(), DEXTORO_TRACKING_CODE);
        });
    }
    submitOrder(marketId, accountId, sizeDelta, acceptablePrice, settlementStrategyId) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const commitment = {
                marketId: marketId,
                accountId,
                sizeDelta: sizeDelta.toBN(),
                settlementStrategyId,
                acceptablePrice: acceptablePrice.toBN(),
                trackingCode: DEXTORO_TRACKING_CODE,
            };
            return this.sdk.transactions.createContractTxn(marketProxy, 'commitOrder', [commitment]);
        });
    }
    cancelAsyncOrder(marketId, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            return this.sdk.transactions.createContractTxn(marketProxy, 'cancelOrder', [
                marketId,
                accountId,
            ]);
        });
    }
    executeAsyncOrder(marketKey, marketId, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { Pyth } = this.sdk.context.contracts;
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!Pyth || !marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const extraData = defaultAbiCoder.encode(['uint128', 'uint128'], [marketId, accountId]);
            // get price update data
            const priceUpdateData = yield this.sdk.prices.getPythPriceUpdateData(marketKey);
            const updateFee = yield Pyth.getUpdateFee(priceUpdateData);
            return this.sdk.transactions.createContractTxn(marketProxy, 'settlePythOrder', [priceUpdateData[0], extraData], { value: updateFee });
        });
    }
    createAccount(requestedId) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
            if (!marketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const id = requestedId !== null && requestedId !== void 0 ? requestedId : Date.now();
            return this.sdk.transactions.createContractTxn(marketProxy, 'createAccount', [id]);
        });
    }
    // private helpers
    modifyCollateral(accountId, synthId, amount) {
        const marketProxy = this.sdk.context.contracts.perpsV3MarketProxy;
        if (!marketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return this.sdk.transactions.createContractTxn(marketProxy, 'modifyCollateral', [
            accountId,
            synthId,
            amount.toBN(),
        ]);
    }
}
