var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hexZeroPad, hexConcat, hexlify } from 'ethers/lib/utils';
export function getABISVMSessionKeyData(sessionKey, permission) {
    return __awaiter(this, void 0, void 0, function* () {
        let sessionKeyData = hexConcat([
            sessionKey,
            permission.destContract,
            permission.functionSelector,
            hexZeroPad(permission.valueLimit.toHexString(), 16),
            hexZeroPad(hexlify(permission.rules.length), 2), // this can't be more 2**11 (see below), so uint16 (2 bytes) is enough
        ]);
        for (let i = 0; i < permission.rules.length; i++) {
            sessionKeyData = hexConcat([
                sessionKeyData,
                hexZeroPad(hexlify(permission.rules[i].offset), 2),
                hexZeroPad(hexlify(permission.rules[i].condition), 1),
                permission.rules[i].referenceValue,
            ]);
        }
        return sessionKeyData;
    });
}
