var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wei } from '@synthetixio/wei';
import { ethers } from 'ethers';
import moment from 'moment';
import * as sdkErrors from '../common/errors';
import { DEXTORO_ADDRESS } from '../constants/exchange';
import { AGGREGATE_ASSET_KEY, DEXTORO_TRACKING_CODE } from '../constants/futures';
import { ZERO_WEI } from '../constants/number';
import { SECONDS_PER_DAY } from '../constants/period';
import { DEFAULT_NUMBER_OF_FUTURES_FEE, EPOCH_START, OP_REWARDS_CUTOFF_EPOCH, STAKING_V2_REWARDS_CUTOFF_EPOCH, TRADING_REWARDS_CUTOFF_EPOCH, WEEK, } from '../constants/staking';
import { formatTruncatedDuration } from '../utils/date';
import { awsClient, referralClient } from '../utils/files';
import { weiFromWei } from '../utils/number';
import { getFuturesAggregateStats, getFuturesTrades } from '../utils/subgraph';
import { calculateFeesForAccount, calculateTotalFees, getStakingGqlEndpoint } from '../utils';
import { ADDRESSES, REFERRAL_START_EPOCH } from '../constants';
import { queryOperatorsByOwner } from '../queries/staking';
export default class DextoroTokenService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    get stakingGqlEndpoint() {
        const { networkId } = this.sdk.context;
        return getStakingGqlEndpoint(networkId);
    }
    changePoolTokens(amount, action) {
        if (!this.sdk.context.contracts.StakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(this.sdk.context.contracts.StakingRewards, action, [wei(amount).toBN()]);
    }
    approveLPToken() {
        return this.approveToken('DextoroArrakisVault', 'StakingRewards');
    }
    getEarnDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            const { StakingRewards, DextoroArrakisVault } = this.sdk.context.multicallContracts;
            if (!StakingRewards || !DextoroArrakisVault) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const [balance, earned, periodFinish, rewardRate, totalSupply, lpTokenBalance, allowance, [wethAmount, dextoroAmount], lpTotalSupply,] = yield this.sdk.context.multicallProvider.all([
                StakingRewards.balanceOf(walletAddress),
                StakingRewards.earned(walletAddress),
                StakingRewards.periodFinish(),
                StakingRewards.rewardRate(),
                StakingRewards.totalSupply(),
                DextoroArrakisVault.balanceOf(walletAddress),
                DextoroArrakisVault.allowance(walletAddress, StakingRewards.address),
                DextoroArrakisVault.getUnderlyingBalances(),
                DextoroArrakisVault.totalSupply(),
            ]);
            return {
                balance: wei(balance),
                earned: wei(earned),
                endDate: periodFinish.toNumber(),
                rewardRate: wei(rewardRate),
                totalSupply: wei(totalSupply),
                lpTokenBalance: wei(lpTokenBalance),
                allowance: wei(allowance),
                wethAmount: wei(wethAmount),
                dextoroAmount: wei(dextoroAmount),
                lpTotalSupply: wei(lpTotalSupply),
            };
        });
    }
    getEarnTokenPrices() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const dextoroCoinGeckoPrice = yield this.sdk.exchange.batchGetCoingeckoPrices([DEXTORO_ADDRESS], false);
            return {
                dextoroPrice: dextoroCoinGeckoPrice
                    ? wei((_a = dextoroCoinGeckoPrice[DEXTORO_ADDRESS]) === null || _a === void 0 ? void 0 : _a.usd)
                    : ZERO_WEI,
            };
        });
    }
    claimRewards() {
        const StakingRewards = this.sdk.context.contracts.StakingRewards;
        if (!StakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(StakingRewards, 'getReward', []);
    }
    getStakingData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { vDextoroRedeemer, veDextoroRedeemer } = this.sdk.context.contracts;
            const { RewardEscrow, DextoroStakingRewards, DextoroToken, SupplySchedule, vDextoroToken, veDextoroToken, MultipleMerkleDistributor, } = this.sdk.context.multicallContracts;
            if (!RewardEscrow ||
                !DextoroStakingRewards ||
                !DextoroToken ||
                !SupplySchedule ||
                !vDextoroToken ||
                !MultipleMerkleDistributor ||
                !veDextoroToken ||
                !vDextoroRedeemer ||
                !veDextoroRedeemer) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress, networkId } = this.sdk.context;
            const [rewardEscrowBalance, stakedNonEscrowedBalance, stakedEscrowedBalance, claimableBalance, dextoroBalance, weekCounter, totalStakedBalance, vDextoroBalance, vDextoroAllowance, dextoroAllowance, veDextoroBalance, veDextoroAllowance,] = yield this.sdk.context.multicallProvider.all([
                RewardEscrow.balanceOf(walletAddress),
                DextoroStakingRewards.nonEscrowedBalanceOf(walletAddress),
                DextoroStakingRewards.escrowedBalanceOf(walletAddress),
                DextoroStakingRewards.earned(walletAddress),
                DextoroToken.balanceOf(walletAddress),
                SupplySchedule.weekCounter(),
                DextoroStakingRewards.totalSupply(),
                vDextoroToken.balanceOf(walletAddress),
                vDextoroToken.allowance(walletAddress, ADDRESSES.vDextoroRedeemer[networkId]),
                DextoroToken.allowance(walletAddress, ADDRESSES.DextoroStakingRewards[networkId]),
                veDextoroToken.balanceOf(walletAddress),
                veDextoroToken.allowance(walletAddress, ADDRESSES.veDextoroRedeemer[networkId]),
            ]);
            return {
                rewardEscrowBalance: wei(rewardEscrowBalance),
                stakedNonEscrowedBalance: wei(stakedNonEscrowedBalance),
                stakedEscrowedBalance: wei(stakedEscrowedBalance),
                claimableBalance: wei(claimableBalance),
                dextoroBalance: wei(dextoroBalance),
                weekCounter: Number(weekCounter),
                totalStakedBalance: wei(totalStakedBalance),
                vDextoroBalance: wei(vDextoroBalance),
                vDextoroAllowance: wei(vDextoroAllowance),
                dextoroAllowance: wei(dextoroAllowance),
                epochPeriod: Math.floor((Math.floor(Date.now() / 1000) - EPOCH_START[10]) / WEEK),
                veDextoroBalance: wei(veDextoroBalance),
                veDextoroAllowance: wei(veDextoroAllowance),
            };
        });
    }
    getStakingV2Data() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrowV2, DextoroStakingRewardsV2, DextoroToken, SupplySchedule } = this.sdk.context.multicallContracts;
            if (!RewardEscrowV2 || !DextoroStakingRewardsV2 || !DextoroToken || !SupplySchedule) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress, networkId } = this.sdk.context;
            const [rewardEscrowBalance, stakedNonEscrowedBalance, stakedEscrowedBalance, claimableBalance, totalStakedBalance, lastStakedTime, cooldownPeriod, dextoroStakingV2Allowance,] = yield this.sdk.context.multicallProvider.all([
                RewardEscrowV2.escrowedBalanceOf(walletAddress),
                DextoroStakingRewardsV2.nonEscrowedBalanceOf(walletAddress),
                DextoroStakingRewardsV2.escrowedBalanceOf(walletAddress),
                DextoroStakingRewardsV2.earned(walletAddress),
                DextoroStakingRewardsV2.totalSupply(),
                DextoroStakingRewardsV2.userLastStakeTime(walletAddress),
                DextoroStakingRewardsV2.cooldownPeriod(),
                DextoroToken.allowance(walletAddress, ADDRESSES.DextoroStakingRewardsV2[networkId]),
            ]);
            return {
                rewardEscrowBalance: wei(rewardEscrowBalance),
                stakedNonEscrowedBalance: wei(stakedNonEscrowedBalance),
                stakedEscrowedBalance: wei(stakedEscrowedBalance),
                claimableBalance: wei(claimableBalance),
                totalStakedBalance: wei(totalStakedBalance),
                stakedResetTime: Number(lastStakedTime) + Number(cooldownPeriod),
                dextoroStakingV2Allowance: wei(dextoroStakingV2Allowance),
            };
        });
    }
    getEscrowData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrow } = this.sdk.context.contracts;
            const { RewardEscrow: RewardEscrowMulticall } = this.sdk.context.multicallContracts;
            if (!RewardEscrow || !RewardEscrowMulticall) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const schedules = yield RewardEscrow.getVestingSchedules(walletAddress, 0, DEFAULT_NUMBER_OF_FUTURES_FEE);
            const vestingSchedules = schedules.filter((schedule) => schedule.escrowAmount.gt(0));
            const calls = vestingSchedules.map((schedule) => RewardEscrowMulticall.getVestingEntryClaimable(walletAddress, schedule.entryID));
            const vestingEntries = yield this.sdk.context.multicallProvider.all(calls);
            const { escrowData, totalVestable } = vestingSchedules.reduce((acc, next, i) => {
                const vestable = wei(vestingEntries[i].quantity);
                const date = Number(next.endTime) * 1000;
                acc.totalVestable = acc.totalVestable.add(vestable);
                acc.escrowData.push({
                    id: Number(next.entryID),
                    date: moment(date).format('MM/DD/YY'),
                    time: formatTruncatedDuration(Number(next.endTime) - new Date().getTime() / 1000),
                    vestable,
                    amount: wei(next.escrowAmount),
                    fee: wei(vestingEntries[i].fee),
                    status: date > Date.now() ? 'Vesting' : 'Vested',
                    version: 1,
                });
                return acc;
            }, { escrowData: [], totalVestable: wei(0) });
            return { escrowData, totalVestable };
        });
    }
    getEscrowV2Data() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrowV2 } = this.sdk.context.contracts;
            const { RewardEscrowV2: RewardEscrowMulticall } = this.sdk.context.multicallContracts;
            if (!RewardEscrowV2 || !RewardEscrowMulticall) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const schedules = yield RewardEscrowV2.getVestingSchedules(walletAddress, 0, DEFAULT_NUMBER_OF_FUTURES_FEE);
            const vestingSchedules = schedules.filter((schedule) => schedule.escrowAmount.gt(0));
            const calls = vestingSchedules.map((schedule) => RewardEscrowMulticall.getVestingEntryClaimable(schedule.entryID));
            const vestingEntries = yield this.sdk.context.multicallProvider.all(calls);
            const { escrowData, totalVestable } = vestingSchedules.reduce((acc, next, i) => {
                const vestable = wei(vestingEntries[i].quantity);
                const date = Number(next.endTime) * 1000;
                acc.totalVestable = acc.totalVestable.add(vestable);
                acc.escrowData.push({
                    id: Number(next.entryID),
                    date: moment(date).format('MM/DD/YY'),
                    time: formatTruncatedDuration(Number(next.endTime) - new Date().getTime() / 1000),
                    vestable,
                    amount: wei(next.escrowAmount),
                    fee: wei(vestingEntries[i].fee),
                    status: date > Date.now() ? 'Vesting' : 'Vested',
                    version: 2,
                });
                return acc;
            }, { escrowData: [], totalVestable: wei(0) });
            return { escrowData, totalVestable };
        });
    }
    claimStakingRewards() {
        const { DextoroStakingRewards } = this.sdk.context.contracts;
        if (!DextoroStakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(DextoroStakingRewards, 'getReward', []);
    }
    claimStakingRewardsV2() {
        const { DextoroStakingRewardsV2 } = this.sdk.context.contracts;
        if (!DextoroStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(DextoroStakingRewardsV2, 'getReward', []);
    }
    compoundRewards() {
        const { DextoroStakingRewardsV2 } = this.sdk.context.contracts;
        if (!DextoroStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(DextoroStakingRewardsV2, 'compound', []);
    }
    // TODO: Replace this with separate functions that use `approveToken`
    // In that case, we can safely remove the map object from this method.
    approveDextoroToken(token, amount = ethers.constants.MaxUint256) {
        const { DextoroToken, DextoroStakingRewards, vDextoroToken, vDextoroRedeemer, veDextoroToken, veDextoroRedeemer, DextoroStakingRewardsV2, } = this.sdk.context.contracts;
        const map = {
            dextoro: { contract: DextoroToken, spender: DextoroStakingRewards },
            vDextoro: { contract: vDextoroToken, spender: vDextoroRedeemer },
            veDextoro: { contract: veDextoroToken, spender: veDextoroRedeemer },
            dextoroStakingV2: { contract: DextoroToken, spender: DextoroStakingRewardsV2 },
        };
        const { contract, spender } = map[token];
        if (!contract || !spender) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(contract, 'approve', [spender.address, amount]);
    }
    approveToken(token, spender, amount = ethers.constants.MaxUint256) {
        const tokenContract = this.sdk.context.contracts[token];
        if (!tokenContract) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        let spenderAddress = this.sdk.context.walletAddress;
        if (spender) {
            const spenderContract = this.sdk.context.contracts[spender];
            if (spenderContract)
                spenderAddress = spenderContract.address;
        }
        return this.sdk.transactions.createContractTxn(tokenContract, 'approve', [
            spenderAddress,
            amount,
        ]);
    }
    redeemToken(token, options = { hasAddress: false }) {
        const tokenContract = this.sdk.context.contracts[token];
        if (!tokenContract) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(tokenContract, 'redeem', options.hasAddress ? [this.sdk.context.walletAddress] : []);
    }
    redeemVDextoro() {
        return this.redeemToken('vDextoroRedeemer');
    }
    redeemVeDextoro() {
        return this.redeemToken('veDextoroRedeemer', { hasAddress: true });
    }
    vestToken(ids) {
        const { RewardEscrow } = this.sdk.context.contracts;
        if (!RewardEscrow) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrow, 'vest', [ids]);
    }
    vestTokenV2(ids) {
        const { RewardEscrowV2 } = this.sdk.context.contracts;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrowV2, 'vest', [ids]);
    }
    stakeDextoro(amount) {
        return this.performStakeAction('stake', amount);
    }
    unstakeDextoro(amount) {
        return this.performStakeAction('unstake', amount, { escrow: false, version: 1 });
    }
    stakeEscrowedDextoro(amount) {
        return this.performStakeAction('stake', amount, { escrow: true, version: 1 });
    }
    unstakeEscrowedDextoro(amount) {
        return this.performStakeAction('unstake', amount, { escrow: true, version: 1 });
    }
    stakeDextoroV2(amount) {
        return this.performStakeAction('stake', amount, { escrow: false, version: 2 });
    }
    unstakeDextoroV2(amount) {
        return this.performStakeAction('unstake', amount, { escrow: false, version: 2 });
    }
    stakeEscrowedDextoroV2(amount) {
        return this.performStakeAction('stake', amount, { escrow: true, version: 2 });
    }
    unstakeEscrowedDextoroV2(amount) {
        return this.performStakeAction('unstake', amount, { escrow: true, version: 2 });
    }
    getEstimatedRewards() {
        return __awaiter(this, void 0, void 0, function* () {
            const { networkId, walletAddress } = this.sdk.context;
            const fileName = `${networkId === 420 ? 'goerli-' : ''}epoch-current.json`;
            const response = yield awsClient.get(fileName);
            const reward = response.data.claims[walletAddress];
            const estimatedDextoroRewards = reward ? weiFromWei(reward.amount) : ZERO_WEI;
            return { estimatedDextoroRewards, estimatedOpRewards: ZERO_WEI };
        });
    }
    getClaimableRewards(epochPeriod) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorPerpsV2 } = this.sdk.context.multicallContracts;
            const { walletAddress } = this.sdk.context;
            if (!MultipleMerkleDistributorPerpsV2) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
            const adjustedPeriods = periods.slice(TRADING_REWARDS_CUTOFF_EPOCH);
            const fileNames = adjustedPeriods.map((i) => `${this.sdk.context.networkId === 420 ? `goerli-` : ''}epoch-${i}.json`);
            const responses = yield Promise.all(fileNames.map((fileName, index) => __awaiter(this, void 0, void 0, function* () {
                const response = yield awsClient.get(fileName);
                const period = index + TRADING_REWARDS_CUTOFF_EPOCH;
                return Object.assign(Object.assign({}, response.data), { period });
            })));
            const rewards = responses
                .map((d) => {
                const reward = d.claims[walletAddress];
                if (reward) {
                    return [reward.index, walletAddress, reward.amount, reward.proof, d.period];
                }
                return null;
            })
                .filter((x) => !!x);
            const claimed = yield this.sdk.context.multicallProvider.all(rewards.map((reward) => MultipleMerkleDistributorPerpsV2.isClaimed(reward[0], reward[4])));
            const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
                if (!claimed[i]) {
                    acc.claimableRewards.push(next);
                    acc.totalRewards = acc.totalRewards.add(weiFromWei(next[2]));
                }
                return acc;
            }, { claimableRewards: [], totalRewards: wei(0) });
            return { claimableRewards, totalRewards };
        });
    }
    getClaimableAllRewards(epochPeriod, isStakingV2 = false, isOp = false, isSnx = false, cutoffPeriod = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorPerpsV2, MultipleMerkleDistributorStakingV2, MultipleMerkleDistributorOp, MultipleMerkleDistributorSnxOp, } = this.sdk.context.multicallContracts;
            const { walletAddress } = this.sdk.context;
            if (!MultipleMerkleDistributorPerpsV2 ||
                !MultipleMerkleDistributorStakingV2 ||
                !MultipleMerkleDistributorOp ||
                !MultipleMerkleDistributorSnxOp) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
            const adjustedPeriods = isOp
                ? periods.slice(OP_REWARDS_CUTOFF_EPOCH)
                : isStakingV2
                    ? periods.slice(STAKING_V2_REWARDS_CUTOFF_EPOCH)
                    : periods.slice(TRADING_REWARDS_CUTOFF_EPOCH, STAKING_V2_REWARDS_CUTOFF_EPOCH);
            const fileNames = adjustedPeriods.map((i) => `${this.sdk.context.networkId === 420 ? `goerli-` : ''}epoch-${isSnx ? i - OP_REWARDS_CUTOFF_EPOCH : i}${isOp ? (isSnx ? '-snx-op' : '-op') : ''}.json`);
            const responses = yield Promise.all(fileNames.map((fileName, index) => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (index < cutoffPeriod)
                        return null;
                    const response = yield awsClient.get(fileName);
                    const period = isOp
                        ? isSnx
                            ? index
                            : index + OP_REWARDS_CUTOFF_EPOCH
                        : isStakingV2
                            ? index + STAKING_V2_REWARDS_CUTOFF_EPOCH
                            : index + TRADING_REWARDS_CUTOFF_EPOCH;
                    return Object.assign(Object.assign({}, response.data), { period });
                }
                catch (err) {
                    this.sdk.context.logError(err);
                    return null;
                }
            })));
            const rewards = responses
                .filter(Boolean)
                .map((d) => {
                const reward = d.claims[walletAddress];
                if (reward) {
                    return [reward.index, walletAddress, reward.amount, reward.proof, d.period];
                }
                return null;
            })
                .filter((x) => !!x);
            const claimed = yield this.sdk.context.multicallProvider.all(rewards.map((reward) => isOp
                ? isSnx
                    ? MultipleMerkleDistributorSnxOp.isClaimed(reward[0], reward[4])
                    : MultipleMerkleDistributorOp.isClaimed(reward[0], reward[4])
                : isStakingV2
                    ? MultipleMerkleDistributorStakingV2.isClaimed(reward[0], reward[4])
                    : MultipleMerkleDistributorPerpsV2.isClaimed(reward[0], reward[4])));
            const { totalRewards, claimableRewards, periodsRewards } = rewards.reduce((acc, next, i) => {
                if (!claimed[i]) {
                    acc.claimableRewards.push(next);
                    acc.totalRewards = acc.totalRewards.add(weiFromWei(next[2]));
                }
                acc.periodsRewards.push({
                    period: next[4],
                    amount: weiFromWei(next[2]).toString(),
                    claimed: claimed[i],
                });
                return acc;
            }, {
                claimableRewards: [],
                totalRewards: wei(0),
                periodsRewards: [],
            });
            return { claimableRewards, totalRewards, periodsRewards };
        });
    }
    getReferralAffiliateRewards(account, epochPeriod) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorReferral } = this.sdk.context.multicallContracts;
            if (!MultipleMerkleDistributorReferral) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
            const adjustedPeriods = periods.slice(REFERRAL_START_EPOCH);
            const fileNames = adjustedPeriods.map((i) => `epoch-${i}-referral.json`);
            const responses = yield Promise.all(fileNames.map((fileName, index) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const response = yield referralClient.get(fileName);
                    const period = index + REFERRAL_START_EPOCH;
                    return Object.assign(Object.assign({}, response.data), { period });
                }
                catch (e) {
                    const period = index + REFERRAL_START_EPOCH;
                    return { period };
                }
            })));
            const rewards = responses
                .filter(Boolean)
                .map((d) => {
                var _a;
                const reward = (_a = d.claims) === null || _a === void 0 ? void 0 : _a[account];
                if (reward) {
                    return [reward.index, account, reward.amount, reward.proof, d.period];
                }
                return null;
            })
                .filter((x) => !!x);
            const claimed = yield this.sdk.context.multicallProvider.all(rewards.map((reward) => MultipleMerkleDistributorReferral.isClaimed(reward[0], reward[4])));
            const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
                if (!claimed[i]) {
                    acc.claimableRewards.push(next);
                    acc.totalRewards = acc.totalRewards.add(wei(next[2], 6, true));
                }
                return acc;
            }, { claimableRewards: [], totalRewards: wei(0, 6, true) });
            return { claimableRewards, totalRewards };
        });
    }
    claimDextoroRewards(claimableRewards) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorStakingV2 } = this.sdk.context.contracts;
            if (!MultipleMerkleDistributorStakingV2) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(MultipleMerkleDistributorStakingV2, 'claimMultiple', [claimableRewards]);
        });
    }
    claimMultipleAllRewards(claimableRewards) {
        return __awaiter(this, void 0, void 0, function* () {
            const { BatchClaimer, 
            // MultipleMerkleDistributorPerpsV2,
            MultipleMerkleDistributorStakingV2,
            // MultipleMerkleDistributorOp,
            // MultipleMerkleDistributorSnxOp,
             } = this.sdk.context.contracts;
            if (!BatchClaimer ||
                // !MultipleMerkleDistributorPerpsV2 ||
                !MultipleMerkleDistributorStakingV2
            // !MultipleMerkleDistributorOp ||
            // !MultipleMerkleDistributorSnxOp
            ) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(BatchClaimer, 'claimMultiple', [
                [
                    // MultipleMerkleDistributorPerpsV2.address,
                    MultipleMerkleDistributorStakingV2.address,
                    // MultipleMerkleDistributorOp.address,
                    // MultipleMerkleDistributorSnxOp.address,
                ],
                claimableRewards,
            ]);
        });
    }
    claimOpRewards(claimableRewards, isSnx = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorOp, MultipleMerkleDistributorSnxOp } = this.sdk.context.contracts;
            if (!MultipleMerkleDistributorOp || !MultipleMerkleDistributorSnxOp) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(isSnx ? MultipleMerkleDistributorSnxOp : MultipleMerkleDistributorOp, 'claimMultiple', [claimableRewards]);
        });
    }
    getFuturesFee(start, end) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2) {
                throw new Error(sdkErrors.REQUIRES_L2);
            }
            const response = yield getFuturesAggregateStats(this.sdk.futures.futuresGqlEndpoint, {
                first: DEFAULT_NUMBER_OF_FUTURES_FEE,
                where: {
                    asset: AGGREGATE_ASSET_KEY,
                    period: SECONDS_PER_DAY,
                    timestamp_gte: start,
                    timestamp_lt: end,
                },
                orderDirection: 'desc',
                orderBy: 'timestamp',
            }, { feesDextoro: true });
            return response ? calculateTotalFees(response) : wei(0);
        });
    }
    getFuturesFeeForAccount(account, start, end) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!account)
                return wei(0);
            const response = yield getFuturesTrades(this.sdk.futures.futuresGqlEndpoint, {
                first: DEFAULT_NUMBER_OF_FUTURES_FEE,
                where: {
                    account: account,
                    timestamp_gt: start,
                    timestamp_lt: end,
                    trackingCode: DEXTORO_TRACKING_CODE,
                },
                orderDirection: 'desc',
                orderBy: 'timestamp',
            }, {
                feesPaid: true,
                keeperFeesPaid: true,
            });
            return response ? calculateFeesForAccount(response) : wei(0);
        });
    }
    performStakeAction(action, amount, options = { escrow: false, version: 1 }) {
        const { RewardEscrow, RewardEscrowV2, DextoroStakingRewards, DextoroStakingRewardsV2 } = this.sdk.context.contracts;
        if (!RewardEscrow || !RewardEscrowV2 || !DextoroStakingRewards || !DextoroStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const contract = (options === null || options === void 0 ? void 0 : options.version) === 1
            ? (options === null || options === void 0 ? void 0 : options.escrow)
                ? RewardEscrow
                : DextoroStakingRewards
            : DextoroStakingRewardsV2;
        return this.sdk.transactions.createContractTxn(contract, `${action}${(options === null || options === void 0 ? void 0 : options.escrow) ? 'Escrow' : ''}`, [amount]);
    }
    approveOperator(delegatedAddress, isApproval) {
        const { DextoroStakingRewardsV2 } = this.sdk.context.contracts;
        if (!DextoroStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(DextoroStakingRewardsV2, 'approveOperator', [
            delegatedAddress,
            isApproval,
        ]);
    }
    getApprovedOperators() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.DextoroStakingRewardsV2) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            return queryOperatorsByOwner(this.sdk, walletAddress);
        });
    }
    transferFrom(from, to, id) {
        const { RewardEscrowV2 } = this.sdk.context.contracts;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrowV2, 'transferFrom', [from, to, id]);
    }
    bulkTransferFrom(from, to, ids) {
        const { RewardEscrowV2 } = this.sdk.context.contracts;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrowV2, 'bulkTransferFrom', [
            from,
            to,
            ids,
        ]);
    }
}
