var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wei } from '@synthetixio/wei';
import { REFERRALS_ENDPOINT, REFERRAL_START_EPOCH, TRADE_ENDPOINT } from '../constants/referrals';
import { queryClaimHistories, queryReferralsData, queryTradersByCode } from '../queries/referrals';
import { DEFAULT_NUMBER_OF_FUTURES_FEE, DEXTORO_TRACKING_CODE } from '../constants';
import { calculateFeesForAccount, getFuturesTrades, parseEpochData } from '../utils';
export const getReferralsGqlEndpoint = (networkId) => {
    return REFERRALS_ENDPOINT[networkId] || REFERRALS_ENDPOINT[10];
};
export const getTradeGqlEndpoint = (networkId) => {
    return TRADE_ENDPOINT[networkId] || TRADE_ENDPOINT[10];
};
export const getReferralStatisticsByAccount = (sdk, account) => __awaiter(void 0, void 0, void 0, function* () {
    const referralsData = yield queryReferralsData(sdk, account.toLocaleLowerCase());
    const feesPaidByCodes = yield Promise.all((referralsData.referralCodes || []).map((referralCode) => __awaiter(void 0, void 0, void 0, function* () {
        const traders = yield queryTradersByCode(sdk, referralCode.code);
        const tradersFeesPaid = yield Promise.all(traders.map((trader) => __awaiter(void 0, void 0, void 0, function* () {
            const startEpochData = parseEpochData(REFERRAL_START_EPOCH, 10);
            const start = startEpochData.start;
            const end = Math.floor(Date.now() / 1000);
            const tradingData = yield getTradingDataByAccountAndEpoch(sdk, trader, start, end);
            return tradingData.fees.toNumber();
        })));
        const totalFeesPaid = tradersFeesPaid.reduce((acc, curr) => acc + curr, 0);
        return {
            code: referralCode.code,
            feesPaid: totalFeesPaid,
        };
    })));
    referralsData['feesPaidByCodes'] = feesPaidByCodes;
    return referralsData;
});
export const getTradingDataByAccountAndEpoch = (sdk, account, start, end) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield getFuturesTrades(sdk.futures.futuresGqlEndpoint, {
        first: DEFAULT_NUMBER_OF_FUTURES_FEE,
        where: {
            account: account.toLocaleLowerCase(),
            timestamp_gt: start,
            timestamp_lt: end,
            trackingCode: DEXTORO_TRACKING_CODE,
        },
        orderDirection: 'desc',
        orderBy: 'timestamp',
    }, {
        feesPaid: true,
        keeperFeesPaid: true,
    });
    const tradingData = {
        fees: calculateFeesForAccount(response),
        tradesCount: response.length,
    };
    return tradingData;
});
export const getTradingDataByAccount = (sdk, account, start, end) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield getFuturesTrades(sdk.futures.futuresGqlEndpoint, {
        first: DEFAULT_NUMBER_OF_FUTURES_FEE,
        where: {
            account: account.toLocaleLowerCase(),
            timestamp_gt: start,
            timestamp_lt: end,
            trackingCode: DEXTORO_TRACKING_CODE,
        },
        orderDirection: 'desc',
        orderBy: 'timestamp',
    }, {
        feesPaid: true,
        keeperFeesPaid: true,
        timestamp: true,
    });
    return response;
});
export const getAffiliateRewardsByAccount = (sdk, account, epochPeriod) => __awaiter(void 0, void 0, void 0, function* () {
    return yield sdk.dextoroToken.getReferralAffiliateRewards(account, epochPeriod);
});
export const getClaimHistoriesByAccount = (sdk, account) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const response = yield queryClaimHistories(sdk, account);
    const histories = (_a = response.claimHistories) === null || _a === void 0 ? void 0 : _a.map((history) => ({
        timestamp: history.timestamp,
        amount: wei(history.amount, 6, true).toString(),
        transactionHash: history.transactionHash,
    }));
    return histories;
});
