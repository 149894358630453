import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from 'state/store'

export const selectDelegated = (state: RootState) => state.oneClick.delegated

export const selectSessionActivated = (state: RootState) => state.oneClick.sessionActivated

export const selectSmartAccountAddress = (state: RootState) => state.oneClick.smartAccountAddress

export const selectSmartAccountBalance = (state: RootState) => state.oneClick.smartAccountBalance

export const selectSessionId = createSelector(selectSmartAccountAddress, (smartAccountAddress) =>
	window.localStorage.getItem(`dextoro-session-id-${smartAccountAddress}`)
)

export const selectSessionValidUntil = (state: RootState) => state.oneClick.validUntil

export const selectEstimatedTxs = (state: RootState) => state.oneClick.estimatedTxs

export const selectExpiresIn = (state: RootState) => state.oneClick.expiresIn

export const selectWalletBalance = (state: RootState) => state.oneClick.walletBalance

export const selectUsingBiconomy = (state: RootState) => state.oneClick.usingBiconomy
