import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useRef, useState } from 'react'
import { wei, WeiSource } from '@synthetixio/wei'
import { formatDollars, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { css } from 'styled-components'
import OptimisimIcon from 'assets/svg/app/optimism.svg'

import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import Button from 'components/Button'
import { FlexDivRowCentered } from 'components/layout/flex'
import { notifyError } from 'components/ErrorNotifier'
import Loader from 'components/Loader'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import { depositSmartMargin } from 'state/futures/smartMargin/actions'
import { selectFuturesPortfolio, selectSubmittingSmartMarginTx } from 'state/futures/selectors'
import { selectSusdBalance } from 'state/balances/selectors'

import { MaxButton } from '../Trade/DepositWithdrawCrossMargin'

import useIsL2 from 'hooks/useIsL2'
import Connector from 'containers/Connector'
import useNetworkSwitcher from 'hooks/useNetworkSwitcher'
import sUSD from 'assets/png/sUSD.png'
import DropdownArrow from 'assets/svg/app/asset-dropdown-arrow.svg'
import { selectCurrentTheme } from 'state/preferences/selectors'
import media from 'styles/media'
interface DepositProps {
	setTab: (tab: 'deposit' | 'withdraw' | 'bridge' | 'oneclick-trading') => void
}

const Deposit: FC<DepositProps> = ({ setTab }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const isL2 = useIsL2()
	const { walletAddress } = Connector.useContainer()
	const { switchToL2 } = useNetworkSwitcher()
	const sUSDWalletBalance = useAppSelector(selectSusdBalance) //metamask balance
	const depositing = useAppSelector(selectSubmittingSmartMarginTx)
	const currentTheme = useAppSelector(selectCurrentTheme)

	const [amount, setAmount] = useState<string>('')
	const [isOpen, setIsOpen] = useState(false)
	const [selectedAsset, setSelectedAsset] = useState('sUSD')
	// const [showToolTip, setShowToolTip] = useState(false)
	const assets = [{ name: 'sUSD', symbol: 'SUSD', icon: <img src={sUSD} width={'24px'} /> }]
	const isFetchingPreview = useRef(false)
	const [showPreview, setShowPreview] = useState(false)
	const [togglePreview, setTogglePreview] = useState(false)
	const updatedUsdBalance = useRef<WeiSource | null>(null)
	const updatedWalletBallance = useRef<WeiSource | null>(null)
	const updatedBuyingPower = useRef<WeiSource | null>(null)
	const portfolio = useAppSelector(selectFuturesPortfolio) //smart wallet balance

	// const handleAssetSelect = (assetName: string) => {
	// 	setSelectedAsset(assetName)
	// 	setIsOpen(false)
	// }
	const handleMaxBalance = () => {
		setTogglePreview(!togglePreview)
		isFetchingPreview.current = true
		setAmount(parseFloat(Number(truncateNumbers(sUSDWalletBalance, 5)).toString()).toString())
	}

	const handleDeposit = async () => {
		if (amount === '' || amount === '0') {
			notifyError('Please input margin deposit amount')
		} else {
			dispatch(depositSmartMargin(wei(amount)))
		}
	}

	const handleAssetSelect = (assetName: string) => {
		setSelectedAsset(assetName)
		setIsOpen(false)
	}

	useEffect(() => {
		// || wei(amount).gt(sUSDWalletBalance)
		if (!amount || amount === '0') {
			setShowPreview(false)
			isFetchingPreview.current = false
			return
		}
		isFetchingPreview.current = true

		const inputValue = amount !== '' ? wei(amount) : wei(0)

		// sUSDWalletBalance.gt(inputValue) ?
		const usdValue = sUSDWalletBalance.sub(inputValue)
		updatedUsdBalance.current = formatDollars(usdValue)

		const equityValue = portfolio.smartMargin.add(inputValue)
		updatedWalletBallance.current = formatDollars(equityValue)
		updatedBuyingPower.current = formatDollars(equityValue.mul(50))

		setShowPreview(true)
		isFetchingPreview.current = false
	}, [amount, togglePreview])

	return (
		<Container>
			<Body fontSize={20} color="primary">
				{t('futures.market.manage.deposit.title')}
			</Body>
			<DropdownWrapper>
				<BalanceText>{t('futures.market.manage.deposit.asset')}</BalanceText>
				<DropdownContainer isOpen={isOpen}>
					<SelectedOption onClick={() => setIsOpen(!isOpen)}>
						<AssetIconWrapper>
							{assets.find((asset) => asset.name === selectedAsset)?.icon}
						</AssetIconWrapper>
						<AssetText>
							<AssetName>{selectedAsset}</AssetName>
							<AssetSymbol>
								{assets.find((asset) => asset.name === selectedAsset)?.symbol}
							</AssetSymbol>
						</AssetText>
						<DropdownArrowWrap isOpen={isOpen}>
							<DropdownArrow />
						</DropdownArrowWrap>
					</SelectedOption>
					{isOpen && (
						<DropdownOptions>
							{assets.map((asset, index) => (
								<Option key={index} onClick={() => handleAssetSelect(asset.name)}>
									<AssetIconWrapper>{asset.icon}</AssetIconWrapper>
									<AssetText>
										<AssetName>{asset.name}</AssetName>
										<AssetSymbol>{asset.symbol}</AssetSymbol>
									</AssetText>
								</Option>
							))}
						</DropdownOptions>
					)}
				</DropdownContainer>
			</DropdownWrapper>
			<BalanceWrapper>
				<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
				<BalanceContainer>
					{/* <div style={{ position: 'relative' }}> */}
					<InputContainer
						placeholder="0.00"
						value={amount.toString()}
						onChange={(_, v) => {
							isFetchingPreview.current = true
							setAmount(v !== '' ? v : '')
						}}
						right={
							<StyledMaxButton onClick={handleMaxBalance} amount={amount}>
								{t('futures.market.trade.margin.modal.max')}
							</StyledMaxButton>
						}
					/>
					<EquityRowsWrap>
						<EquityCardRow>
							<EquityCardLabel>
								{t('dashboard.oneclick.fund.available')}&nbsp;&nbsp;
								<AssetSymbol>SUSD</AssetSymbol>
							</EquityCardLabel>
							<EquityCardValue>
								{formatDollars(sUSDWalletBalance)?.replace('$', '')}
								{showPreview && (
									<>
										<PreviewArrow>→</PreviewArrow>
										<PreviewValue negative={sUSDWalletBalance.gt(wei(amount || 0))}>
											{updatedUsdBalance.current?.replace('$', '')}
										</PreviewValue>
									</>
								)}
							</EquityCardValue>
						</EquityCardRow>
					</EquityRowsWrap>
				</BalanceContainer>
			</BalanceWrapper>
			{wei(amount === '' ? 0 : amount).gt(sUSDWalletBalance) && (
				<ErrorBox>{t('futures.market.manage.deposit.limit-exceed')}</ErrorBox>
			)}
			{walletAddress && !isL2 && (
				<ErrorBox onClick={switchToL2} style={{ cursor: 'pointer' }}>
					{t('futures.market.manage.deposit.wrong-network')}
				</ErrorBox>
			)}
			<WarningBox color="secondary">{t('futures.market.manage.deposit.alert')}</WarningBox>
			<OptimismButton onClick={() => setTab('bridge')}>
				{t('futures.market.manage.deposit.bridge')}
				<BaseIconWrapper>
					<OptimisimIcon /> {t('futures.market.manage.deposit.optimism')}
				</BaseIconWrapper>
			</OptimismButton>
			<EquityCardContainer>
				<EquityRowsWrap>
					<EquityCardRow>
						<EquityCardLabel>
							{t('futures.market.manage.deposit.equity')}
							<EquityCardCurrencyBadge>
								<span>USD</span>
							</EquityCardCurrencyBadge>
						</EquityCardLabel>
						<EquityCardValue>
							{formatDollars(portfolio.smartMargin)}
							{showPreview && (
								<>
									<PreviewArrow green={true}>→</PreviewArrow>
									{updatedWalletBallance.current}
								</>
							)}
						</EquityCardValue>
					</EquityCardRow>
					<EquityCardRow>
						<EquityCardLabel>{t('futures.market.manage.deposit.bp')}</EquityCardLabel>
						<EquityCardValue>
							{formatDollars(wei(portfolio.smartMargin).mul(50))}
							{showPreview && (
								<>
									<PreviewArrow green={true}>→</PreviewArrow>
									{updatedBuyingPower.current}
								</>
							)}
						</EquityCardValue>
					</EquityCardRow>
				</EquityRowsWrap>
				<StyledButton
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						handleDeposit()
					}}
					disabled={
						depositing ||
						isFetchingPreview.current ||
						wei(amount === '' ? 0 : amount).eq(0) ||
						wei(amount === '' ? 0 : amount).gt(sUSDWalletBalance)
					}
					isLoading={depositing || isFetchingPreview.current}
					data-submit-button
				>
					{depositing || isFetchingPreview.current ? (
						<Loader />
					) : amount ? (
						t('futures.market.manage.deposit.title')
					) : (
						t('futures.market.manage.deposit.enter-amount')
					)}
				</StyledButton>
			</EquityCardContainer>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`

const OptimismButton = styled(Button)`
	${media.greaterThan('md')`
    width: 298px;
  `} color: ${({ theme }) => theme.colors.selectedTheme.red};
	border: none;
	background: #ff53531a;
	text-transform: none;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	font-size: 13px;
	&:hover {
		background: #ff53531a;
	}
`
const BaseIconWrapper = styled.div`
	display: flex;
	align-items: center;
`
const BalanceWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
	font-feature-settings: 'zero' 0;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
	flex-direction: column;
	> div {
		width: 100%;
	}
	gap: 12px;
	padding-bottom: 8px;
`

const BalanceText = styled.p`
	font-size: 12px;
	justify-content: space-between;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
`
const WalletBalanceTextWrap = styled.div`
	font-size: 13px;
	justify-content: space-between;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
		cursor: pointer;
	}
`

const StyledBalanceText = styled.div`
	padding: 5px 0;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	display: flex;
	align-items: center;
`

const InputContainer = styled(NumericInput)`
	// margin-top: -10px;
	height: 40px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	border: 0;
	font-size: 14px;
	input {
		color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
		font-size: 14px;
		::placeholder {
			color: ${(props) => props.theme.colors.selectedTheme.modal.text.gray};
			font-family: ${(props) => props.theme.fonts.regular};
			font-weight: 400;
			font-size: 14px;
		}
	}
`

const ErrorBox = styled.div`
	background-color: #ff53531a;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	padding: 10px 12px;
	border-left: 4px solid #ff5353;
	border-radius: 8px;
	font-size: 13px;
	line-height: 1.5;
	width: 100%;
	// margin-top: 10px;
`
const WarningBox = styled.div`
	background-color: #ffb6481a;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	padding: 10px 12px;
	border-left: 4px solid #ffb648;
	border-radius: 8px;
	font-size: 13px;
	line-height: 1.5;
	width: 100%;
	font-feature-settings: 'zero' 0;
`

const EquityCardContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.dark};
	padding-top: 12px;
	gap: 8px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	border-radius: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	font-feature-settings: 'zero' 0;
`

const EquityRowsWrap = styled.div`
	padding: 0px 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
const EquityCardRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
`

const EquityCardLabel = styled.span`
	font-size: 12px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	position: relative;
	display: flex;
	align-items: center;
`

const EquityCardValue = styled.span`
	font-size: 14px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-weight: 400;
	display: flex;
	gap: 2px;
	align-items: center;
`

const EquityCardCurrencyBadge = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.gray};
	font-size: 10px;
	margin-left: 8px;
	padding: 2px 4px 2px 4px;
	border-radius: 4px;
	height: 20px;
	width: 32px;
	display: flex;
	align-items: center;
	span {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
	}
`

const PreviewValue = styled.span<{ negative?: boolean }>`
	color: ${(props) =>
		!props.negative
			? props.theme.colors.selectedTheme.red
			: props.theme.colors.selectedTheme.modal.text.primary};
`
const PreviewArrow = styled.span<{ green?: boolean }>`
	color: ${(props) =>
		props.green ? props.theme.colors.selectedTheme.green : props.theme.colors.selectedTheme.red};
`

const StyledMaxButton = styled.span<{ amount: string }>`
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		!props.amount
			? props.theme.colors.selectedTheme.modal.text.gray
			: props.theme.colors.selectedTheme.modal.text.primaryWhite};
	font-family: ${(props) => props.theme.fonts.regular};
	text-transform: uppercase;
	letter-spacing: 0.04em;
	cursor: pointer;
	font-weight: 400;
`

const StyledButton = styled(Button)<{ isLoading: boolean }>`
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	${(props) =>
		props.disabled
			? css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.disabled} !important;
					border: 0 !important;
					color: #ffffff80 !important;
			  `
			: css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.active} !important;
					border: 0 !important;
					color: #ffffff !important;
			  `};
	${(props) =>
		props.isLoading &&
		css`
			background-color: transparent !important;
			border: ${(props) => props.theme.colors.selectedTheme.border}!important;
		`};
`

const DropdownWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`

const DropdownContainer = styled.div<{ isOpen: boolean }>`
	width: 100%;
	position: relative;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
	border-radius: ${({ isOpen }) => (isOpen ? '6px 6px 0px 0px' : '6px')};
`
const AssetSymbol = styled.span`
	font-size: 12px;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primary};
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.gray};
	padding: 2px 6px;
	border-radius: 4px;
`

const SelectedOption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.selectedBackground};
	padding: 10px;
	// border-radius: 8px;
	cursor: pointer;
	border-radius: 6px 6px 6px 6px;
	// ${AssetSymbol} {
	// 	background: #454258;
	// }
`

const AssetIconWrapper = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 20px;
		height: 20px;
		overflow: visible;
	}
`

const AssetText = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const AssetName = styled.span`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
`

const DropdownArrowWrap = styled.div<{ isOpen: boolean }>`
	margin-left: auto;
	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
		transition: transform 0.1s ease;
		path {
			fill: ${({ theme, isOpen }) =>
				isOpen
					? theme.colors.selectedTheme.modal.text.primaryWhite
					: theme.colors.selectedTheme.modal.arrowColor};
		}
	}
`

const DropdownOptions = styled.div`
	position: absolute;
	top: 44px;
	width: 100%;
	border-radius: 0px 0px 6px 6px;
	z-index: 100;
	// box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
`

const Option = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
`

export default Deposit
