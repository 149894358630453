import { ClaimHistories, ReferralsData } from '@dextoroprotocol/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { calculateFeesForAccount } from '@dextoroprotocol/sdk/utils'

import { notifyError } from 'components/ErrorNotifier'
import { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import { ZERO_AFFILIATE_REWARD_DATA, ZERO_REFERRAL_DATA } from './reducer'
import { selectReferralSupportedNetwork } from './selectors'
import { AffiliateReward, Epoch, ReferralsTradingData } from './types'

export const fetchReferralsData = createAsyncThunk<ReferralsData, void, ThunkConfig>(
	'referrals/fetchReferralsData',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectReferralSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_REFERRAL_DATA

			const referralsData = await sdk.referrals.getReferralsData(wallet)
			return referralsData
		} catch (err) {
			logError(err)
			notifyError('Failed to fetch staking data', err)
			throw err
		}
	}
)

export const fetchReferralsTradingData = createAsyncThunk<
	ReferralsTradingData,
	Epoch[],
	ThunkConfig
>('referrals/fetchReferralsTradingData', async (epochData, { getState, extra: { sdk } }) => {
	try {
		const wallet = selectWallet(getState())
		const supportedNetwork = selectReferralSupportedNetwork(getState())
		if (!wallet || !supportedNetwork) return {}

		const reversedEpochData = epochData.sort((a, b) => b.period - a.period)
		const tradingDataByAccount = await sdk.referrals.getTradingDataForAccount(
			wallet,
			reversedEpochData[reversedEpochData.length - 1].start,
			reversedEpochData[0].end
		)

		const tradingData = reversedEpochData.map((epoch) => {
			const epochData = tradingDataByAccount.filter(
				(data) => data.timestamp.gte(epoch.start) && data.timestamp.lt(epoch.end)
			)
			return {
				epoch: epoch.period,
				volume: calculateFeesForAccount(epochData).toString(),
				tradesCount: epochData.length,
			}
		})

		return { tradingData }
	} catch (err) {
		notifyError('Failed to fetch referrals trading data', err)
		throw err
	}
})

export const fetchAffiliateUSDTEarned = createAsyncThunk<AffiliateReward, void, ThunkConfig>(
	'referrals/fetchAffiliateUSDTEarned',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectReferralSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_AFFILIATE_REWARD_DATA

			const { epochPeriod } = await sdk.dextoroToken.getStakingData()
			const { claimableRewards, totalRewards } = await sdk.referrals.getAffiliateUSDTEarned(
				wallet,
				epochPeriod
			)

			return {
				affiliateClaimableRewards: claimableRewards,
				affiliateTotalRewards: totalRewards.toString(),
			}
		} catch (err) {
			notifyError('Failed to fetch affiliate usdt earned for the account', err)
			throw err
		}
	}
)

export const fetchClaimHistories = createAsyncThunk<ClaimHistories, void, ThunkConfig>(
	'referrals/fetchClaimHistories',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectReferralSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return {}

			const claimHistories = await sdk.referrals.getClaimHistories(wallet)
			return { claimHistories: claimHistories }
		} catch (err) {
			notifyError('Failed to fetch affiliate usdt earned for the account', err)
			throw err
		}
	}
)
