import { FC, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import LightOnIcon from 'assets/svg/app/light-on.svg'
import BaseModal from 'components/BaseModal'
import { FlexDiv, FlexDivCol } from 'components/layout/flex'
import { Body } from 'components/Text'

import { setOpenModal, setWithdrawModalTab } from 'state/app/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { showDefaultTab } from 'state/app/selectors'
import useWindowSize from 'hooks/useWindowSize'

import Deposit from './Deposit'
import Withdraw from './Withdraw'
import OneClickTrading from './OneClickTrading'
import Bridge from './Bridge'
import { WithdrawTabs } from 'state/app/types'
import { css } from 'styled-components'

const ManageAccount: FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const defaultTab = useAppSelector(showDefaultTab)
	const { deviceType } = useWindowSize()

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const [tab, setTab] = useState<'deposit' | 'withdraw' | 'bridge' | 'oneclick-trading'>(defaultTab)

	const onClose = () => {
		dispatch(setOpenModal(null))
		dispatch(setWithdrawModalTab(WithdrawTabs.WithdrawUSD))
	}

	return (
		<StyledBaseModal
			onDismiss={onClose}
			isOpen={true}
			title={t('futures.market.manage.title')}
			tab={tab}
		>
			{isMobile ? (
				<FlexDivCol>
					<MobileContent>
						{tab === 'deposit' && <Deposit setTab={setTab} />}
						{tab === 'withdraw' && <Withdraw />}
						{tab === 'bridge' && <Bridge />}
						{tab === 'oneclick-trading' && <OneClickTrading />}
					</MobileContent>
					<MobileTabs>
						<Tab active={tab === 'deposit'} onClick={() => setTab('deposit')}>
							Deposit
						</Tab>
						<Tab
							active={tab === 'withdraw'}
							onClick={() => {
								setTab('withdraw')
								dispatch(setWithdrawModalTab(WithdrawTabs.WithdrawUSD))
							}}
						>
							Withdraw
						</Tab>
						<Tab active={tab === 'bridge'} onClick={() => setTab('bridge')}>
							Bridge & Swap
						</Tab>
						<Tab active={tab === 'oneclick-trading'} onClick={() => setTab('oneclick-trading')}>
							<LightOnIcon />
							One-Click Trading
						</Tab>
					</MobileTabs>
				</FlexDivCol>
			) : (
				<FlexDiv>
					<Tabs>
						<Tab active={tab === 'deposit'} onClick={() => setTab('deposit')}>
							Deposit
						</Tab>
						<Tab
							active={tab === 'withdraw'}
							onClick={() => {
								setTab('withdraw')
								dispatch(setWithdrawModalTab(WithdrawTabs.WithdrawUSD))
							}}
						>
							Withdraw
						</Tab>
						<Tab active={tab === 'bridge'} onClick={() => setTab('bridge')}>
							Bridge & Swap
						</Tab>
						<Tab active={tab === 'oneclick-trading'} onClick={() => setTab('oneclick-trading')}>
							<LightOnIcon />
							One-Click Trading
						</Tab>
					</Tabs>
					<Content tab={tab}>
						{tab === 'deposit' && <Deposit setTab={setTab} />}
						{tab === 'withdraw' && <Withdraw />}
						{tab === 'bridge' && <Bridge />}
						{tab === 'oneclick-trading' && <OneClickTrading />}
					</Content>
				</FlexDiv>
			)}
		</StyledBaseModal>
	)
}

export default ManageAccount

const StyledBaseModal = styled(BaseModal)<{
	tab: 'deposit' | 'withdraw' | 'bridge' | 'oneclick-trading'
}>`
	[data-reach-dialog-content] {
		width: ${({ tab }) =>
			tab === 'withdraw' || tab === 'deposit'
				? '555px'
				: tab === 'oneclick-trading'
				? '650px'
				: '750px'};
		transition: width 0.2s ease;
	}
	.card-header {
		color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
		path {
			fill: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
		}
	}
`

const Tabs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 10px 16px 0 0;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	width: 270px;
`

const Tab = styled(Body)<{ active: boolean }>`
	font-size: 16px;
	font-weight: 400;
	background: ${({ theme, active }) =>
		active ? theme.colors.selectedTheme.modal.backgroundColor.tab.dark : 'transparent'};
	color: ${({ theme, active }) =>
		active
			? theme.colors.selectedTheme.modal.text.primaryWhite
			: theme.colors.selectedTheme.modal.text.tab.gray};
	border-radius: 8px;
	padding: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
	white-space: nowrap;

	svg {
		width: 18px;
	}

	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
`

const Content = styled.div<{
	tab: 'deposit' | 'withdraw' | 'bridge' | 'oneclick-trading'
}>`
	padding: ${({ tab }) => (tab !== 'bridge' ? '8px 0px 16px 16px' : '8px 8px 16px 20px')};
	width: 100%;

	${(props) =>
		props.tab === 'bridge' &&
		css`
			min-height: 472px;
		`}
`

const MobileContent = styled.div`
	min-height: 472px;
`

const MobileTabs = styled.div`
	display: flex;
	gap: 8px;
	overflow-x: scroll;
	margin-top: 8px;
`
