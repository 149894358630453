import { useState } from 'react'
import styled from 'styled-components'
import { toast, ToastContainer } from 'react-toastify'
import { truncateString } from '@dextoroprotocol/sdk/utils'
import ErrorIcon from 'assets/svg/app/error.svg'
import {
	CloseButton,
	Container,
	NotificationContent,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'
import media from 'styles/media'

function ToastContent({ message, errorDetails }: { message: string; errorDetails?: string }) {
	const [expanded, setExpanded] = useState(false)

	if (!errorDetails)
		return (
			<Container>
				<NotificationContent>
					<NotificationTitle>
						<NotificationIconDiv type="error">
							<ErrorIcon />
						</NotificationIconDiv>
						{message}
					</NotificationTitle>
				</NotificationContent>
			</Container>
		)

	return (
		<Container>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv type="error">
						<ErrorIcon />
					</NotificationIconDiv>
					<div>
						{message}
						<br />
						<ErrorDetails
							onClick={() => setExpanded(!expanded)}
							style={{ fontSize: 13, textDecoration: 'underline' }}
						>
							{expanded ? 'Hide' : 'Show'} Details
						</ErrorDetails>
					</div>
				</NotificationTitle>
				{expanded ? <ErrorDetails>{errorDetails}</ErrorDetails> : null}
			</NotificationContent>
		</Container>
	)
}

export const notifyError = (message: string, error?: Error) => {
	const friendlyError = formatError(error?.message)
	const truncated = truncateString(message)
	toast.error(<ToastContent message={truncated} errorDetails={friendlyError} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'notifications',
	})
}

export default function ErrorNotifier() {
	return (
		<StyledToastContainer
			icon={() => null}
			enableMultiContainer
			containerId="notifications"
			position="top-right"
			autoClose={10000}
			hideProgressBar={true}
			newestOnTop
			rtl={false}
			pauseOnFocusLoss
			pauseOnHover
			closeOnClick={false}
			closeButton={({ closeToast }: any) => <CloseButton closeToast={closeToast} />}
		/>
	)
}

export const ERROR_MESSAGES = {
	ORDER_PENDING: 'Previous order is pending, please wait for it to finish processing or cancel it.',
	INSUFFICIENT_MARGIN: 'Insufficient margin, minimum $50 margin required.',
	INSUFFICIENT_ETH_BAL: 'Insufficient eth balance for gas cost',
	CANNOT_CANCEL_ORDER_YET: 'Cannot cancel the order yet',
	ORDER_TOO_OLD: 'Order expired, please cancel',
	PRICE_IMPACT_EXCEEDED: 'Price exceeded desired fill price',
}

// TODO: Format more errors, especially transaction failures
export const formatError = (message?: string) => {
	if (!message) return ''
	const lowerCaseMessage = message.toLowerCase()
	if (lowerCaseMessage.includes('insufficient funds for intrinsic transaction cost'))
		return ERROR_MESSAGES.INSUFFICIENT_ETH_BAL
	if (lowerCaseMessage.includes('insufficient margin')) return ERROR_MESSAGES.INSUFFICIENT_MARGIN
	if (lowerCaseMessage.includes('previous order exists')) return ERROR_MESSAGES.ORDER_PENDING
	if (lowerCaseMessage.includes('cannot cancel yet')) return ERROR_MESSAGES.CANNOT_CANCEL_ORDER_YET
	if (lowerCaseMessage.includes('order too old')) return ERROR_MESSAGES.ORDER_TOO_OLD
	if (lowerCaseMessage.includes('price impact exceeded'))
		return ERROR_MESSAGES.PRICE_IMPACT_EXCEEDED
	return message
}

const StyledToastContainer = styled(ToastContainer)`
	.Toastify__toast-container {
		display: grid;
		width: 272px;
		top: 48px;
		right: 0;
		overflow-y: auto;
		padding: 16px 16px 0 0;
		max-height: calc(100vh - 48px);
	}
	.Toastify__toast {
		padding: 0;
		width: 272px;
		border-radius: 8px;
		margin-bottom: 16px;
		font-feature-settings: 'zero' 0;
		font-family: ${(props) => props.theme.fonts.regular};
		border: ${(props) => props.theme.colors.selectedTheme.border};
		color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
		background: ${(props) => props.theme.colors.selectedTheme.notification.background};
		position: relative;
		overflow: initial;
		cursor: pointer;

		.notification-close-button {
			display: none !important;
			${media.lessThan('lg')`
      			display: flex !important;
			`}
		}
		&:hover {
			.notification-close-button {
				display: flex !important;
			}
		}

		${media.lessThan('md')`
			width: 90%;
			margin: 12px auto;
		`}
	}
	.Toastify__toast-body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}
	.Toastify__progress-bar {
		background: ${(props) => props.theme.colors.selectedTheme.red};
	}
`

// TODO: Use re-useable component once merged with component refactor

const ErrorDetails = styled.span`
	font-size: 11px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`
