// TODO: Make this dynamic
import { FuturesMarketKey } from '../types';
export const V3_SYNTH_MARKET_IDS = {
    SNXUSD: 0,
    SNXETH: 2,
    SNXBTC: 3,
};
export const V3_PERPS_MARKET_IDS = {
    SNXETH: 5,
};
export const V3_PERPS_ID_TO_V2_MARKET_KEY = {
    [V3_PERPS_MARKET_IDS.SNXETH]: FuturesMarketKey.sETHPERP,
};
export const V3_PERPS_ID_TO_SYNTH_ID = {
    [V3_PERPS_MARKET_IDS.SNXETH]: V3_SYNTH_MARKET_IDS.SNXETH,
};
