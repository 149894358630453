import React from 'react'
import { toast } from 'react-toastify'
import { truncateString } from '@dextoroprotocol/sdk/utils'
import {
	Container,
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'

function ToastContent({ message, details }: { message: string; details?: string }) {
	// const [expanded, setExpanded] = useState(false)

	return (
		<Container>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv>{/* <icon/> */}</NotificationIconDiv>
					{message}
				</NotificationTitle>
				{details ? <NotificationDetails text={details} /> : <></>}
			</NotificationContent>
		</Container>
	)
}

export const notifyStakingRewards = (message: string) => {
	const truncated = truncateString(message)
	toast.info(<ToastContent message={truncated} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'info',
	})
}
