import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useState, FC, useMemo } from 'react'

import LightOnIcon from 'assets/svg/app/light-on.svg'
import LightOffIcon from 'assets/svg/app/light-off.svg'
import { Body } from 'components/Text'
import Button from 'components/Button'
import ProgressSteps from 'components/ProgressSteps'
import { FlexDiv, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import { notifyError } from 'components/ErrorNotifier'
import Loader from 'components/Loader'

import { monitorTransaction } from 'contexts/RelayerContext'
import { addDelegate } from 'state/oneClickTrading/helper'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import { selectSmartAccountAddress } from 'state/oneClickTrading/selectors'
import { setDelegated } from 'state/oneClickTrading/reducer'
import { FetchStatus } from 'state/types'
import { setOpenModal } from 'state/app/reducer'
import logError from 'utils/logError'
import useWindowSize from 'hooks/useWindowSize'

import { ManageActivateProps } from './types'

const ManageActivate: FC<ManageActivateProps> = ({ isModal = false }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const { deviceType } = useWindowSize()

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const [tab, setTab] = useState('one-click')
	const [processing, setProcessing] = useState(false)

	const handleActivate = async () => {
		if (tab === 'one-click') {
			if (!smartMarginAccount || !smartAccountAddress) {
				notifyError('Please create smart wallet account first')
				return
			}
			setProcessing(true)
			try {
				const hash = await addDelegate(smartMarginAccount!, smartAccountAddress)
				monitorTransaction({
					txHash: hash!,
					onTxConfirmed: () => {
						dispatch(setDelegated(true))
						setProcessing(false)
					},
					onTxFailed: () => {
						dispatch({ type: 'oneClick/addDelegate', payload: FetchStatus.Error })
					},
				})
			} catch (err) {
				notifyError('Transaction failed', err)
				logError(err)
				setProcessing(false)
			}
		} else {
			dispatch(setOpenModal(null))
		}
	}

	const getText = () => {
		if (tab === 'one-click') {
			if (processing) return <Loader />
			return t('dashboard.oneclick.manage.activate-button')
		}
		if (isModal) return t('dashboard.oneclick.manage.back-trading')
		return t('dashboard.oneclick.manage.sign-button')
	}

	return (
		<Container>
			<Card onClick={() => setTab('one-click')} isActive={tab === 'one-click'} mobile={isMobile}>
				<FlexDivRow justifyContent="space-between">
					<FlexDivRowCentered columnGap="8px">
						<LightOnIcon />
						<Body fontSize={isMobile ? 15 : 18}>{t('dashboard.oneclick.title')}</Body>
						<SuggestedDiv mobile={isMobile}>
							<LightOffIcon />{' '}
							<Body fontSize={isMobile ? 12 : 13}>{t('dashboard.oneclick.manage.suggested')}</Body>
						</SuggestedDiv>
					</FlexDivRowCentered>
					<Circle isActive={tab === 'one-click'} />
				</FlexDivRow>
				<Body fontSize={13} color="secondary">
					{t('dashboard.oneclick.manage.oneclick-text')}
				</Body>
			</Card>
			<Card onClick={() => setTab('sign-tx')} isActive={tab === 'sign-tx'} mobile={isMobile}>
				<FlexDivRow justifyContent="space-between">
					<Body fontSize={isMobile ? 15 : 18}>{t('dashboard.oneclick.manage.sign-title')}</Body>
					<Circle isActive={tab === 'sign-tx'} />
				</FlexDivRow>
				<Body fontSize={13} color="secondary">
					{t('dashboard.oneclick.manage.sign-text')}
				</Body>
			</Card>
			{isModal && <ProgressSteps step={1} totalSteps={3} complete={false} />}
			<Button onClick={handleActivate} disabled={processing}>
				{getText()}
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Card = styled.div<{ isActive: boolean; mobile: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: ${({ mobile }) => (mobile ? '12px' : '16px 24px')};
	border-radius: 8px;
	border: ${(props) =>
		props.isActive ? '1px solid #6966ff' : props.theme.colors.selectedTheme.border};
	cursor: pointer;
	user-select: none;
`

const SuggestedDiv = styled(FlexDiv)<{ mobile: boolean }>`
	border: 1px solid #6966ff;
	border-radius: 16px;
	padding: ${({ mobile }) => (mobile ? '2px 10px 2px 5px' : '4px 12px 4px 8px')};
	gap: 4px;
	display: flex;
	align-items: center;

	svg {
		path {
			fill: #6966ff;
		}
	}
`

const Circle = styled.div<{ isActive: boolean }>`
	z-index: 2;
	height: 18px;
	width: 18px;
	border-radius: 9px;
	border: 3px solid ${({ theme }) => theme.colors.selectedTheme.gray2};
	background-color: ${({ theme, isActive }) =>
		isActive ? theme.colors.selectedTheme.yellow : theme.colors.selectedTheme.background};
`

export default ManageActivate
