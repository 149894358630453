import { memo, FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import HelpIcon from 'assets/svg/app/question-mark.svg'
import DextoroLogo from 'assets/svg/brand/DTORO.svg'
import OptimismLogo from 'assets/svg/providers/optimism.svg'

import Heading from './Heading'

type LogoTextProps = {
	yellow?: boolean
	isToolTip?: boolean
	dextoroIcon?: boolean
	bold?: boolean
	size?: 'medium' | 'large'
	isStake?: boolean
	removeCross?: boolean
	children?: ReactNode
}

export const LogoText: FC<LogoTextProps> = memo(
	({
		children,
		yellow,
		size = 'large',
		isToolTip = false,
		bold = true,
		dextoroIcon = true,
		isStake,
		removeCross,
	}) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<TitleText
					$yellow={yellow}
					$bold={bold}
					$size={size}
					isStake={isStake}
					$removeCross={removeCross}
				>
					{children}
				</TitleText>
				{dextoroIcon ? (
					<DextoroLogo
						height={isStake && size === 'medium' ? 24 : isStake ? 30 : 28}
						width={isStake && size === 'medium' ? 24 : isStake ? 30 : 28}
					/>
				) : (
					<OptimismLogo height={18} width={18} />
				)}
				{isToolTip && <SpacedHelpIcon />}
			</div>
		)
	}
)

const TitleText = styled(Heading)<{
	$yellow?: boolean
	$mono?: boolean
	$bold?: boolean
	$size?: 'medium' | 'large'
	isStake?: boolean
	$removeCross?: boolean
}>`
	font-size: ${(props) =>
		props.isStake && props.$size === 'medium'
			? '16'
			: props.isStake
			? '24'
			: props.$size === 'large'
			? '26'
			: '16'}px;
	margin-right: ${(props) => (props.isStake ? '4px' : '8px')};
	font-family: ${(props) => (props.$bold ? props.theme.fonts.monoBold : props.theme.fonts.mono)};
	font-feature-settings: 'zero' 1;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};

	${(props) =>
		props.$removeCross &&
		css`
			font-feature-settings: 'zero' 0;
		`}

	${(props) =>
		props.$yellow &&
		css`
			color: ${(props) => props.theme.colors.selectedTheme.yellow};
		`}
`

const SpacedHelpIcon = styled(HelpIcon)`
	margin-left: 8px;
`

export default LogoText
