/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "address",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "_resolver",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "flags",
                type: "address",
            },
        ],
        name: "AggregatorWarningFlagsUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "equivalent",
                type: "address",
            },
        ],
        name: "AtomicEquivalentForDexPricingUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newExchangeFeeRate",
                type: "uint256",
            },
        ],
        name: "AtomicExchangeFeeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newMaxVolume",
                type: "uint256",
            },
        ],
        name: "AtomicMaxVolumePerBlockUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newWindow",
                type: "uint256",
            },
        ],
        name: "AtomicTwapWindowUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newVolatilityConsiderationWindow",
                type: "uint256",
            },
        ],
        name: "AtomicVolatilityConsiderationWindowUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newVolatilityUpdateThreshold",
                type: "uint256",
            },
        ],
        name: "AtomicVolatilityUpdateThresholdUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "name",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "destination",
                type: "address",
            },
        ],
        name: "CacheUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "collapseFeeRate",
                type: "uint256",
            },
        ],
        name: "CollapseFeeRateUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
        ],
        name: "CrossChainSynthTransferEnabledUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "enum MixinSystemSettings.CrossDomainMessageGasLimits",
                name: "gasLimitType",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newLimit",
                type: "uint256",
            },
        ],
        name: "CrossDomainMessageGasLimitChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "debtSnapshotStaleTime",
                type: "uint256",
            },
        ],
        name: "DebtSnapshotStaleTimeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "rate",
                type: "uint256",
            },
        ],
        name: "EtherWrapperBurnFeeRateUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "maxETH",
                type: "uint256",
            },
        ],
        name: "EtherWrapperMaxETHUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "rate",
                type: "uint256",
            },
        ],
        name: "EtherWrapperMintFeeRateUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "dynamicFeeRounds",
                type: "uint256",
            },
        ],
        name: "ExchangeDynamicFeeRoundsUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "dynamicFeeThreshold",
                type: "uint256",
            },
        ],
        name: "ExchangeDynamicFeeThresholdUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "dynamicFeeWeightDecay",
                type: "uint256",
            },
        ],
        name: "ExchangeDynamicFeeWeightDecayUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newExchangeFeeRate",
                type: "uint256",
            },
        ],
        name: "ExchangeFeeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "maxDynamicFee",
                type: "uint256",
            },
        ],
        name: "ExchangeMaxDynamicFeeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newFeePeriodDuration",
                type: "uint256",
            },
        ],
        name: "FeePeriodDurationUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newReward",
                type: "uint256",
            },
        ],
        name: "FlagRewardUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "interactionDelay",
                type: "uint256",
            },
        ],
        name: "InteractionDelayUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newRatio",
                type: "uint256",
            },
        ],
        name: "IssuanceRatioUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newReward",
                type: "uint256",
            },
        ],
        name: "LiquidateRewardUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newDelay",
                type: "uint256",
            },
        ],
        name: "LiquidationDelayUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newDuration",
                type: "uint256",
            },
        ],
        name: "LiquidationEscrowDurationUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newPenalty",
                type: "uint256",
            },
        ],
        name: "LiquidationPenaltyUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newRatio",
                type: "uint256",
            },
        ],
        name: "LiquidationRatioUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "minimumStakeTime",
                type: "uint256",
            },
        ],
        name: "MinimumStakeTimeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "oldOwner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnerChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnerNominated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "threshold",
                type: "uint256",
            },
        ],
        name: "PriceDeviationThresholdUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "synthKey",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
        ],
        name: "PureChainlinkPriceForAtomicSwapsEnabledUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "rateStalePeriod",
                type: "uint256",
            },
        ],
        name: "RateStalePeriodUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newPenalty",
                type: "uint256",
            },
        ],
        name: "SelfLiquidationPenaltyUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newPenalty",
                type: "uint256",
            },
        ],
        name: "SnxLiquidationPenaltyUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newTargetThreshold",
                type: "uint256",
            },
        ],
        name: "TargetThresholdUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
        ],
        name: "TradingRewardsEnabled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "waitingPeriodSecs",
                type: "uint256",
            },
        ],
        name: "WaitingPeriodSecsUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "wrapper",
                type: "address",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "rate",
                type: "int256",
            },
        ],
        name: "WrapperBurnFeeRateUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "wrapper",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "maxTokenAmount",
                type: "uint256",
            },
        ],
        name: "WrapperMaxTokenAmountUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "wrapper",
                type: "address",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "rate",
                type: "int256",
            },
        ],
        name: "WrapperMintFeeRateUpdated",
        type: "event",
    },
    {
        constant: true,
        inputs: [],
        name: "CONTRACT_NAME",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: false,
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "aggregatorWarningFlags",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "atomicEquivalentForDexPricing",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "atomicExchangeFeeRate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "atomicMaxVolumePerBlock",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "atomicTwapWindow",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "atomicVolatilityConsiderationWindow",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "atomicVolatilityUpdateThreshold",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "address",
                name: "collateral",
                type: "address",
            },
        ],
        name: "collapseFeeRate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "crossChainSynthTransferEnabled",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "enum MixinSystemSettings.CrossDomainMessageGasLimits",
                name: "gasLimitType",
                type: "uint8",
            },
        ],
        name: "crossDomainMessageGasLimit",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "debtSnapshotStaleTime",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "etherWrapperBurnFeeRate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "etherWrapperMaxETH",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "etherWrapperMintFeeRate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "exchangeDynamicFeeRounds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "exchangeDynamicFeeThreshold",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "exchangeDynamicFeeWeightDecay",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "exchangeFeeRate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "exchangeMaxDynamicFee",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "feePeriodDuration",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "flagReward",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "address",
                name: "collateral",
                type: "address",
            },
        ],
        name: "interactionDelay",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "isResolverCached",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "issuanceRatio",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "liquidateReward",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "liquidationDelay",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "liquidationEscrowDuration",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "liquidationPenalty",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "liquidationRatio",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "minimumStakeTime",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_owner",
                type: "address",
            },
        ],
        name: "nominateNewOwner",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "nominatedOwner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "priceDeviationThresholdFactor",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "currencyKey",
                type: "bytes32",
            },
        ],
        name: "pureChainlinkPriceForAtomicSwapsEnabled",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "rateStalePeriod",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: false,
        inputs: [],
        name: "rebuildCache",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "resolver",
        outputs: [
            {
                internalType: "contract AddressResolver",
                name: "",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "resolverAddressesRequired",
        outputs: [
            {
                internalType: "bytes32[]",
                name: "addresses",
                type: "bytes32[]",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "selfLiquidationPenalty",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_flags",
                type: "address",
            },
        ],
        name: "setAggregatorWarningFlags",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32",
                name: "_currencyKey",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "_equivalent",
                type: "address",
            },
        ],
        name: "setAtomicEquivalentForDexPricing",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32",
                name: "_currencyKey",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_exchangeFeeRate",
                type: "uint256",
            },
        ],
        name: "setAtomicExchangeFeeRate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_maxVolume",
                type: "uint256",
            },
        ],
        name: "setAtomicMaxVolumePerBlock",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_window",
                type: "uint256",
            },
        ],
        name: "setAtomicTwapWindow",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32",
                name: "_currencyKey",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_window",
                type: "uint256",
            },
        ],
        name: "setAtomicVolatilityConsiderationWindow",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32",
                name: "_currencyKey",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_threshold",
                type: "uint256",
            },
        ],
        name: "setAtomicVolatilityUpdateThreshold",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_collateral",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_collapseFeeRate",
                type: "uint256",
            },
        ],
        name: "setCollapseFeeRate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32",
                name: "_currencyKey",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_value",
                type: "uint256",
            },
        ],
        name: "setCrossChainSynthTransferEnabled",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "enum MixinSystemSettings.CrossDomainMessageGasLimits",
                name: "_gasLimitType",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "_crossDomainMessageGasLimit",
                type: "uint256",
            },
        ],
        name: "setCrossDomainMessageGasLimit",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_seconds",
                type: "uint256",
            },
        ],
        name: "setDebtSnapshotStaleTime",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_rate",
                type: "uint256",
            },
        ],
        name: "setEtherWrapperBurnFeeRate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_maxETH",
                type: "uint256",
            },
        ],
        name: "setEtherWrapperMaxETH",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_rate",
                type: "uint256",
            },
        ],
        name: "setEtherWrapperMintFeeRate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "rounds",
                type: "uint256",
            },
        ],
        name: "setExchangeDynamicFeeRounds",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "threshold",
                type: "uint256",
            },
        ],
        name: "setExchangeDynamicFeeThreshold",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "weightDecay",
                type: "uint256",
            },
        ],
        name: "setExchangeDynamicFeeWeightDecay",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32[]",
                name: "synthKeys",
                type: "bytes32[]",
            },
            {
                internalType: "uint256[]",
                name: "exchangeFeeRates",
                type: "uint256[]",
            },
        ],
        name: "setExchangeFeeRateForSynths",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "maxFee",
                type: "uint256",
            },
        ],
        name: "setExchangeMaxDynamicFee",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_feePeriodDuration",
                type: "uint256",
            },
        ],
        name: "setFeePeriodDuration",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "reward",
                type: "uint256",
            },
        ],
        name: "setFlagReward",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_collateral",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_interactionDelay",
                type: "uint256",
            },
        ],
        name: "setInteractionDelay",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "ratio",
                type: "uint256",
            },
        ],
        name: "setIssuanceRatio",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "reward",
                type: "uint256",
            },
        ],
        name: "setLiquidateReward",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "time",
                type: "uint256",
            },
        ],
        name: "setLiquidationDelay",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "duration",
                type: "uint256",
            },
        ],
        name: "setLiquidationEscrowDuration",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "penalty",
                type: "uint256",
            },
        ],
        name: "setLiquidationPenalty",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_liquidationRatio",
                type: "uint256",
            },
        ],
        name: "setLiquidationRatio",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_seconds",
                type: "uint256",
            },
        ],
        name: "setMinimumStakeTime",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_priceDeviationThresholdFactor",
                type: "uint256",
            },
        ],
        name: "setPriceDeviationThresholdFactor",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bytes32",
                name: "_currencyKey",
                type: "bytes32",
            },
            {
                internalType: "bool",
                name: "_enabled",
                type: "bool",
            },
        ],
        name: "setPureChainlinkPriceForAtomicSwapsEnabled",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "period",
                type: "uint256",
            },
        ],
        name: "setRateStalePeriod",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "penalty",
                type: "uint256",
            },
        ],
        name: "setSelfLiquidationPenalty",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "penalty",
                type: "uint256",
            },
        ],
        name: "setSnxLiquidationPenalty",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "percent",
                type: "uint256",
            },
        ],
        name: "setTargetThreshold",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "bool",
                name: "_tradingRewardsEnabled",
                type: "bool",
            },
        ],
        name: "setTradingRewardsEnabled",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "uint256",
                name: "_waitingPeriodSecs",
                type: "uint256",
            },
        ],
        name: "setWaitingPeriodSecs",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_wrapper",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_rate",
                type: "int256",
            },
        ],
        name: "setWrapperBurnFeeRate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_wrapper",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_maxTokenAmount",
                type: "uint256",
            },
        ],
        name: "setWrapperMaxTokenAmount",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {
                internalType: "address",
                name: "_wrapper",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_rate",
                type: "int256",
            },
        ],
        name: "setWrapperMintFeeRate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "snxLiquidationPenalty",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "targetThreshold",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "tradingRewardsEnabled",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "waitingPeriodSecs",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "address",
                name: "wrapper",
                type: "address",
            },
        ],
        name: "wrapperBurnFeeRate",
        outputs: [
            {
                internalType: "int256",
                name: "",
                type: "int256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "address",
                name: "wrapper",
                type: "address",
            },
        ],
        name: "wrapperMaxTokenAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "address",
                name: "wrapper",
                type: "address",
            },
        ],
        name: "wrapperMintFeeRate",
        outputs: [
            {
                internalType: "int256",
                name: "",
                type: "int256",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];
export class SystemSettings__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
SystemSettings__factory.abi = _abi;
