var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request, { gql } from 'graphql-request';
export const queryReferralsData = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    if (!walletAddress)
        return {};
    const response = yield request(sdk.referrals.referralGqlEndpoint, gql `
			query referralData($account: String) {
				referralCodes(first: 1000, where: { owner: $account }) {
					code
					owner
				}
				traderToReferralCodes(first: 1000, where: { referralCode_: { owner: $account } }) {
					id
					referralCode {
						code
						owner
					}
				}
				registeredReferrals(first: 1000, where: { referral: $account }) {
					referral
					referralCode {
						code
						owner
					}
					timestamp
				}
			}
		`, { account: walletAddress.toLocaleLowerCase() });
    return response || {};
});
export const queryTradersByCode = (sdk, code) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!code)
        return [];
    const response = yield request(sdk.referrals.referralGqlEndpoint, gql `
			query tradersData($code: String) {
				traderToReferralCodes(first: 1000, where: { referralCode_: { code: $code } }) {
					id
				}
			}
		`, { code: code });
    const traders = (_a = response.traderToReferralCodes) === null || _a === void 0 ? void 0 : _a.map((code) => code.id);
    return traders || [];
});
export const queryClaimHistories = (sdk, account) => __awaiter(void 0, void 0, void 0, function* () {
    if (!account)
        return {};
    const response = yield request(sdk.referrals.referralGqlEndpoint, gql `
			query getClaimHistory($account: String) {
				claimHistories(first: 1000, where: { account: $account }) {
					id
					account
					amount
					epoch
					timestamp
					transactionHash
				}
			}
		`, { account: account.toLocaleLowerCase() });
    return response || {};
});
