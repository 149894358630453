import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useCallback, useRef } from 'react'
import { wei } from '@synthetixio/wei'

import LightOnIcon from 'assets/svg/app/light-on.svg'
import TabButton from 'components/Button/TabButton'
import { Body } from 'components/Text'
import { TabPanel } from 'components/Tab'
import { FlexDiv } from 'components/layout/flex'
import { DEFAULT_TX_FEE } from 'constants/defaults'

import { useAppSelector } from 'state/hooks'
import { selectSmartAccountBalance } from 'state/oneClickTrading/selectors'

import { OneClickTradingTabs } from './types'
import Manage from './Manage'
import Fund from './Fund'
import Withdraw from './Withdraw'
import { showDefaultTab } from 'state/app/selectors'
import media from 'styles/media'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { InfoBoxContainer } from 'components/InfoBox'

const OneClickTrading: React.FC = () => {
	const { t } = useTranslation()
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const defaultTab = useAppSelector(showDefaultTab)
	const currentTheme = useAppSelector(selectCurrentTheme)
	const isFirstRender = useRef(true)

	const [currentTab, setCurrentTab] = useState(OneClickTradingTabs.Manage)

	useEffect(() => {
		if (defaultTab === 'oneclick-trading' && isFirstRender.current) {
			setCurrentTab(OneClickTradingTabs.Manage)
			isFirstRender.current = false
		} else if (!wei(smartAccountBalance).gte(DEFAULT_TX_FEE))
			setCurrentTab(OneClickTradingTabs.Fund)
	}, [smartAccountBalance])

	const handleChangeTab = useCallback(
		(tab: OneClickTradingTabs) => () => {
			setCurrentTab(tab)
		},
		[]
	)
	return (
		<Container>
			<PageTitle>
				<FlexDiv columnGap="4px" style={{ position: 'relative', left: '-5px' }}>
					<LightOnIcon />
					<Body fontSize={20}>{t('dashboard.oneclick.title')}</Body>
				</FlexDiv>
				<StyledText>{t('dashboard.oneclick.text')}</StyledText>
			</PageTitle>
			<TabButtons>
				<StyledTabButton
					onClick={handleChangeTab(OneClickTradingTabs.Manage)}
					active={currentTab === OneClickTradingTabs.Manage}
					currentTheme={currentTheme}
				>
					{t('dashboard.oneclick.manage.title')}
				</StyledTabButton>
				<StyledTabButton
					onClick={handleChangeTab(OneClickTradingTabs.Fund)}
					active={currentTab === OneClickTradingTabs.Fund}
					currentTheme={currentTheme}
				>
					{t('dashboard.oneclick.fund.title')}
				</StyledTabButton>
				<StyledTabButton
					onClick={handleChangeTab(OneClickTradingTabs.Withdraw)}
					active={currentTab === OneClickTradingTabs.Withdraw}
					currentTheme={currentTheme}
				>
					{t('dashboard.oneclick.withdraw.title')}
				</StyledTabButton>
			</TabButtons>
			<div>
				<TabPanel name={OneClickTradingTabs.Manage} activeTab={currentTab}>
					<Manage onChangeTab={handleChangeTab} />
				</TabPanel>
				<TabPanel name={OneClickTradingTabs.Fund} activeTab={currentTab}>
					<Fund />
				</TabPanel>
				<TabPanel name={OneClickTradingTabs.Withdraw} activeTab={currentTab}>
					<Withdraw />
				</TabPanel>
			</div>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PageTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const TabButtons = styled.div`
	height: 40px;
	width: 100%;
	display: flex;
	gap: 4px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.tabFill};
	padding: 4px;
	border-radius: 10px;
	${media.lessThan('mdUp')`
		width: 100%;
	`}
`

const StyledTabButton = styled.button<{ active?: boolean; currentTheme?: string }>`
	width: 100%;
	padding: 6px 12px;
	font-size: 13px;
	background-color: ${(props) =>
		props.active ? props.theme.colors.selectedTheme.modal.manage.buttonFill : 'transparent'};
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.modal.manage.tabText
			: props.theme.colors.selectedTheme.modal.manage.disabledTabText};
	font-family: Relative-Medium-Pro;
	font-weight: 500;
	font-feature-settings: 'zero' 0;
	${media.lessThan('mdUp')`
		width: 50%;
	`}
`
const StyledText = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
`

export default OneClickTrading
