import Link from 'next/link'
import { FC, memo } from 'react'
import styled from 'styled-components'

import LogoDarkSvg from 'assets/svg/brand/logo-dark.svg'
import LogoSvg from 'assets/svg/brand/logo.svg'
import ROUTES from 'constants/routes'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

const SvgLogo = memo(() => {
	const currentTheme = useAppSelector(selectCurrentTheme)

	if (window.location.pathname === ROUTES.Home.Root) {
		return <LogoSvg />
	}

	if (currentTheme === 'light') {
		return <LogoDarkSvg />
	} else {
		return <LogoSvg />
	}
})

const Logo: FC = memo(() => {
	return (
		<LogoLink href={ROUTES.Home.MainSite}>
			<LogoContainer>
				<SvgLogo />
			</LogoContainer>
		</LogoLink>
	)
})

const LogoContainer = styled.span`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 30px;
	width: 40px;
	margin-right: 4px;
`

const LogoLink = styled(Link)`
	border-right: 1px solid ${(props) => props.theme.colors.selectedTheme.border};
`

export default Logo
