var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from 'ethers';
import { hexDataSlice, id, parseEther } from 'ethers/lib/utils';
import { DEFAULT_SESSION_KEY_MANAGER_MODULE, createSmartAccountClient } from '@biconomy/account';
import { SessionKeyManagerModule } from '@biconomy/modules';
import { getABISVMSessionKeyData } from '../utils/sessionKey';
export default class BiconomyService {
    constructor(sdk) {
        this.abiSVMAddress = '0x000006bC2eCdAe38113929293d241Cf252D91861';
        this.bundlerUrl = 'https://bundler.biconomy.io/api/v2/10/dewj2189.wh1289hU-7E49-45ic-af80-35j9zdG0S';
        this.sdk = sdk;
    }
    initSmartAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            const provider = this.getProvider();
            yield provider.send('eth_requestAccounts', []);
            const signer = provider.getSigner();
            const smartAccount = yield createSmartAccountClient({
                signer,
                bundlerUrl: this.bundlerUrl,
            });
            return smartAccount;
        });
    }
    getSmartAccountAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            const smartAccount = yield this.initSmartAccount();
            return yield smartAccount.getAddress();
        });
    }
    getProvider() {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        return provider;
    }
    activeSession(smartMarginAccount, duration) {
        return __awaiter(this, void 0, void 0, function* () {
            const smartAccount = yield this.initSmartAccount();
            const smartAccountAddress = yield this.getSmartAccountAddress();
            const sessionSigner = ethers.Wallet.createRandom();
            const sessionKeyEOA = yield sessionSigner.getAddress();
            window.localStorage.setItem(`dextoro-sessionPKey-${smartAccountAddress}`, sessionSigner.privateKey);
            const sessionModule = yield SessionKeyManagerModule.create({
                moduleAddress: DEFAULT_SESSION_KEY_MANAGER_MODULE,
                smartAccountAddress,
            });
            const functionSelector = hexDataSlice(id('execute(uint8[],bytes[])'), 0, 4);
            const sessionKeyData = yield getABISVMSessionKeyData(sessionKeyEOA, {
                destContract: smartMarginAccount,
                functionSelector: functionSelector,
                valueLimit: parseEther('0.001'),
                rules: [],
            });
            const now = Math.floor(Date.now() / 1000);
            const validUntil = now + duration * 3600;
            const sessionTxData = yield sessionModule.createSessionData([
                {
                    validUntil: validUntil,
                    validAfter: now,
                    sessionValidationModule: this.abiSVMAddress,
                    sessionPublicKey: sessionKeyEOA,
                    sessionKeyData: sessionKeyData,
                },
            ]);
            const sessionId = sessionTxData.sessionIDInfo[0];
            const setSessionTrx = {
                to: DEFAULT_SESSION_KEY_MANAGER_MODULE,
                data: sessionTxData.data,
            };
            const transactionArray = [];
            const isModuleEnabled = yield this.isModuleEnabled();
            if (!isModuleEnabled) {
                const enableModuleTrx = yield smartAccount.getEnableModuleData(DEFAULT_SESSION_KEY_MANAGER_MODULE);
                transactionArray.push(enableModuleTrx);
            }
            transactionArray.push(setSessionTrx);
            const userOpResponse = yield smartAccount.sendTransaction(transactionArray);
            const transactionDetails = yield userOpResponse.wait();
            window.localStorage.setItem(`dextoro-session-id-${smartAccountAddress}`, sessionId);
            window.localStorage.setItem(`dextoro-valid-until-${smartAccountAddress}`, validUntil.toString());
            return transactionDetails.receipt.transactionHash;
        });
    }
    isModuleEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const smartAccount = yield this.initSmartAccount();
                const isEnabled = yield smartAccount.isModuleEnabled(DEFAULT_SESSION_KEY_MANAGER_MODULE);
                return isEnabled;
            }
            catch (err) {
                return false;
            }
        });
    }
    sendUserOpWithData(to, data, value) {
        return __awaiter(this, void 0, void 0, function* () {
            let smartAccount = yield this.initSmartAccount();
            const smartAccountAddress = yield this.getSmartAccountAddress();
            const provider = this.getProvider();
            const sessionKeyPrivKey = window.localStorage.getItem(`dextoro-sessionPKey-${smartAccountAddress}`);
            if (!sessionKeyPrivKey) {
                alert('Session key not found please create session');
                return;
            }
            const sessionId = window.localStorage.getItem(`dextoro-session-id-${smartAccountAddress}`);
            if (!sessionId) {
                alert('Please activate sesion first');
                return;
            }
            const sessionSigner = new ethers.Wallet(sessionKeyPrivKey).connect(provider);
            const sessionModule = yield SessionKeyManagerModule.create({
                moduleAddress: DEFAULT_SESSION_KEY_MANAGER_MODULE,
                smartAccountAddress,
            });
            smartAccount = smartAccount.setActiveValidationModule(sessionModule);
            const tx = {
                to: to,
                data: data,
                value: ethers.utils.parseEther(value).toString(),
            };
            let userOpResponse = yield smartAccount.sendTransaction([tx], {
                params: {
                    sessionSigner: sessionSigner,
                    sessionValidationModule: this.abiSVMAddress,
                    sessionID: sessionId,
                },
            });
            const { receipt } = yield userOpResponse.wait(5);
            const txReceipt = yield provider.getTransaction(receipt.transactionHash);
            return txReceipt;
        });
    }
    withdraw(to, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const smartAccount = yield this.initSmartAccount();
            const isSADeployed = yield smartAccount.isAccountDeployed();
            const provider = this.getProvider();
            if (isSADeployed) {
                const tx = {
                    to: to,
                    data: '0x',
                    value: ethers.utils.parseEther(value).toString(),
                };
                const userOpResponse = yield smartAccount.sendTransaction([tx]);
                const { receipt } = yield userOpResponse.wait(5);
                const txReceipt = yield provider.getTransaction(receipt.transactionHash);
                return txReceipt;
            }
        });
    }
}
