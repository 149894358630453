import { wei } from '@synthetixio/wei';
import { BigNumber } from 'ethers';
export const DEFAULT_NUMBER_OF_FUTURES_FEE = 9999;
export const EPOCH_START = {
    420: 1696977205,
    10: 1696977205,
};
export const WEEK = 604800;
export const VESTING_ENTRY_PAGE_SIZE = 999999;
export const DECAY_RATE = 0.0205;
export const INITIAL_WEEKLY_SUPPLY = BigNumber.from('1153846153846153846153846');
export const STAKING_REWARDS_RATIO = 0.4;
export const TRADING_REWARDS_RATIO = 0.05;
export const STAKING_HIGH_GAS_LIMIT = BigNumber.from('400000');
export const STAKING_LOW_GAS_LIMIT = BigNumber.from('200000');
export const TRADING_REWARDS_CUTOFF_EPOCH = 0;
export const STAKING_V2_REWARDS_CUTOFF_EPOCH = 12;
export const OP_REWARDS_CUTOFF_EPOCH = 0;
export const SUPPLY_RATE = wei(1).sub(wei(DECAY_RATE));
export const STAKING_ENDPOINT_OP_MAINNET = 'https://subgraph.satsuma-prod.com/05943208e921/dextoro/staking-v2/api';
export const STAKING_ENDPOINTS = {
    10: STAKING_ENDPOINT_OP_MAINNET,
};
