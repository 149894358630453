import React, { ReactNode, createContext, useContext, useState } from 'react'

// Context type
type WalkthroughContextType = {
	newTrader: string | null
	setNewTrader: React.Dispatch<string | null>
	currentStep: string | null
	setCurrentStep: (step: string | null) => void
}

const WalkthroughContext = createContext<WalkthroughContextType>({
	newTrader: null,
	setNewTrader: () => {},
	currentStep: null,
	setCurrentStep: () => {},
})
export const useWalkthroughContext = () => useContext(WalkthroughContext)

type Props = { children: ReactNode }
export const WalkthroughProvider: React.FC<Props> = ({ children }) => {
	const [currentStep, setCurrentStep] = useState<string | null>(null)
	const [newTrader, setNewTrader] = useState<string | null>(null)

	return (
		<WalkthroughContext.Provider value={{ newTrader, setNewTrader, currentStep, setCurrentStep }}>
			{children}
		</WalkthroughContext.Provider>
	)
}
