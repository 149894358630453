import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useRef, useState } from 'react'
import { wei, WeiSource } from '@synthetixio/wei'
import { formatDollars, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { css } from 'styled-components'

import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import Button from 'components/Button'
import { FlexDivRowCentered } from 'components/layout/flex'
import { notifyError } from 'components/ErrorNotifier'
import Loader from 'components/Loader'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectIdleAccountMargin } from 'state/futures/smartMargin/selectors'
import { withdrawSmartMargin } from 'state/futures/smartMargin/actions'
import { selectFuturesPortfolio, selectSubmittingSmartMarginTx } from 'state/futures/selectors'
import DropdownArrow from 'assets/svg/app/asset-dropdown-arrow.svg'
import ManageKeeperBalance from '../TradeSmartMargin/ManageKeeperBalance'
import QuestionIcon from 'components/Icons/QuestionIcon'
import media from 'styles/media'
import sUSD from 'assets/png/sUSD.png'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { selectSusdBalance } from 'state/balances/selectors'
import { selectWithdrawModalTab } from 'state/app/selectors'
import { WithdrawTabs } from 'state/app/types'
import { setWithdrawModalTab } from 'state/app/reducer'

const Withdraw: FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const sUSDMarginBalance = useAppSelector(selectIdleAccountMargin)
	const withdrawing = useAppSelector(selectSubmittingSmartMarginTx)
	const currentTheme = useAppSelector(selectCurrentTheme)
	const sUSDWalletBalance = useAppSelector(selectSusdBalance) //metamask balance
	const withdrawModalTab = useAppSelector(selectWithdrawModalTab)
	const [amount, setAmount] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [selectedAsset, setSelectedAsset] = useState('sUSD')
	const [showToolTip, setShowToolTip] = useState(false)
	// const [selectedTab, setSelectedTab] = useState<WithdrawTabs>(WithdrawTabs.WithdrawUSD)
	const assets = [{ name: 'sUSD', symbol: 'SUSD', icon: <img src={sUSD} width={'24px'} /> }]
	const isFetchingPreview = useRef(false)
	const [showPreview, setShowPreview] = useState(false)
	const [togglePreview, setTogglePreview] = useState(false)
	const updatedCollateralValue = useRef<WeiSource | null>(null)
	const updatedWalletBallance = useRef<WeiSource | null>(null)
	const portfolio = useAppSelector(selectFuturesPortfolio) //smart wallet balance

	const handleAssetSelect = (assetName: string) => {
		setSelectedAsset(assetName)
		setIsOpen(false)
	}
	const handleMaxBalance = () => {
		setTogglePreview(!togglePreview)
		isFetchingPreview.current = true
		setAmount(parseFloat(Number(truncateNumbers(sUSDMarginBalance, 5)).toString()).toString())
	}

	const handleWithdraw = async () => {
		if (amount === '' || amount === '0') {
			notifyError('Please input margin withdraw amount')
		} else if (wei(sUSDMarginBalance).eq('0')) {
			notifyError('You have no margin amount')
		} else {
			dispatch(withdrawSmartMargin(wei(amount)))
		}
	}

	const handleChangeTab = (tab: WithdrawTabs) => () => {
		dispatch(setWithdrawModalTab(tab))
	}

	useEffect(() => {
		if (!amount || amount === '0') {
			setShowPreview(false)
			isFetchingPreview.current = false
			return
		}
		isFetchingPreview.current = true

		const inputValue = amount !== '' ? wei(amount) : wei(0)

		const collateralValue = sUSDMarginBalance.sub(inputValue)
		updatedCollateralValue.current = formatDollars(collateralValue)

		const walletValue = portfolio.smartMargin.sub(inputValue)
		updatedWalletBallance.current = formatDollars(walletValue)

		setShowPreview(true)
		isFetchingPreview.current = false
	}, [amount, togglePreview])

	return (
		<Container>
			<Body fontSize={20} color="primary">
				{t('futures.market.manage.withdraw.title')}
			</Body>
			<TabButtons>
				<StyledTabButton
					onClick={handleChangeTab(WithdrawTabs.WithdrawUSD)}
					active={withdrawModalTab === WithdrawTabs.WithdrawUSD}
					currentTheme={currentTheme}
				>
					USD {t('futures.market.manage.withdraw.balance-text')}
				</StyledTabButton>
				<StyledTabButton
					onClick={handleChangeTab(WithdrawTabs.ManageKeeperBalance)}
					active={withdrawModalTab === WithdrawTabs.ManageKeeperBalance}
					currentTheme={currentTheme}
				>
					ETH {t('futures.market.manage.withdraw.balance-text')}
				</StyledTabButton>
			</TabButtons>

			{withdrawModalTab === WithdrawTabs.WithdrawUSD ? (
				<>
					{/* <Body fontSize={20}>{t('futures.market.manage.withdraw.title')}</Body> */}
					{/* <Body fontSize={14} color="secondary">
						{t('futures.market.manage.withdraw.text')}
					</Body> */}
					<DropdownWrapper>
						<BalanceText>{t('futures.market.manage.deposit.asset')}</BalanceText>
						<DropdownContainer isOpen={isOpen}>
							<SelectedOption onClick={() => setIsOpen(!isOpen)}>
								<AssetIconWrapper>
									{assets.find((asset) => asset.name === selectedAsset)?.icon}
								</AssetIconWrapper>
								<AssetText>
									<AssetName>{selectedAsset}</AssetName>
									<AssetSymbol>
										{assets.find((asset) => asset.name === selectedAsset)?.symbol}
									</AssetSymbol>
								</AssetText>
								<DropdownArrowWrap isOpen={isOpen}>
									<DropdownArrow />
								</DropdownArrowWrap>
							</SelectedOption>
							{isOpen && (
								<DropdownOptions>
									{assets.map((asset, index) => (
										<Option key={index} onClick={() => handleAssetSelect(asset.name)}>
											<AssetIconWrapper>{asset.icon}</AssetIconWrapper>
											<AssetText>
												<AssetName>{asset.name}</AssetName>
												<AssetSymbol>{asset.symbol}</AssetSymbol>
											</AssetText>
										</Option>
									))}
								</DropdownOptions>
							)}
						</DropdownContainer>
					</DropdownWrapper>
					<BalanceWrapper>
						<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
						<BalanceContainer>
							<InputContainer
								placeholder="0.00"
								value={amount.toString()}
								onChange={(_, v) => {
									isFetchingPreview.current = true
									setAmount(v !== '' ? v : '')
								}}
								right={
									<StyledMaxButton onClick={handleMaxBalance} amount={amount}>
										{t('futures.market.trade.margin.modal.max')}
									</StyledMaxButton>
								}
							/>
							<EquityRowsWrap>
								<EquityCardRow>
									<EquityCardLabel>
										{t('futures.market.manage.withdraw.collateral')}
									</EquityCardLabel>
									<EquityCardValue>
										{formatDollars(sUSDMarginBalance)}
										{showPreview && (
											<>
												<PreviewArrow>→</PreviewArrow>
												<PreviewValue negative={sUSDMarginBalance.gt(wei(amount || 0))}>
													{updatedCollateralValue.current}
												</PreviewValue>
											</>
										)}
									</EquityCardValue>
								</EquityCardRow>
								<EquityCardRow>
									<EquityCardLabel>{t('futures.market.manage.withdraw.leverage')}</EquityCardLabel>
									<EquityCardValue>50x</EquityCardValue>
								</EquityCardRow>
							</EquityRowsWrap>
						</BalanceContainer>
					</BalanceWrapper>
					{wei(amount === '' ? 0 : amount).gt(sUSDMarginBalance) && (
						<ErrorBox>{t('futures.market.manage.withdraw.limit-exceed')}</ErrorBox>
					)}
					<EquityCardContainer>
						<EquityRowsWrap>
							<EquityCardRow>
								<EquityCardLabel>
									{t('futures.market.manage.withdraw.wallet')}
									<EquityCardCurrencyBadge>
										<span>USD</span>
									</EquityCardCurrencyBadge>
								</EquityCardLabel>
								<EquityCardValue>
									{formatDollars(portfolio.smartMargin)?.replace('$', '')}
									{showPreview && (
										<>
											<PreviewArrow>→</PreviewArrow>
											<PreviewValue negative={portfolio.smartMargin.gt(wei(amount || 0))}>
												{updatedWalletBallance.current?.replace('$', '')}
											</PreviewValue>
										</>
									)}
								</EquityCardValue>
							</EquityCardRow>
						</EquityRowsWrap>
						<StyledButton
							onClick={handleWithdraw}
							disabled={
								withdrawing ||
								isFetchingPreview.current ||
								wei(amount === '' ? 0 : amount).eq(0) ||
								wei(amount === '' ? 0 : amount).gt(sUSDMarginBalance)
							}
							isLoading={withdrawing || isFetchingPreview.current}
							data-submit-button
						>
							{withdrawing || isFetchingPreview.current ? (
								<Loader />
							) : (
								t('futures.market.manage.withdraw.confirm')
							)}
						</StyledButton>
					</EquityCardContainer>
				</>
			) : (
				<ManageKeeperBalance defaultType="withdraw" />
			)}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`

const BalanceWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
	font-feature-settings: 'zero' 0;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
	flex-direction: column;
	> div {
		width: 100%;
	}
	gap: 12px;
	padding-bottom: 8px;
`

const BalanceText = styled.p`
	font-size: 12px;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
`
const InputContainer = styled(NumericInput)`
	// margin-top: -10px;
	height: 40px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	border: 0;
	font-size: 14px;
	input {
		color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
		font-size: 14px;
		::placeholder {
			color: ${(props) => props.theme.colors.selectedTheme.modal.text.gray};
			font-family: ${(props) => props.theme.fonts.regular};
			font-weight: 400;
			font-size: 14px;
		}
	}
`
const DropdownWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`

const DropdownContainer = styled.div<{ isOpen: boolean }>`
	width: 100%;
	position: relative;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
	border-radius: ${({ isOpen }) => (isOpen ? '6px 6px 0px 0px' : '6px')};
`
const AssetSymbol = styled.span`
	font-size: 12px;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primary};
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.gray};
	padding: 2px 6px;
	border-radius: 4px;
`

const SelectedOption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.selectedBackground};
	padding: 10px;
	// border-radius: 8px;
	cursor: pointer;
	border-radius: 6px 6px 6px 6px;
`

const AssetIconWrapper = styled.div`
	width: 24px;
	height: 24px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 20px;
		height: 20px;
		overflow: visible;
	}
`

const AssetText = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const AssetName = styled.span`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
`

const DropdownArrowWrap = styled.div<{ isOpen: boolean }>`
	margin-left: auto;
	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
		transition: transform 0.1s ease;
		path {
			fill: ${({ theme, isOpen }) =>
				isOpen
					? theme.colors.selectedTheme.modal.text.primaryWhite
					: theme.colors.selectedTheme.modal.arrowColor};
		}
	}
`

const DropdownOptions = styled.div`
	position: absolute;
	top: 44px;
	width: 100%;
	border-radius: 0px 0px 6px 6px;
	z-index: 100;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
`

const Option = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
`

const EquityCardContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.dark};
	padding-top: 12px;
	gap: 8px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	font-feature-settings: 'zero' 0;
`

const EquityRowsWrap = styled.div`
	padding: 0px 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
const EquityCardRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
`

const EquityCardLabel = styled.span`
	font-size: 12px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	position: relative;
	display: flex;
	align-items: center;
`

const EquityCardValue = styled.span`
	font-size: 14px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-weight: 400;
	display: flex;
	gap: 2px;
	align-items: center;
`
const PreviewValue = styled.span<{ negative?: boolean }>`
	color: ${(props) =>
		!props.negative
			? props.theme.colors.selectedTheme.red
			: props.theme.colors.selectedTheme.modal.text.primary};
`

const PreviewArrow = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.red};
`

const EquityCardCurrencyBadge = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.gray};
	margin-left: 8px;
	padding: 2px 4px 2px 4px;
	border-radius: 4px;
	height: 20px;
	width: 32px;
	display: flex;
	align-items: center;
	span {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
	}
`

const IconDivStyle = styled.div`
	padding: auto;
	display: flex;
	align-items: center;
	svg {
		overflow: visible;
	}
	cursor: help;
	margin-left: 8px;
`
const OpenMenu = styled.div`
	z-index: 1001;
	position: absolute;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primary};
	top: 20px;
	width: 300px;
	display: grid;
	padding: 16px;
	// border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 8px;
	backdrop-filter: blur(8px);
	font-size: 12px;
`
const ToolTipHeading = styled.div`
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 4px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primaryWhite};
`

const StyledMaxButton = styled.span<{ amount: string }>`
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		!props.amount
			? props.theme.colors.selectedTheme.modal.text.gray
			: props.theme.colors.selectedTheme.modal.text.primaryWhite};
	font-family: ${(props) => props.theme.fonts.regular};
	text-transform: uppercase;
	letter-spacing: 0.04em;
	cursor: pointer;
	font-weight: 400;
`

const ErrorBox = styled.div`
	background-color: #ff53531a;
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	padding: 10px 12px;
	border-left: 4px solid #ff5353;
	border-radius: 8px;
	font-size: 13px;
	line-height: 1.5;
	width: 100%;
	// margin-top: 10px;
`
const StyledButton = styled(Button)<{ isLoading: boolean }>`
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	${(props) =>
		props.disabled
			? css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.disabled} !important;
					border: 0 !important;
					color: #ffffff80 !important;
			  `
			: css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.active} !important;
					border: 0 !important;
					color: #ffffff !important;
			  `};
	${(props) =>
		props.isLoading &&
		css`
			background-color: transparent !important;
			border: ${(props) => props.theme.colors.selectedTheme.border}!important;
		`};
`

const TabButtons = styled.div`
	height: 40px;
	width: 100%;
	display: flex;
	gap: 4px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.tabFill};
	padding: 4px;
	border-radius: 10px;
	${media.lessThan('mdUp')`
		width: 100%;
	`}
`

const StyledTabButton = styled.button<{ active: boolean; currentTheme: string }>`
	width: 100%;
	padding: 6px 12px;
	font-size: 12px;
	background-color: ${(props) =>
		props.active ? props.theme.colors.selectedTheme.modal.manage.buttonFill : 'transparent'};
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.modal.manage.tabText
			: props.theme.colors.selectedTheme.modal.manage.disabledTabText};
	font-family: Relative-Medium-Pro;
	font-weight: 500;
	font-feature-settings: 'zero' 0;
	&:hover {
		// filter: ${(props) =>
			props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)'};
		// border-width: 1px;
	}
	${media.lessThan('mdUp')`
		width: 50%;
	`}
`

export default Withdraw
