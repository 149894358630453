const fontStyles = {
	regular: `Relative-Medium-Pro`,
	bold: `Relative-Bold-Pro`,
	black: `Relative-Black-Pro`,
	book: `Relative-Book-Pro`,
	mono: `Relative-Mono-Pro`,
	relativeMono: `Relative-Mono`,
	monoBold: `Relative-Bold-Pro`,
	compressedBlack: `Relative-Black-Pro`,
	compressedMedium: `Relative-Medium-Pro`,
}

export default fontStyles
