import React, { FC, useCallback, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Connector from 'containers/Connector'
import { useWalkthroughContext } from 'contexts/WalkthroughContext'
import { notifyError } from 'components/ErrorNotifier'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSettings } from 'state/app/selectors'
import { enableSettings, setShowWalkthrough } from 'state/app/reducer'
import Settings from 'sections/shared/Layout/AppLayout/Header/Settings'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { BASE_URL, Step } from '../Walkthrough'

const TradePreference: FC = () => {
	const dispatch = useAppDispatch()
	const { walletAddress } = Connector.useContainer()
	const settings = useAppSelector(selectSettings)
	const { setCurrentStep } = useWalkthroughContext()
	const currentTheme = useAppSelector(selectCurrentTheme)

	const handleComplete = async () => {
		try {
			dispatch(setShowWalkthrough('hide'))
			dispatch(enableSettings(true))
			await axios.patch(
				`${BASE_URL}?walletAddress=${walletAddress?.toLowerCase()}&step=${Step.TradePreference}`
			)
			setCurrentStep(Step.TradePreference)
		} catch (error) {
			notifyError(error.message)
		}
	}

	const handleSkip = async () => {
		try {
			await axios.patch(
				`${BASE_URL}?walletAddress=${walletAddress?.toLowerCase()}&step=${Step.TradePreference}`
			)
			setCurrentStep(Step.TradePreference)
		} catch (error) {
			notifyError(error.message)
		}
	}

	const handleDismiss = useCallback(() => {
		dispatch(enableSettings(!settings))
	}, [dispatch, settings])

	return (
		<>
			<Content>
				<ParaText>Customize your trading experience by choosing light mode or dark mode.</ParaText>
				<ActionDiv>
					<ActionButton onClick={handleComplete}>Customize</ActionButton>
					<SkipButton onClick={handleSkip} currentTheme={currentTheme}>
						Skip
					</SkipButton>
				</ActionDiv>
			</Content>
			{settings && <Settings onDismiss={handleDismiss} />}
		</>
	)
}

export default TradePreference

const Content = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.25rem;
	margin-left: 1.75rem;
	margin-top: -0.5rem;
`

const ParaText = styled.p`
	font-size: 14px;
	line-height: 1.25;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
`

const ActionDiv = styled.div`
	display: flex;
	column-gap: 0.5rem;
`

const ActionButton = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	font-size: 14px;
	border-radius: 0.25rem;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	padding-left: 1rem;
	padding-right: 1rem;
	cursor: pointer;
	&:hover {
		outline-color: ${(props) => props.theme.colors.selectedTheme.walkthrough.outline};
		outline-style: solid;
		outline-width: thin;
	}
`

const SkipButton = styled.div<{ currentTheme: string }>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-size: 14px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	cursor: pointer;
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.5)' : 'brightness(0.9)')};
	}
`
