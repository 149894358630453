import { createSlice } from '@reduxjs/toolkit'

import { OneClickState } from './types'

export const ONECLICK_INITIAL_STATE: OneClickState = {
	delegated: false,
	sessionActivated: false,
	smartAccountAddress: '',
	smartAccountBalance: '0',
	sessionId: '',
	validUntil: '0',
	estimatedTxs: 0,
	expiresIn: 0,
	walletBalance: '0',
	usingBiconomy: false,
}

const oneClickSlice = createSlice({
	name: 'oneClick',
	initialState: ONECLICK_INITIAL_STATE,
	reducers: {
		setDelegated: (state, action) => {
			state.delegated = action.payload
		},
		setSessionActivated: (state, action) => {
			state.sessionActivated = action.payload
		},
		setSmartAccountAddress: (state, action) => {
			state.smartAccountAddress = action.payload
		},
		setSmartAccountBalance: (state, action) => {
			state.smartAccountBalance = action.payload
		},
		setSessionId: (state, action) => {
			state.sessionId = action.payload
		},
		setSessionValidUntil: (state, action) => {
			state.validUntil = action.payload
		},
		setEstimatedTxs: (state, action) => {
			state.estimatedTxs = action.payload
		},
		setExpiresIn: (state, action) => {
			state.expiresIn = action.payload
		},
		setWalletBalance: (state, action) => {
			state.walletBalance = action.payload
		},
		setUsingBiconomy: (state, action) => {
			state.usingBiconomy = action.payload
		},
	},
})

export const {
	setDelegated,
	setSessionActivated,
	setSmartAccountAddress,
	setSmartAccountBalance,
	setSessionId,
	setSessionValidUntil,
	setEstimatedTxs,
	setExpiresIn,
	setWalletBalance,
	setUsingBiconomy,
} = oneClickSlice.actions

export default oneClickSlice.reducer
