/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "contract IAddressResolver",
                name: "_resolverProxy",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        constant: true,
        inputs: [],
        name: "allMarketSummaries",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "market",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "asset",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "key",
                        type: "bytes32",
                    },
                    {
                        internalType: "uint256",
                        name: "maxLeverage",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "price",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "marketSize",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "marketSkew",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "marketDebt",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "currentFundingRate",
                        type: "int256",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "takerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "takerFeeNextPrice",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFeeNextPrice",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FeeRates",
                        name: "feeRates",
                        type: "tuple",
                    },
                ],
                internalType: "struct FuturesMarketData.MarketSummary[]",
                name: "",
                type: "tuple[]",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "globals",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "minInitialMargin",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "liquidationFeeRatio",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "liquidationBufferRatio",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minKeeperFee",
                        type: "uint256",
                    },
                ],
                internalType: "struct FuturesMarketData.FuturesGlobals",
                name: "",
                type: "tuple",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "contract IFuturesMarket",
                name: "market",
                type: "address",
            },
        ],
        name: "marketDetails",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "market",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "baseAsset",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "marketKey",
                        type: "bytes32",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "takerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "takerFeeNextPrice",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFeeNextPrice",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FeeRates",
                        name: "feeRates",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "maxLeverage",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "maxMarketValueUSD",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.MarketLimits",
                        name: "limits",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "maxFundingRate",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "skewScaleUSD",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FundingParameters",
                        name: "fundingParameters",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "marketSize",
                                type: "uint256",
                            },
                            {
                                components: [
                                    {
                                        internalType: "uint256",
                                        name: "long",
                                        type: "uint256",
                                    },
                                    {
                                        internalType: "uint256",
                                        name: "short",
                                        type: "uint256",
                                    },
                                ],
                                internalType: "struct FuturesMarketData.Sides",
                                name: "sides",
                                type: "tuple",
                            },
                            {
                                internalType: "uint256",
                                name: "marketDebt",
                                type: "uint256",
                            },
                            {
                                internalType: "int256",
                                name: "marketSkew",
                                type: "int256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.MarketSizeDetails",
                        name: "marketSizeDetails",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "price",
                                type: "uint256",
                            },
                            {
                                internalType: "bool",
                                name: "invalid",
                                type: "bool",
                            },
                        ],
                        internalType: "struct FuturesMarketData.PriceDetails",
                        name: "priceDetails",
                        type: "tuple",
                    },
                ],
                internalType: "struct FuturesMarketData.MarketData",
                name: "",
                type: "tuple",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "marketKey",
                type: "bytes32",
            },
        ],
        name: "marketDetailsForKey",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "market",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "baseAsset",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "marketKey",
                        type: "bytes32",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "takerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "takerFeeNextPrice",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFeeNextPrice",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FeeRates",
                        name: "feeRates",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "maxLeverage",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "maxMarketValueUSD",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.MarketLimits",
                        name: "limits",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "maxFundingRate",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "skewScaleUSD",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FundingParameters",
                        name: "fundingParameters",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "marketSize",
                                type: "uint256",
                            },
                            {
                                components: [
                                    {
                                        internalType: "uint256",
                                        name: "long",
                                        type: "uint256",
                                    },
                                    {
                                        internalType: "uint256",
                                        name: "short",
                                        type: "uint256",
                                    },
                                ],
                                internalType: "struct FuturesMarketData.Sides",
                                name: "sides",
                                type: "tuple",
                            },
                            {
                                internalType: "uint256",
                                name: "marketDebt",
                                type: "uint256",
                            },
                            {
                                internalType: "int256",
                                name: "marketSkew",
                                type: "int256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.MarketSizeDetails",
                        name: "marketSizeDetails",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "price",
                                type: "uint256",
                            },
                            {
                                internalType: "bool",
                                name: "invalid",
                                type: "bool",
                            },
                        ],
                        internalType: "struct FuturesMarketData.PriceDetails",
                        name: "priceDetails",
                        type: "tuple",
                    },
                ],
                internalType: "struct FuturesMarketData.MarketData",
                name: "",
                type: "tuple",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "address[]",
                name: "markets",
                type: "address[]",
            },
        ],
        name: "marketSummaries",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "market",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "asset",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "key",
                        type: "bytes32",
                    },
                    {
                        internalType: "uint256",
                        name: "maxLeverage",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "price",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "marketSize",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "marketSkew",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "marketDebt",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "currentFundingRate",
                        type: "int256",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "takerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "takerFeeNextPrice",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFeeNextPrice",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FeeRates",
                        name: "feeRates",
                        type: "tuple",
                    },
                ],
                internalType: "struct FuturesMarketData.MarketSummary[]",
                name: "",
                type: "tuple[]",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32[]",
                name: "marketKeys",
                type: "bytes32[]",
            },
        ],
        name: "marketSummariesForKeys",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "market",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "asset",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "key",
                        type: "bytes32",
                    },
                    {
                        internalType: "uint256",
                        name: "maxLeverage",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "price",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "marketSize",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "marketSkew",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "marketDebt",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "currentFundingRate",
                        type: "int256",
                    },
                    {
                        components: [
                            {
                                internalType: "uint256",
                                name: "takerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFee",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "takerFeeNextPrice",
                                type: "uint256",
                            },
                            {
                                internalType: "uint256",
                                name: "makerFeeNextPrice",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct FuturesMarketData.FeeRates",
                        name: "feeRates",
                        type: "tuple",
                    },
                ],
                internalType: "struct FuturesMarketData.MarketSummary[]",
                name: "",
                type: "tuple[]",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "marketKey",
                type: "bytes32",
            },
        ],
        name: "parameters",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "takerFee",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "makerFee",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "takerFeeNextPrice",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "makerFeeNextPrice",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "nextPriceConfirmWindow",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxLeverage",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxMarketValueUSD",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxFundingRate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "skewScaleUSD",
                        type: "uint256",
                    },
                ],
                internalType: "struct IFuturesMarketSettings.Parameters",
                name: "",
                type: "tuple",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "contract IFuturesMarket",
                name: "market",
                type: "address",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "positionDetails",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "uint64",
                                name: "id",
                                type: "uint64",
                            },
                            {
                                internalType: "uint64",
                                name: "lastFundingIndex",
                                type: "uint64",
                            },
                            {
                                internalType: "uint128",
                                name: "margin",
                                type: "uint128",
                            },
                            {
                                internalType: "uint128",
                                name: "lastPrice",
                                type: "uint128",
                            },
                            {
                                internalType: "int128",
                                name: "size",
                                type: "int128",
                            },
                        ],
                        internalType: "struct IFuturesMarketBaseTypes.Position",
                        name: "position",
                        type: "tuple",
                    },
                    {
                        internalType: "int256",
                        name: "notionalValue",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "profitLoss",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "accruedFunding",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "remainingMargin",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "accessibleMargin",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "liquidationPrice",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "canLiquidatePosition",
                        type: "bool",
                    },
                ],
                internalType: "struct FuturesMarketData.PositionData",
                name: "",
                type: "tuple",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            {
                internalType: "bytes32",
                name: "marketKey",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "positionDetailsForMarketKey",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "uint64",
                                name: "id",
                                type: "uint64",
                            },
                            {
                                internalType: "uint64",
                                name: "lastFundingIndex",
                                type: "uint64",
                            },
                            {
                                internalType: "uint128",
                                name: "margin",
                                type: "uint128",
                            },
                            {
                                internalType: "uint128",
                                name: "lastPrice",
                                type: "uint128",
                            },
                            {
                                internalType: "int128",
                                name: "size",
                                type: "int128",
                            },
                        ],
                        internalType: "struct IFuturesMarketBaseTypes.Position",
                        name: "position",
                        type: "tuple",
                    },
                    {
                        internalType: "int256",
                        name: "notionalValue",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "profitLoss",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "accruedFunding",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "remainingMargin",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "accessibleMargin",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "liquidationPrice",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "canLiquidatePosition",
                        type: "bool",
                    },
                ],
                internalType: "struct FuturesMarketData.PositionData",
                name: "",
                type: "tuple",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "resolverProxy",
        outputs: [
            {
                internalType: "contract IAddressResolver",
                name: "",
                type: "address",
            },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];
export class FuturesMarketData__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
FuturesMarketData__factory.abi = _abi;
