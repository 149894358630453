var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wei } from '@synthetixio/wei';
import request, { gql } from 'graphql-request';
import { REQUIRES_L2 } from '../common/errors';
import { FUTURES_ENDPOINT_OP_MAINNET } from '../constants/futures';
import { ADDRESSES_PER_LOOKUP, DEFAULT_LEADERBOARD_DATA, ENS_REVERSE_LOOKUP, } from '../constants/stats';
import { ETH_UNIT } from '../constants/transactions';
import { mapStat } from '../utils/stats';
import { truncateAddress } from '../utils/string';
import { getFuturesStats } from '../utils/subgraph';
import { Contract } from 'ethers';
export default class StatsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    getStatsVolumes() {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    getFuturesTradersStats() {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    getFuturesStats() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield getFuturesStats(this.sdk.futures.futuresGqlEndpoint, {
                    first: 10,
                    orderBy: 'pnlWithFeesPaid',
                    orderDirection: 'desc',
                }, {
                    account: true,
                    pnl: true,
                    pnlWithFeesPaid: true,
                    liquidations: true,
                    totalTrades: true,
                    totalVolume: true,
                });
                const stats = response.map((stat, i) => ({
                    trader: stat.account,
                    traderShort: truncateAddress(stat.account),
                    pnl: stat.pnlWithFeesPaid.div(ETH_UNIT).toString(),
                    totalVolume: stat.totalVolume.div(ETH_UNIT).toString(),
                    totalTrades: stat.totalTrades.toNumber(),
                    liquidations: stat.liquidations.toNumber(),
                    rank: i + 1,
                    rankText: (i + 1).toString(),
                }));
                return stats;
            }
            catch (e) {
                this.sdk.context.logError(e);
                return [];
            }
        });
    }
    getLeaderboard(searchTerm, walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const query = gql `
				fragment StatsBody on FuturesStat {
					account
					pnl
					pnlWithFeesPaid
					liquidations
					totalTrades
					totalVolume
				}

				query leaderboardStats($account: String!, $searchTerm: String!) {
					top: futuresStats(
						where: { smartMarginVolume_gt: "0" }
						orderBy: pnlWithFeesPaid
						orderDirection: desc
						first: 100
					) {
						...StatsBody
					}
					bottom: futuresStats(
						where: { smartMarginVolume_gt: "0" }
						orderBy: pnlWithFeesPaid
						orderDirection: asc
						first: 100
					) {
						...StatsBody
					}
					wallet: futuresStats(where: { account: $account }) {
						...StatsBody
					}
					search: futuresStats(where: { account_contains: $searchTerm }) {
						...StatsBody
					}
				}
			`;
                const response = yield request(this.sdk.futures.futuresGqlEndpoint, query, { account: walletAddress, searchTerm });
                // TODO: Improve the time complexity of this operation.
                // We *should* be able to add the ENS and merge at the same time.
                const ensInfo = yield this.batchGetENS(Object.values(response)
                    .flat(1)
                    .map(({ account }) => account));
                const statTransform = mapStat(ensInfo);
                const stats = {
                    top: response.top.map(statTransform),
                    bottom: response.bottom.map(statTransform),
                    wallet: response.wallet.map(statTransform),
                    search: response.search.map(statTransform),
                };
                return Object.assign(Object.assign({}, stats), { all: [...stats.top, ...stats.bottom, ...stats.wallet, ...stats.search] });
            }
            catch (e) {
                this.sdk.context.logError(e);
                return DEFAULT_LEADERBOARD_DATA;
            }
        });
    }
    getFuturesCumulativeStats(homepage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2 && !homepage) {
                throw new Error(REQUIRES_L2);
            }
            const futuresEndpoint = homepage
                ? FUTURES_ENDPOINT_OP_MAINNET
                : this.sdk.futures.futuresGqlEndpoint;
            try {
                const response = yield request(futuresEndpoint, gql `
					query FuturesCumulativeStats {
						futuresCumulativeStat(id: "0") {
							totalTrades
							totalTraders
							totalVolume
							totalLiquidations
							averageTradeSize
						}
					}
				`);
                return response
                    ? {
                        totalVolume: wei(response.futuresCumulativeStat.totalVolume, 18, true).toString(),
                        averageTradeSize: wei(response.futuresCumulativeStat.averageTradeSize, 18, true).toString(),
                        totalTraders: response.futuresCumulativeStat.totalTraders,
                        totalTrades: response.futuresCumulativeStat.totalTrades,
                        totalLiquidations: response.futuresCumulativeStat.totalLiquidations,
                    }
                    : null;
            }
            catch (e) {
                this.sdk.context.logError(e);
                return null;
            }
        });
    }
    batchGetENS(addresses) {
        return __awaiter(this, void 0, void 0, function* () {
            const ReverseLookup = new Contract(ENS_REVERSE_LOOKUP, ['function getNames(address[] addresses) external view returns (string[] r)'], this.sdk.context.l1MainnetProvider);
            let ensPromises = [];
            for (let i = 0; i < addresses.length; i += ADDRESSES_PER_LOOKUP) {
                const addressesToLookup = addresses.slice(i, i + ADDRESSES_PER_LOOKUP);
                const ensNamesPromise = ReverseLookup.getNames(addressesToLookup);
                ensPromises.push(ensNamesPromise);
            }
            let ensInfo = {};
            const ensPromiseResult = yield Promise.all(ensPromises);
            ensPromiseResult.flat(1).forEach((val, ind) => {
                if (val !== '') {
                    ensInfo[addresses[ind]] = val;
                }
            });
            return ensInfo;
        });
    }
}
