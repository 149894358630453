import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { formatCurrency } from '@dextoroprotocol/sdk/utils'
import { wei, WeiSource } from '@synthetixio/wei'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Button from 'components/Button'
import { notifyError } from 'components/ErrorNotifier'
import ErrorView from 'components/ErrorView'
import NumericInput from 'components/Input/NumericInput'
import Loader from 'components/Loader'
import SegmentedControl from 'components/SegmentedControl'
import Spacer from 'components/Spacer'

import Connector from 'containers/Connector'
import { selectSubmittingFuturesTx } from 'state/futures/selectors'
import { withdrawAccountKeeperBalance } from 'state/futures/smartMargin/actions'
import {
	selectConditionalOrdersForMarket,
	selectSmartMarginBalanceInfo,
} from 'state/futures/smartMargin/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import logError from 'utils/logError'
import { Body } from 'components/Text'
import { FlexDivRowCentered } from 'components/layout/flex'

type TransferType = 'deposit' | 'withdraw'

type Props = { defaultType: TransferType }

const DEPOSIT_ENABLED = false

export default function ManageKeeperBalance({ defaultType }: Props) {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { provider, walletAddress } = Connector.useContainer()

	const { keeperEthBal } = useAppSelector(selectSmartMarginBalanceInfo)
	const openOrders = useAppSelector(selectConditionalOrdersForMarket)
	const isSubmitting = useAppSelector(selectSubmittingFuturesTx)

	const [amount, setAmount] = useState<string>('')
	const [isMax, setMax] = useState(false)
	const [userEthBal, setUserEthBal] = useState(ZERO_WEI)
	const [transferType, setTransferType] = useState(defaultType === 'deposit' ? 0 : 1)
	const isFetchingPreview = useRef(false)
	const [showPreview, setShowPreview] = useState(false)
	const [togglePreview, setTogglePreview] = useState(false)
	const updatedETHValue = useRef<WeiSource | null>(null)

	const getUserEthBal = useCallback(async () => {
		if (!walletAddress) return
		try {
			const bal = await provider.getBalance(walletAddress)
			setUserEthBal(wei(bal))
		} catch (err) {
			notifyError('Failed to read ETH balance', err)
			logError(err)
		}
	}, [walletAddress, provider])

	useEffect(() => {
		getUserEthBal()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletAddress])

	const onWithdrawKeeperDeposit = useCallback(() => {
		if (keeperEthBal.eq(0)) return
		dispatch(withdrawAccountKeeperBalance(wei(amount)))
	}, [dispatch, amount, keeperEthBal])

	const onDepositKeeperDeposit = useCallback(async () => {
		// 	// TODO: Waiting for the function to be added to the smart contract
	}, [])

	const exceedsLimit = useMemo(() => {
		const amtWei = wei(amount || 0)
		return transferType === 0 && amtWei.gt(transferType === 0 ? userEthBal : keeperEthBal)
	}, [transferType, amount, userEthBal, keeperEthBal])

	const isDisabled = useMemo(() => {
		if (!amount || isSubmitting || exceedsLimit || isFetchingPreview.current || wei(amount).eq(0))
			return true
		return false
	}, [amount, isSubmitting, exceedsLimit, isFetchingPreview.current])

	const handleSetMax = React.useCallback(() => {
		setMax(true)
		setAmount(parseFloat(keeperEthBal.toString()).toString())
	}, [keeperEthBal])

	const onChangeTab = (selection: number) => {
		setTransferType(selection)
		setAmount('')
	}

	useEffect(() => {
		if (!amount || amount === '0') {
			setShowPreview(false)
			isFetchingPreview.current = false
			return
		}

		isFetchingPreview.current = true

		const inputValue = amount !== '' ? wei(amount) : wei(0)

		const collateralValue =
			transferType === 0 ? userEthBal.sub(inputValue) : keeperEthBal.sub(inputValue)
		updatedETHValue.current = formatCurrency('ETH', collateralValue)
		setShowPreview(true)
		isFetchingPreview.current = false
	}, [amount, isMax])

	return (
		<Container>
			{/* <Body fontSize={20}>{t('futures.market.trade.orders.manage-keeper-deposit.title')}</Body> */}
			{/* <Spacer height={16} /> */}
			{DEPOSIT_ENABLED && (
				<StyledSegmentedControl
					values={['Deposit', 'Withdraw']}
					selectedIndex={transferType}
					onChange={onChangeTab}
				/>
			)}
			<BalanceWrapper>
				<BalanceText>{t('futures.market.trade.margin.modal.balance')}</BalanceText>
				<BalanceContainer>
					<InputContainer
						dataTestId="futures-market-trade-withdraw-margin-input"
						placeholder="0.00"
						value={amount.toString()}
						invalid={exceedsLimit}
						onChange={(_, v) => {
							if (v !== amount) isFetchingPreview.current = true
							if (isMax) setMax(false)
							setAmount(v)
						}}
						right={
							<StyledMaxButton
								data-testid="futures-market-trade-withdraw-max-button"
								onClick={handleSetMax}
								amount={amount}
							>
								{t('futures.market.trade.margin.modal.max')}
							</StyledMaxButton>
						}
					/>
					<EquityRowsWrap>
						<EquityCardRow>
							<EquityCardLabel>
								{t('futures.market.manage.withdraw.balance-text')}
								<EquityCardCurrencyBadge>
									<span>ETH</span>
								</EquityCardCurrencyBadge>
							</EquityCardLabel>
							<EquityCardValue>
								{formatCurrency('ETH', transferType === 0 ? userEthBal : keeperEthBal)}
								{showPreview && (
									<>
										<PreviewArrow>→</PreviewArrow>
										<PreviewValue
											negative={
												transferType === 0
													? userEthBal.gt(wei(amount || 0))
													: keeperEthBal.gt(wei(amount || 0))
											}
										>
											{updatedETHValue.current}
										</PreviewValue>
									</>
								)}
							</EquityCardValue>
						</EquityCardRow>
					</EquityRowsWrap>
				</BalanceContainer>
			</BalanceWrapper>
			<Spacer height={20} />
			<StyledButton
				data-testid="futures-market-trade-withdraw-margin-button"
				disabled={isDisabled}
				fullWidth
				onClick={transferType === 0 ? onDepositKeeperDeposit : onWithdrawKeeperDeposit}
				isLoading={isSubmitting || isFetchingPreview.current}
				data-submit-button
			>
				{isFetchingPreview.current || isSubmitting ? (
					<Loader />
				) : (
					t(
						`futures.market.trade.orders.manage-keeper-deposit.${
							transferType === 0 ? 'deposit' : 'withdraw'
						}`
					)
				)}
			</StyledButton>
			<br />
			{openOrders.length && transferType === 1 ? (
				<WarningContainer>
					{t('futures.market.trade.orders.manage-keeper-deposit.withdraw-warning')}
				</WarningContainer>
			) : null}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 4px;
	// margin-top: 16px;
`

const BalanceText = styled.p`
	font-size: 12px;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
`

const InputContainer = styled(NumericInput)`
	// margin-top: -10px;
	height: 40px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
	color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
	border: 0;
	font-size: 14px;
	input {
		color: ${({ theme }) => theme.colors.selectedTheme.modal.text.primaryWhite};
		font-size: 14px;
		::placeholder {
			color: ${(props) => props.theme.colors.selectedTheme.modal.text.gray};
			font-family: ${(props) => props.theme.fonts.regular};
			font-weight: 400;
			font-size: 14px;
		}
	}
`

const StyledSegmentedControl = styled(SegmentedControl)`
	margin-bottom: 16px;
`
const StyledMaxButton = styled.span<{ amount: string }>`
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		!props.amount
			? props.theme.colors.selectedTheme.modal.text.gray
			: props.theme.colors.selectedTheme.modal.text.primaryWhite};
	font-family: ${(props) => props.theme.fonts.regular};
	text-transform: uppercase;
	letter-spacing: 0.04em;
	cursor: pointer;
	font-weight: 400;
`
const StyledButton = styled(Button)<{ isLoading: boolean }>`
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	${(props) =>
		props.disabled
			? css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.disabled} !important;
					border: 0 !important;
					color: #ffffff80 !important;
			  `
			: css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.active} !important;
					border: 0 !important;
					color: #ffffff !important;
			  `};
	${(props) =>
		props.isLoading &&
		css`
			background-color: transparent !important;
			border: ${(props) => props.theme.colors.selectedTheme.border}!important;
		`};
`
const BalanceWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
	font-feature-settings: 'zero' 0;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.optionBackground};
	flex-direction: column;
	> div {
		width: 100%;
	}
	gap: 12px;
	padding-bottom: 8px;
`
const EquityRowsWrap = styled.div`
	padding: 0px 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
const EquityCardRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
`

const EquityCardLabel = styled.span`
	font-size: 12px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	position: relative;
	display: flex;
	align-items: center;
`

const EquityCardValue = styled.span`
	font-size: 14px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-weight: 400;
	display: flex;
	gap: 2px;
	align-items: center;
`
const PreviewValue = styled.span<{ negative?: boolean }>`
	color: ${(props) =>
		!props.negative
			? props.theme.colors.selectedTheme.red
			: props.theme.colors.selectedTheme.modal.text.primary};
`
const PreviewArrow = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.red};
`

const EquityCardCurrencyBadge = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.backgroundColor.gray};
	font-size: 10px;
	margin-left: 8px;
	padding: 2px 4px 2px 4px;
	border-radius: 4px;
	height: 20px;
	width: 32px;
	display: flex;
	align-items: center;
	span {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
	}
`
const WarningContainer = styled.div`
	color: ${(props) => props.theme.colors.selectedTheme.modal.manage.tabText};
	flex: none;
	order: 0;
	flex-grow: 0;
	text-align: center;
	font-size: 13px;
	font-feature-settings: 'zero' 0;
	margin: '16px 0 0 0';
	padding: 15px;
	border: 1px solid rgba(239, 104, 104, 0);
	background-color: ${(props) => props.theme.colors.selectedTheme.modal.manage.buttonFill};
	border-radius: 8px;
	cursor: default;
	word-wrap: break-word;

	:first-letter {
		text-transform: uppercase;
	}
`
