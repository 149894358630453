import React, { FC } from 'react'
import styled from 'styled-components'
import Checked from 'assets/svg/app/circle-checked.svg'
import Expand from 'assets/svg/app/expand.svg'

type Props = {}

const ConnectWallet: FC<Props> = () => {
	return (
		<Container>
			<CircleIcon>
				<Checked />
			</CircleIcon>
			<Title>Connect wallet</Title>
			<Space />
			<div>
				<ExpandIcon />
			</div>
		</Container>
	)
}

export default ConnectWallet

const Container = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	white-space: nowrap;
	padding-top: 0.5rem;
	cursor: not-allowed;
	:disabled {
		cursor: default;
	}
`

const CircleIcon = styled.circle`
	display: flex;
	width: 21px;
	height: 21px;
	background-color: rgba(110, 95, 180, 0.2);
	border-radius: 9999px;
	aspect-ratio: 1/1;
	border: ${(props) => props.theme.colors.selectedTheme.walkthrough.outlineBorder};
`

const Title = styled.span`
	font-size: 18px;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	text-decoration-line: line-through;
`

const Space = styled.div`
	flex: 1 1 0%;
`

const ExpandIcon = styled(Expand)`
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`
