export const GA_ANALYTICS_MEASUREMENT_ID =
	process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID || 'G-HF096RC711'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
	window.gtag('config', GA_ANALYTICS_MEASUREMENT_ID, {
		page_path: url,
	})
}

type GTagEvent = {
	action: 'wallet' | 'purchase'
	currency?: string
	value?: number
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, currency, value }: GTagEvent): void => {
	if (action === 'wallet') {
		window.gtag('event', action)
	} else if (action === 'purchase') {
		window.gtag('event', action, {
			currency,
			value,
		})
	}
}
