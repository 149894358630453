import React from 'react'
import styled from 'styled-components'
import { PositionSide } from '@dextoroprotocol/sdk/types'
import { getDisplayAsset } from '@dextoroprotocol/sdk/utils'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import { showNotification } from 'state/app/reducer'
import { clearNotifications, fetchNotifications } from 'state/notifications/actions'
import { clearStateOnDisconnect } from 'state/notifications/reducer'
import {
	selectAllNotifications,
	selectNotificationError,
	selectNotificationStatus,
} from 'state/notifications/selectors'
import useClickOutside from 'hooks/useClickOutside'
import Connector from 'containers/Connector'

import PositionType from 'sections/futures/PositionType'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import { FlexDivCentered, FlexDivRowCentered } from 'components/layout/flex'
import { MobilePageTitle } from 'styles/common'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { zIndex } from 'constants/ui'
import media from 'styles/media'
import {
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'
import SuccessIcon from 'assets/svg/app/success-new.svg'
import CloseIcon from 'assets/svg/app/close.svg'

const Notifications: React.FC = () => {
	const dispatch = useAppDispatch()
	const { isWalletConnected } = Connector.useContainer()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const notifications = useAppSelector(selectAllNotifications)
	const status = useAppSelector(selectNotificationStatus)
	const error = useAppSelector(selectNotificationError)

	const { ref } = useClickOutside(() => dispatch(showNotification(false)))
	const handleClearAll = (userId: string) => dispatch(clearNotifications({ userId }))
	React.useEffect(() => {
		if (isWalletConnected && smartMarginAccount) {
			dispatch(fetchNotifications(smartMarginAccount ?? ''))
		} else {
			dispatch(clearStateOnDisconnect())
		}
	}, [dispatch, isWalletConnected, smartMarginAccount])

	// if (status === 'failed') {
	// 	return <div>Error: {error}</div>
	// }

	// @ts-ignore
	const notificationData = notifications?.data

	return (
		<>
			<DesktopOnlyView>
				<Container ref={ref}>
					<FullHeightContainer>
						<TitleContainer>
							<FlexDivRowCentered>Notifications</FlexDivRowCentered>
							{notificationData?.length > 0 && (
								<ClearButton onClick={() => handleClearAll(smartMarginAccount ?? '')}>
									Clear
								</ClearButton>
							)}
						</TitleContainer>
						<NotifyContainer>
							{status === 'loading' ? (
								<Loader style={{ top: '20%' }} />
							) : notificationData?.length === 0 ||
							  notificationData == undefined ||
							  notificationData == null ||
							  notificationData == '' ? (
								<ContentContainer>You have no notifications.</ContentContainer>
							) : (
								notificationData?.map((n: NotificationItem) => <RenderNotification item={n} />)
							)}
						</NotifyContainer>
					</FullHeightContainer>
				</Container>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<MobileContainer>
					{status === 'loading' ? (
						<Loader style={{ top: '20%' }} />
					) : notificationData?.length === 0 ||
					  notificationData == undefined ||
					  notificationData == null ||
					  notificationData == '' ? (
						<MobileNotifyContainer>
							<MobilePageTitle>You have no notifications.</MobilePageTitle>
						</MobileNotifyContainer>
					) : (
						notificationData?.map((n: NotificationItem) => <RenderNotification item={n} />)
					)}
				</MobileContainer>
			</MobileOrTabletView>
		</>
	)
}

export default Notifications

// Notification Types
interface BaseItem {
	_id: string
	type: string
	message: string
	details?: string
	userId: string
	blockExplorerLink?: string
}

interface MarketOrderItem extends BaseItem {
	type: 'market' | 'limit' | 'stop_market'
	marketKey: string
	marketAsset: string
	positionType: string
	size: string
	price: string
}

interface TransfersItem extends BaseItem {
	type: 'deposit success' | 'withdraw success'
}

type NotificationItem = MarketOrderItem | TransfersItem

// Render the correct component based on the type of notification
const RenderNotification: React.FC<{ item: NotificationItem }> = ({ item }) => {
	const dispatch = useAppDispatch()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)

	const handleClear = async (userId: string, id?: string) => {
		await dispatch(clearNotifications({ userId, id }))
		await dispatch(fetchNotifications(smartMarginAccount ?? ''))
	}

	let content
	if (item.type === 'market' || item.type === 'limit' || item.type === 'stop_market') {
		content = <MarketOrder key={item._id} data={item} />
	} else if (item.type === 'deposit success' || item.type === 'withdraw success') {
		content = <Transfers key={item._id} data={item} />
	} else {
		return null
	}

	return (
		<MessageContainer
			onClick={() => item?.blockExplorerLink && window.open(item?.blockExplorerLink, '_blank')}
		>
			{content}
			<CloseIconDiv
				onClick={(e) => {
					e.stopPropagation()
					handleClear(smartMarginAccount ?? '', item?._id)
				}}
			>
				<CloseIcon />
			</CloseIconDiv>
		</MessageContainer>
	)
}

const Transfers: React.FC<{ data: TransfersItem }> = ({ data }) => (
	<NotificationContent key={data._id}>
		<NotificationTitle>
			<NotificationIconDiv type="success">
				<SuccessIcon />
			</NotificationIconDiv>
			{data?.message}
		</NotificationTitle>
		{data?.details && <NotificationDetails text={data?.details} />}
	</NotificationContent>
)

const MarketOrder: React.FC<{ data: MarketOrderItem }> = ({ data }) => (
	<NotificationContent key={data?._id} style={{ gap: 4 }}>
		<MessageTitle style={{ textTransform: 'capitalize' }}>
			<FlexDivCentered>
				<StyledCurrencyIcon currencyKey={data?.marketKey} width={24} height={24} />
				{data?.message}
			</FlexDivCentered>
			<OrderTypeBox>
				Filled
				{data?.positionType === PositionSide.SHORT ? <Icon_SHORT /> : <Icon_LONG />}
			</OrderTypeBox>
		</MessageTitle>
		<PositionDetail>
			<Key>
				Size
				<PositionType
					side={data?.positionType === PositionSide.LONG ? PositionSide.LONG : PositionSide.SHORT}
				/>
			</Key>
			<Value>
				{data?.size}
				<Ticker>{getDisplayAsset(data?.marketAsset)}</Ticker>
			</Value>
		</PositionDetail>
		<PositionDetail>
			<Key>Price</Key>
			<Value>{data?.price}</Value>
		</PositionDetail>
	</NotificationContent>
)

const Container = styled.div`
	top: 0;
	right: 0;
	height: 100%;
	z-index: 9999;
	min-width: 300px;
	max-width: 300px;
	position: absolute;
	border-left: ${(props) => props.theme.colors.selectedTheme.border};
	background: ${(props) => props.theme.colors.selectedTheme.background};
`

const FullHeightContainer = styled.div`
	height: 100%;
`

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px 28px 20px;
	font-size: 20px;
	line-height: 24px;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.bold};
	color: ${(props) => props.theme.colors.selectedTheme.primary};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
`

const NotifyContainer = styled.div`
	overflow: scroll;
	padding-bottom: 16px;
	height: calc(100% - 64px);
`

const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 28px 12px;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`

const ClearButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	width: auto;
	height: 28px;
	min-height: 28px;
	padding: 0px 10px;
	border-radius: 24px;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

const MobileContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 68px);
	overflow-y: auto;
	z-index: ${zIndex.DIALOG_OVERLAY};
	background: ${(props) => props.theme.colors.selectedTheme.background};
`

const MobileNotifyContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 28px 12px;
	line-height: 22px;
	font-size: 18px;
	height: 100%;
`

export const MessageContainer = styled.div`
	gap: 8px;
	display: grid;
	cursor: pointer;
	position: relative;
	padding: 16px 20px;
	overflow-y: scroll;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	${media.lessThan('mdUp')`
	  padding: 20px 24px;
  	`}
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.table.hover};
	}
`

const StyledCurrencyIcon = styled(CurrencyIcon)`
	margin-right: 4px;
	margin-left: -2px;
`

export const MessageTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`

const OrderTypeBox = styled.div`
	${MessageContainer}:hover & {
		display: none;
	}
	display: flex;
	align-items: center;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

export const PositionDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 13px;
	line-height: 16px;
`

const Key = styled.span`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	gap: 6px;
`

const Value = styled.span`
	display: flex;
	align-items: center;
	text-transform: capitalize;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	gap: 6px;
`

export const Ticker = styled.div`
	display: inline-flex;
	padding: 0 3px 0 4px;
	border-radius: 2px;
	margin-left: 6px;
	margin: -2px auto;
	font-size: 12px;
	line-height: 16px;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	transition: all 0.15s ease-in-out !important;
	text-transform: uppercase;
	letter-spacing: 0.06em;
`

const CloseIconDiv = styled.div`
	${MessageContainer}:hover & {
		display: flex;
	}
	display: none;
	position: absolute;
	cursor: pointer;
	padding: 4px;
	right: 16px;
	top: 12px;

	svg {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
		}
		${media.lessThan('mdUp')`
			width: 16px;
			height: 16px;
		`}
	}
`

export const Icon_LONG = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ marginLeft: 6 }}
	>
		<circle cx="8" cy="8" r="7.5" stroke="#57ffc3"></circle>
		<circle cx="8" cy="8" r="5" fill="#57ffc3"></circle>
	</svg>
)

export const Icon_SHORT = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ marginLeft: 6 }}
	>
		<circle cx="8" cy="8" r="7.5" stroke="#ff5353"></circle>
		<circle cx="8" cy="8" r="5" fill="#ff5353"></circle>
	</svg>
)
