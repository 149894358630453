import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { truncateString } from '@dextoroprotocol/sdk/utils'
import {
	Container,
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'
import ICON from 'assets/svg/app/warning-icon.svg'
import styled, { css } from 'styled-components'

export function RestrcitedLocationWarningToastContent({
	message,
	details,
	linkText,
}: {
	message: string
	details: string
	linkText: string
}) {
	return (
		<Container>
			<NotificationContent>
				<NotificationTitle>
					<StyledNotificationIconDiv>
						<ICON />
					</StyledNotificationIconDiv>
					{message}
				</NotificationTitle>
				<NotificationDetails text={details} linkText={linkText} path={'/terms'} />
			</NotificationContent>
		</Container>
	)
}

const StyledNotificationIconDiv = styled(NotificationIconDiv)`
	svg {
		height: 23px;
		width: 20px;
	}
`

export const RestrictedLocationsWarning = (message: string, details: string, linkText: string) => {
	toast.info(
		<RestrcitedLocationWarningToastContent
			message={message}
			details={details}
			linkText={linkText}
		/>,
		{
			position: toast.POSITION.TOP_RIGHT,
			toastId: message,
			containerId: 'notifications',
		}
	)
}
