var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request, { gql } from 'graphql-request';
export const queryWalletTrades = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.exchange.mainGqlEndpoint, gql `
			query WalletTrades($walletAddress: String!) {
				synthExchanges(
					where: { account: $walletAddress }
					first: 1000
					orderBy: "timestamp"
					orderDirection: "desc"
				) {
					id
					fromAmount
					fromAmountInUSD
					fromSynth {
						name
						symbol
						id
					}
					toSynth {
						name
						symbol
						id
					}
					toAmount
					toAmountInUSD
					feesInUSD
					toAddress
					timestamp
					gasPrice
				}
			}
		`, { walletAddress: walletAddress.toLowerCase() });
    return response === null || response === void 0 ? void 0 : response.synthExchanges;
});
