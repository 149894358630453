var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as sdkErrors from '../common/errors';
import { VESTING_ENTRY_PAGE_SIZE } from '../constants';
export default class stakingMigrationService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    registerEntries(ids) {
        const { EscrowMigrator } = this.sdk.context.contracts;
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(EscrowMigrator, 'registerEntries', [ids]);
    }
    approveDextoroTokenEscrowMigrator(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const { DextoroToken, EscrowMigrator } = this.sdk.context.contracts;
            if (!DextoroToken || !EscrowMigrator) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const amount = yield EscrowMigrator.toPay(user);
            return this.sdk.transactions.createContractTxn(DextoroToken, 'approve', [
                EscrowMigrator.address,
                amount,
            ]);
        });
    }
    migrateEntries(entryIDs) {
        const { EscrowMigrator } = this.sdk.context.contracts;
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return this.sdk.transactions.createContractTxn(EscrowMigrator, 'migrateEntries', [
            walletAddress,
            entryIDs,
        ]);
    }
    getRegisteredVestingEntryIDs() {
        const { EscrowMigrator } = this.sdk.context.contracts;
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return EscrowMigrator.getRegisteredVestingEntryIDs(walletAddress, 0, VESTING_ENTRY_PAGE_SIZE);
    }
    getRegisteredVestingSchedules() {
        const { EscrowMigrator } = this.sdk.context.contracts;
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return EscrowMigrator.getRegisteredVestingSchedules(walletAddress, 0, VESTING_ENTRY_PAGE_SIZE);
    }
    getVestingEntryIDs() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrow } = this.sdk.context.contracts;
            if (!RewardEscrow) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const schedules = yield RewardEscrow.getVestingSchedules(walletAddress, 0, VESTING_ENTRY_PAGE_SIZE);
            return schedules
                .filter((schedule) => schedule.escrowAmount.gt(0))
                .map((schedule) => Number(schedule.entryID));
        });
    }
    getEscrowMigratorAllowance() {
        const { DextoroToken, EscrowMigrator } = this.sdk.context.contracts;
        if (!DextoroToken || !EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return DextoroToken.allowance(walletAddress, EscrowMigrator.address);
    }
    getToPayByUser() {
        const { EscrowMigrator } = this.sdk.context.contracts;
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return EscrowMigrator.toPay(walletAddress);
    }
    getMigrationDeadline() {
        return __awaiter(this, void 0, void 0, function* () {
            const { EscrowMigrator } = this.sdk.context.multicallContracts;
            if (!EscrowMigrator) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const [startBN, duration] = yield this.sdk.context.multicallProvider.all([
                EscrowMigrator.initializationTime(walletAddress),
                EscrowMigrator.MIGRATION_DEADLINE(),
            ]);
            return {
                startBN,
                endBN: startBN.add(duration),
            };
        });
    }
    getTotalEscrowUnmigrated() {
        const { EscrowMigrator } = this.sdk.context.contracts;
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return EscrowMigrator.totalEscrowUnmigrated(walletAddress);
    }
}
