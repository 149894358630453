export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '1152930912574790'

type FbqEvent = {
	action: 'lead' | 'purchase'
	currency?: string
	value?: number
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, currency, value }: FbqEvent): void => {
	if (action === 'lead') {
		;(window as any).fbq('track', action)
	} else if (action === 'purchase') {
		;(window as any).fbq('track', action, {
			currency,
			value,
		})
	}
}
