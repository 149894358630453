import { createAsyncThunk } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'

import { notifyError } from 'components/ErrorNotifier'
import { monitorTransaction } from 'contexts/RelayerContext'
import {
	selectStakingSupportedNetwork,
	selectTradingRewardsSupportedNetwork,
} from 'state/staking/selectors'
import { FetchStatus, ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import {
	ZERO_CLAIMABLE_REWARDS,
	ZERO_ESCROW_BALANCE,
	ZERO_ESTIMATED_REWARDS,
	ZERO_STAKING_DATA,
	ZERO_STAKING_V2_DATA,
} from './reducer'
import {
	ClaimableRewards,
	EscrowBalance,
	EstimatedRewards,
	StakingAction,
	StakingActionV2,
} from './types'

export const fetchStakingData = createAsyncThunk<StakingAction, void, ThunkConfig>(
	'staking/fetchStakingData',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectStakingSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_STAKING_DATA

			const {
				rewardEscrowBalance,
				stakedNonEscrowedBalance,
				stakedEscrowedBalance,
				claimableBalance,
				dextoroBalance,
				weekCounter,
				totalStakedBalance,
				dextoroAllowance,
				epochPeriod,
			} = await sdk.dextoroToken.getStakingData()

			return {
				escrowedDextoroBalance: rewardEscrowBalance.toString(),
				stakedDextoroBalance: stakedNonEscrowedBalance.toString(),
				stakedEscrowedDextoroBalance: stakedEscrowedBalance.toString(),
				claimableBalance: claimableBalance.toString(),
				dextoroBalance: dextoroBalance.toString(),
				weekCounter,
				totalStakedBalance: totalStakedBalance.toString(),
				dextoroAllowance: dextoroAllowance.toString(),
				epochPeriod,
			}
		} catch (err) {
			logError(err)
			notifyError('Failed to fetch staking data', err)
			throw err
		}
	}
)

export const fetchStakingV2Data = createAsyncThunk<StakingActionV2, void, ThunkConfig>(
	'staking/fetchStakingDataV2',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectStakingSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_STAKING_V2_DATA

			const {
				rewardEscrowBalance,
				stakedNonEscrowedBalance,
				stakedEscrowedBalance,
				claimableBalance,
				totalStakedBalance,
				stakedResetTime,
				dextoroStakingV2Allowance,
			} = await sdk.dextoroToken.getStakingV2Data()

			return {
				escrowedDextoroBalance: rewardEscrowBalance.toString(),
				stakedDextoroBalance: stakedNonEscrowedBalance.toString(),
				stakedEscrowedDextoroBalance: stakedEscrowedBalance.toString(),
				claimableBalance: claimableBalance.toString(),
				totalStakedBalance: totalStakedBalance.toString(),
				stakedResetTime,
				dextoroStakingV2Allowance: dextoroStakingV2Allowance.toString(),
			}
		} catch (err) {
			logError(err)
			notifyError('Failed to fetch staking V2 data', err)
			throw err
		}
	}
)

export const approveDextoroToken = createAsyncThunk<
	void,
	'dextoro' | 'vDextoro' | 'veDextoro' | 'dextoroStakingV2',
	ThunkConfig
>('staking/approveDextoroToken', async (token, { dispatch, extra: { sdk } }) => {
	const { hash } = await sdk.dextoroToken.approveDextoroToken(token)

	monitorTransaction({
		txHash: hash,
		onTxConfirmed: () => {
			dispatch({ type: 'staking/setApproveDextoroStatus', payload: FetchStatus.Success })
			dispatch(fetchStakeMigrateData())
		},
		onTxFailed: () => {
			dispatch({ type: 'staking/setApproveDextoroStatus', payload: FetchStatus.Error })
		},
	})
})

export const redeemToken = createAsyncThunk<void, 'vDextoro' | 'veDextoro', ThunkConfig>(
	'staking/redeemToken',
	async (token, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.redeemToken(
			token === 'vDextoro' ? 'vDextoroRedeemer' : 'veDextoroRedeemer',
			{ hasAddress: token === 'veDextoro' }
		)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch(fetchStakeMigrateData())
			},
		})
	}
)

export const fetchEscrowData = createAsyncThunk<EscrowBalance, void, ThunkConfig>(
	'staking/fetchEscrowData',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectStakingSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_ESCROW_BALANCE

			const { escrowData, totalVestable } = await sdk.dextoroToken.getEscrowData()

			return {
				escrowData: escrowData.map((e) => ({
					...e,
					vestable: e.vestable.toString(),
					amount: e.amount.toString(),
					fee: e.fee.toString(),
				})),
				totalVestable: totalVestable.toString(),
			}
		} catch (err) {
			logError(err)
			notifyError('Failed to fetch escrow data', err)
			throw err
		}
	}
)

export const fetchEscrowV2Data = createAsyncThunk<EscrowBalance, void, ThunkConfig>(
	'staking/fetchEscrowV2Data',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectStakingSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_ESCROW_BALANCE

			const { escrowData, totalVestable } = await sdk.dextoroToken.getEscrowV2Data()

			return {
				escrowData: escrowData.map((e) => ({
					...e,
					vestable: e.vestable.toString(),
					amount: e.amount.toString(),
					fee: e.fee.toString(),
				})),
				totalVestable: totalVestable.toString(),
			}
		} catch (err) {
			logError(err)
			notifyError('Failed to fetch escrow V2 data', err)
			throw err
		}
	}
)

export const fetchEstimatedRewards = createAsyncThunk<EstimatedRewards, void, ThunkConfig>(
	'staking/fetchEstimatedRewards',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectStakingSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_ESTIMATED_REWARDS

			const { estimatedDextoroRewards, estimatedOpRewards } =
				await sdk.dextoroToken.getEstimatedRewards()
			return {
				estimatedDextoroRewards: estimatedDextoroRewards.toString(),
				estimatedOpRewards: estimatedOpRewards.toString(),
			}
		} catch (err) {
			logError(err)
			//notifyError('Failed to fetch estimated rewards', err)
			throw err
		}
	}
)

export const fetchStakeMigrateData = createAsyncThunk<void, void, ThunkConfig>(
	'staking/fetchMigrateData',
	async (_, { dispatch }) => {
		dispatch(fetchStakingData())
		dispatch(fetchStakingV2Data())
		dispatch(fetchEscrowData())
		dispatch(fetchEscrowV2Data())
		dispatch(fetchEstimatedRewards())
		dispatch(fetchClaimableRewards())
	}
)

export const vestEscrowedRewards = createAsyncThunk<void, number[], ThunkConfig>(
	'staking/vestEscrowedRewards',
	async (ids, { dispatch, extra: { sdk } }) => {
		if (ids.length > 0) {
			const { hash } = await sdk.dextoroToken.vestToken(ids)

			monitorTransaction({
				txHash: hash,
				onTxConfirmed: () => {
					dispatch({ type: 'staking/setVestEscrowedRewardsStatus', payload: FetchStatus.Success })
					dispatch(fetchStakeMigrateData())
				},
				onTxFailed: () => {
					dispatch({ type: 'staking/setVestEscrowedRewardsStatus', payload: FetchStatus.Error })
				},
			})
		}
	}
)

export const vestEscrowedRewardsV2 = createAsyncThunk<void, number[], ThunkConfig>(
	'staking/vestEscrowedRewardsV2',
	async (ids, { dispatch, extra: { sdk } }) => {
		if (ids.length > 0) {
			const { hash } = await sdk.dextoroToken.vestTokenV2(ids)

			monitorTransaction({
				txHash: hash,
				onTxConfirmed: () => {
					dispatch({ type: 'staking/setVestEscrowedRewardsStatus', payload: FetchStatus.Success })
					dispatch(fetchStakeMigrateData())
				},
				onTxFailed: () => {
					dispatch({ type: 'staking/setVestEscrowedRewardsStatus', payload: FetchStatus.Error })
				},
			})
		}
	}
)

export const claimStakingRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimStakingRewards',
	async (_, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.claimStakingRewards()

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setGetRewardStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setGetRewardStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const claimStakingRewardsV2 = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimStakingRewardsV2',
	async (_, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.claimStakingRewardsV2()

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setGetRewardStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setGetRewardStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const compoundRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/compoundRewards',
	async (_, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.compoundRewards()

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setCompoundRewardsStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setCompoundRewardsStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const fetchClaimableRewards = createAsyncThunk<ClaimableRewards, void, ThunkConfig>(
	'staking/fetchClaimableRewards',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const supportedNetwork = selectTradingRewardsSupportedNetwork(getState())
			if (!wallet || !supportedNetwork) return ZERO_CLAIMABLE_REWARDS

			const { epochPeriod } = await sdk.dextoroToken.getStakingData()

			// const { claimableRewards: claimableDextoroRewards, totalRewards: dextoroRewards } =
			// 	await sdk.dextoroToken.getClaimableAllRewards(epochPeriod, false, false, false, 20)

			const {
				claimableRewards: claimableDextoroRewardsV2,
				totalRewards: dextoroRewardsV2,
				periodsRewards,
			} = await sdk.dextoroToken.getClaimableAllRewards(epochPeriod, true, false, false)

			// const { claimableRewards: claimableOpRewards, totalRewards: opRewards } =
			// 	await sdk.dextoroToken.getClaimableAllRewards(epochPeriod, false, true, false, 11)

			// const { claimableRewards: claimableSnxOpRewards, totalRewards: snxOpRewards } =
			// 	await sdk.dextoroToken.getClaimableAllRewards(epochPeriod, false, true, true, 11)

			return {
				// claimableDextoroRewards,
				claimableDextoroRewardsV2,
				// claimableOpRewards,
				// claimableSnxOpRewards,
				dextoroRewards: dextoroRewardsV2.toString(),
				// opRewards: opRewards.toString(),
				// snxOpRewards: snxOpRewards.toString(),
				periodsRewards: periodsRewards,
			}
		} catch (err) {
			logError(err)
			notifyError('Failed to fetch claimable rewards', err)
			throw err
		}
	}
)

export const claimMultipleAllRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleAllRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: {
				// claimableDextoroRewards,
				claimableDextoroRewardsV2,
				// claimableOpRewards,
				// claimableSnxOpRewards,
			},
		} = getState()

		const { hash } = await sdk.dextoroToken.claimMultipleAllRewards([
			// claimableDextoroRewards,
			claimableDextoroRewardsV2,
			// claimableOpRewards,
			// claimableSnxOpRewards,
		])

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setClaimAllRewardsStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setClaimAllRewardsStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const claimMultipleDextoroRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleDextoroRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: { claimableDextoroRewardsV2 },
		} = getState()

		const { hash } = await sdk.dextoroToken.claimDextoroRewards(claimableDextoroRewardsV2)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setClaimDextoroRewardsStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setClaimDextoroRewardsStatus', payload: FetchStatus.Error })
			},
		})
	}
)

// export const claimMultipleOpRewards = createAsyncThunk<void, void, ThunkConfig>(
// 	'staking/claimMultipleOpRewards',
// 	async (_, { dispatch, getState, extra: { sdk } }) => {
// 		const {
// 			staking: { claimableOpRewards },
// 		} = getState()

// 		const { hash } = await sdk.dextoroToken.claimOpRewards(claimableOpRewards, false)

// 		monitorTransaction({
// 			txHash: hash,
// 			onTxConfirmed: () => {
// 				dispatch({ type: 'staking/setClaimOpRewardsStatus', payload: FetchStatus.Success })
// 				dispatch(fetchStakeMigrateData())
// 			},
// 			onTxFailed: () => {
// 				dispatch({ type: 'staking/setClaimOpRewardsStatus', payload: FetchStatus.Error })
// 			},
// 		})
// 	}
// )

// export const claimMultipleSnxOpRewards = createAsyncThunk<void, void, ThunkConfig>(
// 	'staking/claimMultipleSnxOpRewards',
// 	async (_, { dispatch, getState, extra: { sdk } }) => {
// 		const {
// 			staking: { claimableSnxOpRewards },
// 		} = getState()

// 		const { hash } = await sdk.dextoroToken.claimOpRewards(claimableSnxOpRewards, true)

// 		monitorTransaction({
// 			txHash: hash,
// 			onTxConfirmed: () => {
// 				dispatch({ type: 'staking/setClaimSnxOpRewardsStatus', payload: FetchStatus.Success })
// 				dispatch(fetchStakeMigrateData())
// 			},
// 			onTxFailed: () => {
// 				dispatch({ type: 'staking/setClaimSnxOpRewardsStatus', payload: FetchStatus.Error })
// 			},
// 		})
// 	}
// )

export const stakeEscrow = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/stakeEscrow',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.stakeEscrowedDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setStakeEscrowedStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setStakeEscrowedStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const unstakeEscrow = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/unstakeEscrow',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.unstakeEscrowedDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setUnstakeEscrowedStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setUnstakeEscrowedStatus', payload: FetchStatus.Error })
			},
		})
	}
)

// TODO: Consider merging this with the (stake|unstake)Escrow actions.

export const stakeDextoro = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/stakeDextoro',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.stakeDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setStakeStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setStakeStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const unstakeDextoro = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/unstakeDextoro',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.unstakeDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setUnstakeStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setUnstakeStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const stakeEscrowV2 = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/stakeEscrowV2',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.stakeEscrowedDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setStakeEscrowedStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setStakeEscrowedStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const unstakeEscrowV2 = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/unstakeEscrowV2',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.unstakeEscrowedDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setUnstakeEscrowedStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setUnstakeEscrowedStatus', payload: FetchStatus.Error })
			},
		})
	}
)

// TODO: Consider merging this with the (stake|unstake)Escrow actions.

export const stakeDextoroV2 = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/stakeDextoroV2',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.stakeDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setStakeStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setStakeStatus', payload: FetchStatus.Error })
			},
		})
	}
)

export const unstakeDextoroV2 = createAsyncThunk<void, BigNumber, ThunkConfig>(
	'staking/unstakeDextoroV2',
	async (amount, { dispatch, extra: { sdk } }) => {
		const { hash } = await sdk.dextoroToken.unstakeDextoroV2(amount)

		monitorTransaction({
			txHash: hash,
			onTxConfirmed: () => {
				dispatch({ type: 'staking/setUnstakeStatus', payload: FetchStatus.Success })
				dispatch(fetchStakeMigrateData())
			},
			onTxFailed: () => {
				dispatch({ type: 'staking/setUnstakeStatus', payload: FetchStatus.Error })
			},
		})
	}
)
