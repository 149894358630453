import React, { FC } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Connector from 'containers/Connector'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import { useWalkthroughContext } from 'contexts/WalkthroughContext'
import { notifyError } from 'components/ErrorNotifier'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { BASE_URL, Step } from '../Walkthrough'

const OneClickTrading: FC = () => {
	const dispatch = useAppDispatch()
	const { walletAddress } = Connector.useContainer()
	const { setCurrentStep } = useWalkthroughContext()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const currentTheme = useAppSelector(selectCurrentTheme)
	const handleComplete = async () => {
		try {
			if (smartMarginAccount !== undefined) {
				await axios.patch(
					`${BASE_URL}?walletAddress=${walletAddress?.toLowerCase()}&step=${Step.OneClickTrading}`
				)
				dispatch(setOpenModal('futures_smart_margin_manage'))
				dispatch(setDefaultTab('oneclick-trading'))
				setCurrentStep(Step.OneClickTrading)
			} else {
				notifyError('Please create smart wallet!')
			}
		} catch (error) {
			notifyError(error.message)
		}
	}

	const handleSkip = async () => {
		try {
			await axios.patch(
				`${BASE_URL}?walletAddress=${walletAddress?.toLowerCase()}&step=${Step.OneClickTrading}`
			)
			setCurrentStep(Step.OneClickTrading)
		} catch (error) {
			notifyError(error.message)
		}
	}

	return (
		<>
			<Content>
				<ParaText>
					Trade fast and seamlessly with One-Click trading mode. Sign one approval transaction at
					the start of each trading session.
				</ParaText>
				<ActionDiv>
					<ActionButton onClick={handleComplete}>Enable 1CT</ActionButton>
					<SkipButton onClick={handleSkip} currentTheme={currentTheme}>
						Skip
					</SkipButton>
				</ActionDiv>
			</Content>
		</>
	)
}

export default OneClickTrading

const Content = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.25rem;
	margin-left: 1.75rem;
	margin-top: -0.5rem;
`

const ParaText = styled.p`
	font-size: 14px;
	line-height: 1.25;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
`

const ActionDiv = styled.div`
	display: flex;
	column-gap: 0.5rem;
`

const ActionButton = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	font-size: 14px;
	border-radius: 0.25rem;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	padding-left: 1rem;
	padding-right: 1rem;
	cursor: pointer;
	&:hover {
		outline-color: ${(props) => props.theme.colors.selectedTheme.walkthrough.outline};
		outline-style: solid;
		outline-width: thin;
	}
`

const SkipButton = styled.div<{ currentTheme: string }>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-size: 14px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	cursor: pointer;
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.5)' : 'brightness(0.9)')};
	}
`
