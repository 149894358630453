import sdk from '../sdk'

export const addDelegate = async (smartMarginAddress: string, smartAccountAddress: string) => {
	if (!smartMarginAddress) return
	const { hash } = await sdk.futures.addDelegate(smartMarginAddress, smartAccountAddress)
	return hash
}

export const removeDelegate = async (smartMarginAddress: string, smartAccountAddress: string) => {
	if (!smartMarginAddress) return
	const { hash } = await sdk.futures.removeDelegate(smartMarginAddress, smartAccountAddress)
	return hash
}

export const activeSession = async (smartMarginAddress: string, duration: number) => {
	if (!smartMarginAddress) return
	const hash = await sdk.biconomy.activeSession(smartMarginAddress, duration)
	return hash
}

export const withdraw = async (to: string, value: string) => {
	const result = await sdk.biconomy.withdraw(to, value)
	return result?.hash
}
