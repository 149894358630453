export const ADDRESSES = {
    Exchanger: {
        1: '0xD64D83829D92B5bdA881f6f61A4e4E27Fc185387',
        5: '0x889d8a97f43809Ef3FBb002B4b7a6A65319B61eD',
        10: '0xC37c47C55d894443493c1e2E615f4F9f4b8fDEa4',
        420: '0x601A1Cf1a34d9cF0020dCCD361c155Fe54CE24fB',
    },
    SystemStatus: {
        1: '0x696c905F8F8c006cA46e9808fE7e00049507798F',
        5: '0x31541f35F6Bd061f4A894fB7eEE565f81EE50df3',
        10: '0xE8c41bE1A167314ABAF2423b72Bf8da826943FFD',
        420: '0x9D89fF8C6f3CC22F4BbB859D0F85FB3a4e1FA916',
    },
    ExchangeRates: {
        1: '0xb4dc5ced63C2918c89E491D19BF1C0e92845de7C',
        5: '0xea765947303051507033202CAB7D3f5d4961CF5d',
        10: '0x0cA3985f973f044978d2381AFEd9c4D85a762d11',
        420: '0x061B75475035c20ef2e35E1002Beb90C3c1f24cC',
    },
    SynthUtil: {
        1: '0x81Aee4EA48f678E172640fB5813cf7A96AFaF6C3',
        5: '0x492395BA6866EF703DA49667fF92Cb8551e7a2D1',
        10: '0x87b1481c82913301Fc6c884Ac266a7c430F92cFA',
        420: '0xC647DecC9c4f9162dBF77E4367199F5ED0950355',
    },
    SystemSettings: {
        1: '0x5ad055A1F8C936FB0deb7024f1539Bb3eAA8dc3E',
        5: '0xA1B0898C54124E06aEAa823dC46ad0C306Ca6CD5',
        10: '0x05E1b1Dff853B1D67828Aa5E8CB37cC25aA050eE',
        420: '0xD2cECA6DD62243aB2d342Eb04882c86a10b35274',
    },
    SynthRedeemer: {
        1: '0xe533139Af961c9747356D947838c98451015e234',
        5: '0x32A0BAA5Acec418a85Fd032f0292893B8E4f743B',
        10: '0xA997BD647AEe62Ef03b41e6fBFAdaB43d8E57535',
        420: '0x2A8338199D802620B4516a557195a498595d7Eb6',
    },
    FuturesMarketData: {
        10: '0xC51aeDBEC3aCD26650a7E85B6909E8AEc4d0F19e',
        420: '0x3FAe35Cfea950Fada314589213BABC54A084d5Bf',
    },
    FuturesMarketSettings: {
        10: '0xaE55F163337A2A46733AA66dA9F35299f9A46e9e',
        420: '0x0dde87714C3bdACB93bB1d38605aFff209a85998',
    },
    PerpsV2MarketData: {
        10: '0x340B5d664834113735730Ad4aFb3760219Ad9112',
        420: '0xcE2dC389fc8Be231beECED1D900881e38596d7b2',
    },
    PerpsV2MarketSettings: {
        10: '0x649F44CAC3276557D03223Dbf6395Af65b11c11c',
        420: '0xedf10514EF611e3808622f24e236b83cB9E51dCe',
    },
    Pyth: {
        10: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
        420: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
    },
    SUSD: {
        1: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
        10: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        420: '0xebaeaad9236615542844adc5c149f86c36ad1136',
    },
    USDC: {
        1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        10: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    },
    USDT: {
        1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        10: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
    },
    DAI: {
        1: '0x6b175474e89094c44da98b954eedeac495271d0f',
        10: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    },
    LUSD: {
        10: '0xc40F949F8a4e094D1b49a23ea9241D289B7b2819',
    },
    SNXUSD: {
        420: '0xe487Ad4291019b33e2230F8E2FB1fb6490325260',
    },
    Synthetix: {
        1: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
        5: '0x51f44ca59b867E005e48FA573Cb8df83FC7f7597',
        10: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
        420: '0x2E5ED97596a8368EB9E44B1f3F25B2E813845303',
    },
    SynthSwap: {
        10: '0x9a905223A4AFc00239d71C7fA0Ed9A111Ef33e26',
    },
    SmartMarginAccount: {
        10: '0x3cc04801539B74eaf9e7445a7fDCB8BE396e9D4E',
        420: '0x4BdC58f5F4A1F0DCc36B5366C783C7971D0F4e4b',
    },
    SmartMarginAccountFactory: {
        10: '0xbCA857ac39123Db0dbf4305959632A1b409c907c',
        420: '0x06fF53410154D46c887F0adA225fe719133598E5',
    },
    SmartMarginEvents: {
        10: '0x94824eddB802b3A00bC60eADdb52ea408170419D',
        420: '0x9efA27c43Be71f56dD73B9Db6410D187cC38d1c8',
    },
    DextoroArrakisVault: {
        10: '0x56dEa47c40877c2aaC2a689aC56aa56cAE4938d2',
    },
    StakingRewards: {
        10: '0x04954C023E72153e4319009e481b6f00FD9245C2', //0x6077987e8e06c062094c33177Eb12c4A65f90B65
    },
    DextoroToken: {
        10: '0x1CeF2d62aF4cd26673C7416957CC4Ec619A696a7',
        420: '0xDA0C33402Fc1e10d18c532F0Ed9c1A6c5C9e386C',
    },
    DextoroStakingRewards: {
        10: '0x04954C023E72153e4319009e481b6f00FD9245C2',
        420: '0x1653a3a3c4ccee0538685f1600a30df5e3ee830a',
    },
    DextoroStakingRewardsV2: {
        10: '0x5b53C6B382D416EA74CD8A19D48719983e3a28B4',
        420: '0xA2dF816B2C5D8d799069d6a8a9f8464D402b5D25',
    },
    RewardEscrow: {
        10: '0xCc1a41132623445FA1b7757F9428e1ee23627BB2',
        420: '0xaFD87d1a62260bD5714C55a1BB4057bDc8dFA413',
    },
    RewardEscrowV2: {
        10: '0x4F69163C3E052dD3bEeb5fab1CfC0fDBe736D255',
        420: '0xe331a7eeC851Ba702aA8BF43070a178451d6D28E',
    },
    ReferralStorage: {
        10: '0xdbec8cd03756bC5499A7aF104223Ca542822c8FA',
    },
    SupplySchedule: {
        10: '0x77A5BE78380E0B032ed146785155eFDe1ea393d9',
        420: '0x671423b2e8a99882fd14bbd07e90ae8b64a0e63a',
    },
    vDextoroToken: {
        10: '0x6789D8a7a7871923Fc6430432A602879eCB6520a',
        420: '0xb897D76bC9F7efB66Fb94970371ef17998c296b6',
    },
    veDextoroToken: {
        10: '0x678d8f4ba8dfe6bad51796351824dcceceaeff2b',
        420: '0x3e52b5f840eafd79394c6359e93bf3ffdae89ee4',
    },
    vDextoroRedeemer: {
        10: '0x8132EE584bCD6f8Eb1bea141DB7a7AC1E72917b9',
        420: '0x03c3E61D624F279243e1c8b43eD0fCF6790D10E9',
    },
    veDextoroRedeemer: {
        10: '0xc7088AC8F287539567e458C7D08C2a1470Fd25B7',
        420: '0x86ca3CEbEA60101292EEFCd5802fD6e55D647c87',
    },
    TradingRewards: {
        10: '0xf486A72E8c8143ACd9F65A104A16990fDb38be14',
        420: '0x74c0A3bD10634759DC8B4CA7078C8Bf85bFE1271',
    },
    ReferralAffiliateRewards: {
        10: '0x2EA2A3CAB35013d29e46E6cfB697c921F2FA6424', //MultipleMerkleDistributorReferral
    },
    TradingRewardsPerpsV2: {
        10: '0xE0Ce977ceFBC6B5791BC97AeEfD23403e3E14489', //MultipleMerkleDistributor 0x2787CC20e5ECb4BF1bfB79eAE284201027683179
    },
    TradingRewardsStakingV2: {
        10: '0x54C77CDcC2bA05e7172244CCb0d7455df9a894F0', //EscrowedMultipleMerkleDistributor 0x195f6F7ca2268e1DEC03352786C350Eb61aBa307
    },
    OpRewards: {
        10: '0x1964cF9d0c5c268DcF5a5d37F13600483237f6F5',
    },
    SnxOpRewards: {
        10: '0xD301BCC5D3229A0a6400F1030c382A5d503931E1',
    },
    BatchClaimer: {
        10: '0x8a0d7e671C131D3a2324598E169AE12b32ce9Db9', //BatchClaimer 0x6Fd879830D9b1EE5d4f9ef12f8D5deE916bebD0b
    },
    DappMaintenance: {
        10: '0x54581A23F62D147AC76d454f0b3eF77F9D766058',
        420: '0x4259a2004A1E110A86564ff1441c37F1461F344F',
    },
    PerpsV3MarketProxy: {
        420: '0xf272382cB3BE898A8CdB1A23BE056fA2Fcf4513b',
    },
    PerpsV3AccountProxy: {
        420: '0x01C2f64ABd46AF20950736f3C3e1a9cfc5c36c82',
    },
    EscrowMigrator: {
        10: '0xd3FA2fb74b803768f6CD077A137421Bf796341e7',
        420: '0x7dF905e163d54B4FE9a14aE01cF985BDc82c1A0F',
    },
    BoostNft: {
        10: '0xD3B8876073949D790AB718CAD21d9326a3adA60f',
    },
};
