import {
	TransactionStatus,
	FuturesMarketKey,
	DextoroStatus,
	GasPrice,
} from '@dextoroprotocol/sdk/types'

import { FuturesTransactionType } from 'state/futures/common/types'
import Wei from '@synthetixio/wei'

export type ModalType =
	| 'futures_deposit_withdraw_smart_margin'
	| 'futures_deposit_withdraw_cross_margin'
	| 'futures_confirm_smart_margin_trade'
	| 'futures_confirm_cross_margin_trade'
	| 'futures_withdraw_keeper_balance'
	| 'futures_smart_margin_onboard'
	| 'futures_cross_margin_onboard'
	| 'futures_smart_margin_socket'
	| 'futures_smart_margin_manage'
	| 'oneclick_active_delegate'
	| 'help'
	| null

export type FuturesPositionModalType =
	| 'smart_margin_close_position'
	| 'cross_margin_close_position'
	| 'futures_edit_position_margin'
	| 'futures_edit_position_size'
	| 'futures_edit_stop_loss_take_profit'

export type GasSpeed = 'average' | 'fast' | 'fastest'
export type DefaultTab = 'withdraw' | 'deposit' | 'oneclick-trading'
export type ShowWalkthrough = 'show' | 'hide'
export type Settings = boolean
export type MobileTradePanel = boolean
export type Notification = boolean

export type TransactionType = FuturesTransactionType // TODO: Support all types

export type Transaction = {
	type: TransactionType
	status: TransactionStatus
	error?: string
	hash: string | null
}

export enum WithdrawTabs {
	WithdrawUSD = 'WithdrawUSD',
	ManageKeeperBalance = 'ManageKeeperBalance',
}

export type SelectedPositionsData = {
	entry_price: Wei
	liq_price: Wei
} | null

export enum SortMethod {
	Favorites = 'favorites',
	SortByPrice = 'sortByPrice',
	SortByChange = 'sortByChange',
	SortByVolume = 'sortByVolume',
	SortByOP = 'sortByOP',
	SortByFR = 'sortByFR',
}

export enum SortOrder {
	Ascending = 'asc',
	Descending = 'desc',
}

export type AppState = {
	showModal?: ModalType
	showPositionModal?: { type: FuturesPositionModalType; marketKey: FuturesMarketKey } | null
	gasSpeed: GasSpeed
	gasPrice: GasPrice<string>
	transaction?: Transaction | undefined
	synthetixOnMaintenance: boolean
	dextoroStatus: DextoroStatus
	acknowledgedOrdersWarning: boolean
	showBanner: boolean
	defaultTab: DefaultTab
	showWalkthrough: ShowWalkthrough
	settings: Settings
	showMobileTradePanel: MobileTradePanel
	withdrawModalTab?: WithdrawTabs | null
	showNotification: Notification
	selectedPositionsData?: SelectedPositionsData
	activeFilter?: string
	sortMethod: SortMethod
	opDirection: SortOrder
	frDirection: SortOrder
	priceDirection: SortOrder
	changeDirection: SortOrder
	volumeDirection: SortOrder
	detailsCard: boolean
}
