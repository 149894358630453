import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { EscrowData } from '@dextoroprotocol/sdk/types'
import { toWei } from '@dextoroprotocol/sdk/utils'
import { createSelector } from '@reduxjs/toolkit'
import { wei } from '@synthetixio/wei'

import { STAKING_DISABLED } from 'constants/ui'
import { getApy, getEpochDetails, parseEpochData } from 'queries/staking/utils'
import { RootState } from 'state/store'
import { FetchStatus } from 'state/types'

export const selectDextoroBalance = createSelector(
	(state: RootState) => state.staking.dextoroBalance,
	toWei
)

export const selectEscrowedDextoroBalance = createSelector(
	(state: RootState) => state.staking.v1.escrowedDextoroBalance,
	toWei
)

export const selectEscrowedDextoroBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.escrowedDextoroBalance,
	toWei
)

export const selectStakedEscrowedDextoroBalance = createSelector(
	(state: RootState) => state.staking.v1.stakedEscrowedDextoroBalance,
	toWei
)

export const selectStakedEscrowedDextoroBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.stakedEscrowedDextoroBalance,
	toWei
)

export const selectStakedDextoroBalance = createSelector(
	(state: RootState) => state.staking.v1.stakedDextoroBalance,
	toWei
)

export const selectUnstakedEscrowedDextoroBalance = createSelector(
	selectEscrowedDextoroBalance,
	selectStakedEscrowedDextoroBalance,
	(escrowedDextoroBalance, stakedEscrowedDextoroBalance) => {
		return escrowedDextoroBalance.sub(stakedEscrowedDextoroBalance)
	}
)

export const selectUnstakedEscrowedDextoroBalanceV2 = createSelector(
	selectEscrowedDextoroBalanceV2,
	selectStakedEscrowedDextoroBalanceV2,
	(escrowedDextoroBalance, stakedEscrowedDextoroBalance) => {
		return escrowedDextoroBalance.sub(stakedEscrowedDextoroBalance)
	}
)

export const selectClaimableBalance = createSelector(
	(state: RootState) => state.staking.v1.claimableBalance,
	toWei
)

export const selectStakedDextoroBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.stakedDextoroBalance,
	toWei
)

export const selectClaimableBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.claimableBalance,
	toWei
)

export const selectIsDextoroTokenApproved = createSelector(
	selectDextoroBalance,
	(state: RootState) => state.staking.dextoroAllowance,
	(dextoroBalance, dextoroAllowance) => dextoroBalance.lte(dextoroAllowance)
)

export const selectIsDextoroTokenApprovedV2 = createSelector(
	selectDextoroBalance,
	(state: RootState) => state.staking.dextoroStakingV2Allowance,
	(dextoroBalance, dextoroAllowance) => dextoroBalance.lte(dextoroAllowance)
)

export const selectResetTime = createSelector(
	(state: RootState) => state.wallet.networkId,
	(state: RootState) => state.staking.epochPeriod,
	(networkId, epochPeriod) => {
		const { epochEnd } = getEpochDetails(networkId ?? 10, epochPeriod)
		return epochEnd
	}
)

export const selectStakedResetTime = (state: RootState) => state.staking.stakedResetTime

export const selectEpochData = createSelector(
	(state: RootState) => state.staking.epochPeriod,
	(state: RootState) => state.wallet.networkId,
	(epochPeriod, networkId) => {
		return Array.from(new Array(epochPeriod + 1), (_, i) => parseEpochData(i, networkId))
	}
)

export const selectSelectedEpoch = createSelector(
	(state: RootState) => state.staking.selectedEpoch,
	(state: RootState) => state.staking.epochPeriod,
	(state: RootState) => state.wallet.networkId,
	(selectedEpoch, epochPeriod, networkId) => parseEpochData(selectedEpoch ?? epochPeriod, networkId)
)

export const selectIsStakingDextoro = createSelector(
	(state: RootState) => state.staking.stakeStatus,
	(stakeStatus) => stakeStatus === FetchStatus.Loading
)

export const selectIsUnstakingDextoro = createSelector(
	(state: RootState) => state.staking.unstakeStatus,
	(unstakeStatus) => unstakeStatus === FetchStatus.Loading
)

export const selectIsApprovingDextoro = createSelector(
	(state: RootState) => state.staking.approveDextoroStatus,
	(approveDextoroStatus) => approveDextoroStatus === FetchStatus.Loading
)

export const selectIsStakedDextoro = createSelector(
	(state: RootState) => state.staking.stakeStatus,
	(stakeStatus) => stakeStatus === FetchStatus.Success || stakeStatus === FetchStatus.Error
)

export const selectIsUnstakedDextoro = createSelector(
	(state: RootState) => state.staking.unstakeStatus,
	(unstakeStatus) => unstakeStatus === FetchStatus.Success || unstakeStatus === FetchStatus.Error
)

export const selectIsStakingEscrowedDextoro = createSelector(
	(state: RootState) => state.staking.stakeEscrowedStatus,
	(stakeEscrowedStatus) => stakeEscrowedStatus === FetchStatus.Loading
)

export const selectIsUnstakingEscrowedDextoro = createSelector(
	(state: RootState) => state.staking.unstakeEscrowedStatus,
	(unstakeEscrowedStatus) => unstakeEscrowedStatus === FetchStatus.Loading
)

export const selectIsStakedEscrowedDextoro = createSelector(
	(state: RootState) => state.staking.stakeEscrowedStatus,
	(stakeEscrowedStatus) =>
		stakeEscrowedStatus === FetchStatus.Success || stakeEscrowedStatus === FetchStatus.Error
)

export const selectIsUnstakedEscrowedDextoro = createSelector(
	(state: RootState) => state.staking.unstakeEscrowedStatus,
	(unstakeEscrowedStatus) =>
		unstakeEscrowedStatus === FetchStatus.Success || unstakeEscrowedStatus === FetchStatus.Error
)

export const selectIsGettingReward = createSelector(
	(state: RootState) => state.staking.getRewardStatus,
	(getRewardStatus) => getRewardStatus === FetchStatus.Loading
)

export const selectIsClaimingRewards = createSelector(
	(state: RootState) => state.staking.claimDextoroRewardsStatus,
	(claimDextoroRewardsStatus) => claimDextoroRewardsStatus === FetchStatus.Loading
)

export const selectIsCompoundingRewards = createSelector(
	(state: RootState) => state.staking.compoundRewardsStatus,
	(compoundRewardsStatus) => compoundRewardsStatus === FetchStatus.Loading
)
export const selectIsVestingEscrowedRewards = createSelector(
	(state: RootState) => state.staking.vestEscrowedRewardsStatus,
	(vestEscrowedRewardsStatus) => vestEscrowedRewardsStatus === FetchStatus.Loading
)

export const selectDextoroRewards = createSelector(
	(state: RootState) => state.staking.dextoroRewards,
	wei
)

export const selectDextoroPeriodsRewards = (state: RootState) => state.staking.periodsRewards

// export const selectOpRewards = createSelector((state: RootState) => state.staking.opRewards, wei)

// export const selectSnxOpRewards = createSelector(
// 	(state: RootState) => state.staking.snxOpRewards,
// 	wei
// )

export const selectEstimatedDextoroRewards = createSelector(
	(state: RootState) => state.staking.estimatedDextoroRewards,
	wei
)

export const selectEstimatedOpRewards = createSelector(
	(state: RootState) => state.staking.estimatedOpRewards,
	wei
)

export const selectTotalVestable = createSelector(
	(state: RootState) => state.staking.v1.totalVestable,
	wei
)

export const selectTotalVestableV2 = createSelector(
	(state: RootState) => state.staking.v2.totalVestable,
	wei
)

export const selectIsTimeLeftInCooldown = createSelector(
	selectStakedResetTime,
	(stakedResetTime) => stakedResetTime > new Date().getTime() / 1000
)

export const selectCanStakeDextoro = createSelector(
	selectDextoroBalance,
	selectIsStakingDextoro,
	(dextoroBalance, isStakingDextoro) =>
		dextoroBalance.gt(0) && !isStakingDextoro && !STAKING_DISABLED
)

export const selectCanUnstakeDextoroV2 = createSelector(
	selectStakedDextoroBalanceV2,
	selectIsUnstakingDextoro,
	selectIsTimeLeftInCooldown,
	(stakedDextoroBalance, isUnstakingDextoro, isTimeLeftInCooldown) =>
		stakedDextoroBalance.gt(0) && !isUnstakingDextoro && !isTimeLeftInCooldown && !STAKING_DISABLED
)

export const selectCanUnstakeDextoro = createSelector(
	selectStakedDextoroBalance,
	selectIsUnstakingDextoro,
	(stakedDextoroBalance, isUnstakingDextoro) =>
		stakedDextoroBalance.gt(0) && !isUnstakingDextoro && !STAKING_DISABLED
)

export const selectCanStakeEscrowedDextoro = createSelector(
	selectUnstakedEscrowedDextoroBalanceV2,
	selectIsStakingEscrowedDextoro,
	(unstakedEscrowedDextoroBalance, isStakingEscrowedDextoro) => {
		return unstakedEscrowedDextoroBalance.gt(0) && !isStakingEscrowedDextoro && !STAKING_DISABLED
	}
)

export const selectCanUnstakeEscrowedDextoro = createSelector(
	selectStakedEscrowedDextoroBalance,
	selectIsUnstakingEscrowedDextoro,
	(stakedEscrowedDextoroBalance, isUnstakingEscrowedDextoro) => {
		return stakedEscrowedDextoroBalance.gt(0) && !isUnstakingEscrowedDextoro && !STAKING_DISABLED
	}
)

export const selectCanUnstakeEscrowedDextoroV2 = createSelector(
	selectStakedEscrowedDextoroBalanceV2,
	selectIsUnstakingEscrowedDextoro,
	selectIsTimeLeftInCooldown,
	(stakedEscrowedDextoroBalance, isUnstakingEscrowedDextoro, isTimeLeftInCooldown) => {
		return (
			stakedEscrowedDextoroBalance.gt(0) &&
			!isUnstakingEscrowedDextoro &&
			!isTimeLeftInCooldown &&
			!STAKING_DISABLED
		)
	}
)

export const selectEpochPeriod = createSelector(
	(state: RootState) => state.staking.epochPeriod,
	wei
)

export const selectAPY = createSelector(
	(state: RootState) => state.staking.v1.totalStakedBalance,
	(state: RootState) => state.staking.weekCounter,
	(totalStakedBalance, weekCounter) => {
		return getApy(Number(totalStakedBalance), weekCounter)
	}
)

export const selectAPYV2 = createSelector(
	(state: RootState) => state.staking.v2.totalStakedBalance,
	(state: RootState) => state.staking.v1.totalStakedBalance,
	(state: RootState) => state.staking.weekCounter,
	(totalStakedBalance, totalStakedBalanceV1, weekCounter) => {
		return getApy(Number(totalStakedBalance) + Number(totalStakedBalanceV1), weekCounter)
	}
)

export const selectEscrowData = (state: RootState) => state.staking.v1.escrowData ?? []

export const selectEscrowV2Data = (state: RootState) => state.staking.v2.escrowData ?? []

export const selectVestEscrowV2Entries = createSelector(selectEscrowV2Data, (escrowData) =>
	escrowData.map((entry: EscrowData<string>) => entry.id)
)

export const selectStakingRollbackRequired = createSelector(
	selectStakedDextoroBalanceV2,
	selectTotalVestableV2,
	(stakedDextoroBalance, totalVestable) =>
		stakedDextoroBalance.gt(ZERO_WEI) || totalVestable.gt(ZERO_WEI)
)

export const selectStakingMigrationCompleted = (state: RootState) =>
	state.staking.stakingMigrationCompleted

export const selectStakingMigrationRequired = createSelector(
	selectClaimableBalance,
	selectStakedDextoroBalance,
	(claimableBalanceV1, stakedDextoroBalanceV1) =>
		claimableBalanceV1.gt(ZERO_WEI) || stakedDextoroBalanceV1.gt(ZERO_WEI)
)

export const selectSelectedEscrowVersion = (state: RootState) =>
	state.staking.selectedEscrowVersion ?? 1

export const selectCombinedEscrowData = createSelector(
	selectEscrowData,
	selectEscrowV2Data,
	selectSelectedEscrowVersion,
	(escrowDataV1, escrowDataV2, escrowVersion) => (escrowVersion === 1 ? escrowDataV1 : escrowDataV2)
)

export const selectTradingRewardsSupportedNetwork = (state: RootState) =>
	state.wallet.networkId === 10

export const selectStakingSupportedNetwork = (state: RootState) =>
	state.wallet.networkId === 10 || state.wallet.networkId === 420

export const selectIsClaimingAllRewards = createSelector(
	(state: RootState) => state.staking.claimAllRewardsStatus,
	(claimAllRewardsStatus) => claimAllRewardsStatus === FetchStatus.Loading
)
