import { ethers } from 'ethers';
export const truncateAddress = (address, first = 6, last = 4) => `${address.slice(0, first)}...${address.slice(-last, address.length)}`;
export const truncateString = (text, max = 256) => {
    if ((text === null || text === void 0 ? void 0 : text.length) > max)
        return text.substring(0, max) + ' ...';
    return text;
};
export const strPadLeft = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
};
export const encodeReferralCode = (text) => {
    let final = text.replace(/[^\w_]/g, '');
    final = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(text));
    while (final.length < 66) {
        final += '0';
    }
    if (final.length !== 66) {
        throw new Error('invalid web3 implicit bytes32');
    }
    return final;
};
