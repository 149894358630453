import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserData } from './types'
import { saveUserData } from './actions'

interface UserDataState {
	userData: UserData | null
	status: 'idle' | 'loading' | 'succeeded' | 'failed'
	error: string | null
}

const initialState: UserDataState = {
	userData: null,
	status: 'idle',
	error: null,
}

const userDataSlice = createSlice({
	name: 'userData',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Handle saveUserData (POST - Save user data)
		builder
			.addCase(saveUserData.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(saveUserData.fulfilled, (state, action: PayloadAction<UserData>) => {
				state.status = 'succeeded'
				state.userData = action.payload
			})
			.addCase(saveUserData.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message || 'Failed to save user data'
			})
	},
})

export const { actions } = userDataSlice

export default userDataSlice.reducer
