/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "which",
                type: "bytes32",
            },
        ],
        name: "FeatureUnavailable",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "InvalidAccountId",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
        ],
        name: "InvalidPermission",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "origin",
                type: "address",
            },
        ],
        name: "OnlyAccountTokenProxy",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "target",
                type: "address",
            },
        ],
        name: "PermissionDenied",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "PermissionNotGranted",
        type: "error",
    },
    {
        inputs: [],
        name: "PositionOutOfBounds",
        type: "error",
    },
    {
        inputs: [],
        name: "ValueAlreadyInSet",
        type: "error",
    },
    {
        inputs: [],
        name: "ValueNotInSet",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        name: "AccountCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "PermissionGranted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "PermissionRevoked",
        type: "event",
    },
    {
        inputs: [],
        name: "createAccount",
        outputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "requestedAccountId",
                type: "uint128",
            },
        ],
        name: "createAccount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getAccountLastInteraction",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getAccountOwner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getAccountPermissions",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "bytes32[]",
                        name: "permissions",
                        type: "bytes32[]",
                    },
                ],
                internalType: "struct IAccountModule.AccountPermissions[]",
                name: "accountPerms",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getAccountTokenAddress",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "grantPermission",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "hasPermission",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "isAuthorized",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "notifyAccountTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
        ],
        name: "renouncePermission",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "permission",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "revokePermission",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "expected",
                type: "bytes32",
            },
            {
                internalType: "bytes32",
                name: "actual",
                type: "bytes32",
            },
        ],
        name: "MismatchAssociatedSystemKind",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
        ],
        name: "MissingAssociatedSystem",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "addr",
                type: "address",
            },
        ],
        name: "Unauthorized",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "kind",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "proxy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "impl",
                type: "address",
            },
        ],
        name: "AssociatedSystemSet",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
        ],
        name: "getAssociatedSystem",
        outputs: [
            {
                internalType: "address",
                name: "addr",
                type: "address",
            },
            {
                internalType: "bytes32",
                name: "kind",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                internalType: "string",
                name: "name",
                type: "string",
            },
            {
                internalType: "string",
                name: "symbol",
                type: "string",
            },
            {
                internalType: "string",
                name: "uri",
                type: "string",
            },
            {
                internalType: "address",
                name: "impl",
                type: "address",
            },
        ],
        name: "initOrUpgradeNft",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                internalType: "string",
                name: "name",
                type: "string",
            },
            {
                internalType: "string",
                name: "symbol",
                type: "string",
            },
            {
                internalType: "uint8",
                name: "decimals",
                type: "uint8",
            },
            {
                internalType: "address",
                name: "impl",
                type: "address",
            },
        ],
        name: "initOrUpgradeToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "endpoint",
                type: "address",
            },
        ],
        name: "registerUnmanagedSystem",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "ImplementationIsSterile",
        type: "error",
    },
    {
        inputs: [],
        name: "NoChange",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "contr",
                type: "address",
            },
        ],
        name: "NotAContract",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "addr",
                type: "address",
            },
        ],
        name: "NotNominated",
        type: "error",
    },
    {
        inputs: [],
        name: "UpgradeSimulationFailed",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "oldOwner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnerChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnerNominated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "self",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "Upgraded",
        type: "event",
    },
    {
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getImplementation",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newNominatedOwner",
                type: "address",
            },
        ],
        name: "nominateNewOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "nominatedOwner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceNomination",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
        ],
        name: "simulateUpgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "InvalidMarket",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "parameter",
                type: "string",
            },
            {
                internalType: "string",
                name: "reason",
                type: "string",
            },
        ],
        name: "InvalidParameter",
        type: "error",
    },
    {
        inputs: [],
        name: "OverflowInt256ToUint256",
        type: "error",
    },
    {
        inputs: [],
        name: "OverflowUint256ToInt256",
        type: "error",
    },
    {
        inputs: [],
        name: "OverflowUint256ToUint128",
        type: "error",
    },
    {
        inputs: [],
        name: "PerpsMarketNotInitialized",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint128",
                name: "globalPerpsMarketId",
                type: "uint128",
            },
        ],
        name: "FactoryInitialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "perpsMarketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "string",
                name: "marketName",
                type: "string",
            },
            {
                indexed: false,
                internalType: "string",
                name: "marketSymbol",
                type: "string",
            },
        ],
        name: "MarketCreated",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "requestedMarketId",
                type: "uint128",
            },
            {
                internalType: "string",
                name: "marketName",
                type: "string",
            },
            {
                internalType: "string",
                name: "marketSymbol",
                type: "string",
            },
        ],
        name: "createMarket",
        outputs: [
            {
                internalType: "uint128",
                name: "",
                type: "uint128",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "initializeFactory",
        outputs: [
            {
                internalType: "uint128",
                name: "",
                type: "uint128",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "perpsMarketId",
                type: "uint128",
            },
        ],
        name: "minimumCredit",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "perpsMarketId",
                type: "uint128",
            },
        ],
        name: "name",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "perpsMarketId",
                type: "uint128",
            },
        ],
        name: "reportedDebt",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract ISpotMarketSystem",
                name: "spotMarket",
                type: "address",
            },
        ],
        name: "setSpotMarket",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract ISynthetixSystem",
                name: "synthetix",
                type: "address",
            },
        ],
        name: "setSynthetix",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4",
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "AccountLiquidatable",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "AccountNotFound",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "collateralAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "withdrawAmount",
                type: "uint256",
            },
        ],
        name: "InsufficientCollateral",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "available",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "required",
                type: "uint256",
            },
        ],
        name: "InsufficientCollateralAvailableForWithdraw",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "int256",
                name: "amountDelta",
                type: "int256",
            },
        ],
        name: "InvalidAmountDelta",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "maxAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "collateralAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "depositAmount",
                type: "uint256",
            },
        ],
        name: "MaxCollateralExceeded",
        type: "error",
    },
    {
        inputs: [],
        name: "OverflowUint128ToInt128",
        type: "error",
    },
    {
        inputs: [],
        name: "PendingOrderExists",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "PriceFeedNotSet",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
        ],
        name: "SynthNotEnabledForCollateral",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "amountDelta",
                type: "int256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "CollateralModified",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getAvailableMargin",
        outputs: [
            {
                internalType: "int256",
                name: "availableMargin",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
        ],
        name: "getCollateralAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getOpenPosition",
        outputs: [
            {
                internalType: "int256",
                name: "totalPnl",
                type: "int256",
            },
            {
                internalType: "int256",
                name: "accruedFunding",
                type: "int256",
            },
            {
                internalType: "int128",
                name: "positionSize",
                type: "int128",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getRequiredMargins",
        outputs: [
            {
                internalType: "uint256",
                name: "requiredInitialMargin",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "requiredMaintenanceMargin",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getWithdrawableMargin",
        outputs: [
            {
                internalType: "int256",
                name: "withdrawableMargin",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
            {
                internalType: "int256",
                name: "amountDelta",
                type: "int256",
            },
        ],
        name: "modifyCollateral",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "totalAccountOpenInterest",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "totalCollateralValue",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "currentFundingRate",
        outputs: [
            {
                internalType: "int256",
                name: "",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "currentFundingVelocity",
        outputs: [
            {
                internalType: "int256",
                name: "",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "int128",
                name: "orderSize",
                type: "int128",
            },
            {
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
        ],
        name: "fillPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getMarketSummary",
        outputs: [
            {
                components: [
                    {
                        internalType: "int256",
                        name: "skew",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "size",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxOpenInterest",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "currentFundingRate",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "currentFundingVelocity",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "indexPrice",
                        type: "uint256",
                    },
                ],
                internalType: "struct IPerpsMarketModule.MarketSummary",
                name: "summary",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "indexPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "maxOpenInterest",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "metadata",
        outputs: [
            {
                internalType: "string",
                name: "name",
                type: "string",
            },
            {
                internalType: "string",
                name: "symbol",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "size",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "skew",
        outputs: [
            {
                internalType: "int256",
                name: "",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "acceptablePrice",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "fillPrice",
                type: "uint256",
            },
        ],
        name: "AcceptablePriceExceeded",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "int256",
                name: "availableMargin",
                type: "int256",
            },
            {
                internalType: "uint256",
                name: "minMargin",
                type: "uint256",
            },
        ],
        name: "InsufficientMargin",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "settlementStrategyId",
                type: "uint128",
            },
        ],
        name: "InvalidSettlementStrategy",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "maxMarketSize",
                type: "uint256",
            },
            {
                internalType: "int256",
                name: "newSideSize",
                type: "int256",
            },
        ],
        name: "MaxOpenInterestReached",
        type: "error",
    },
    {
        inputs: [],
        name: "OverflowInt256ToInt128",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroSizeOrder",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "enum SettlementStrategy.Type",
                name: "orderType",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "int128",
                name: "sizeDelta",
                type: "int128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "acceptablePrice",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "settlementTime",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "expirationTime",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "trackingCode",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "OrderCommitted",
        type: "event",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "uint128",
                        name: "marketId",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "accountId",
                        type: "uint128",
                    },
                    {
                        internalType: "int128",
                        name: "sizeDelta",
                        type: "int128",
                    },
                    {
                        internalType: "uint128",
                        name: "settlementStrategyId",
                        type: "uint128",
                    },
                    {
                        internalType: "uint256",
                        name: "acceptablePrice",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes32",
                        name: "trackingCode",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "referrer",
                        type: "address",
                    },
                ],
                internalType: "struct AsyncOrder.OrderCommitmentRequest",
                name: "commitment",
                type: "tuple",
            },
        ],
        name: "commitOrder",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "settlementTime",
                        type: "uint256",
                    },
                    {
                        components: [
                            {
                                internalType: "uint128",
                                name: "marketId",
                                type: "uint128",
                            },
                            {
                                internalType: "uint128",
                                name: "accountId",
                                type: "uint128",
                            },
                            {
                                internalType: "int128",
                                name: "sizeDelta",
                                type: "int128",
                            },
                            {
                                internalType: "uint128",
                                name: "settlementStrategyId",
                                type: "uint128",
                            },
                            {
                                internalType: "uint256",
                                name: "acceptablePrice",
                                type: "uint256",
                            },
                            {
                                internalType: "bytes32",
                                name: "trackingCode",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "referrer",
                                type: "address",
                            },
                        ],
                        internalType: "struct AsyncOrder.OrderCommitmentRequest",
                        name: "request",
                        type: "tuple",
                    },
                ],
                internalType: "struct AsyncOrder.Data",
                name: "retOrder",
                type: "tuple",
            },
            {
                internalType: "uint256",
                name: "fees",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "int128",
                name: "sizeDelta",
                type: "int128",
            },
        ],
        name: "computeOrderFees",
        outputs: [
            {
                internalType: "uint256",
                name: "orderFees",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "getOrder",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "settlementTime",
                        type: "uint256",
                    },
                    {
                        components: [
                            {
                                internalType: "uint128",
                                name: "marketId",
                                type: "uint128",
                            },
                            {
                                internalType: "uint128",
                                name: "accountId",
                                type: "uint128",
                            },
                            {
                                internalType: "int128",
                                name: "sizeDelta",
                                type: "int128",
                            },
                            {
                                internalType: "uint128",
                                name: "settlementStrategyId",
                                type: "uint128",
                            },
                            {
                                internalType: "uint256",
                                name: "acceptablePrice",
                                type: "uint256",
                            },
                            {
                                internalType: "bytes32",
                                name: "trackingCode",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "referrer",
                                type: "address",
                            },
                        ],
                        internalType: "struct AsyncOrder.OrderCommitmentRequest",
                        name: "request",
                        type: "tuple",
                    },
                ],
                internalType: "struct AsyncOrder.Data",
                name: "order",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "leftover",
                type: "uint256",
            },
        ],
        name: "InsufficientMarginError",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sender",
                type: "address",
            },
            {
                internalType: "string[]",
                name: "urls",
                type: "string[]",
            },
            {
                internalType: "bytes",
                name: "callData",
                type: "bytes",
            },
            {
                internalType: "bytes4",
                name: "callbackFunction",
                type: "bytes4",
            },
            {
                internalType: "bytes",
                name: "extraData",
                type: "bytes",
            },
        ],
        name: "OffchainLookup",
        type: "error",
    },
    {
        inputs: [],
        name: "OrderNotValid",
        type: "error",
    },
    {
        inputs: [],
        name: "OverflowUint256ToUint64",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "deviation",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "tolerance",
                type: "uint256",
            },
        ],
        name: "PriceDeviationToleranceExceeded",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "enum SettlementStrategy.Type",
                name: "strategyType",
                type: "uint8",
            },
        ],
        name: "SettlementStrategyNotFound",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "timestamp",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "settlementTime",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "settlementExpiration",
                type: "uint256",
            },
        ],
        name: "SettlementWindowExpired",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "timestamp",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "settlementTime",
                type: "uint256",
            },
        ],
        name: "SettlementWindowNotOpen",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "skew",
                type: "int256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "size",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "sizeDelta",
                type: "int256",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "currentFundingRate",
                type: "int256",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "currentFundingVelocity",
                type: "int256",
            },
        ],
        name: "MarketUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "fillPrice",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "pnl",
                type: "int256",
            },
            {
                indexed: false,
                internalType: "int128",
                name: "sizeDelta",
                type: "int128",
            },
            {
                indexed: false,
                internalType: "int128",
                name: "newSize",
                type: "int128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "totalFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "referralFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "collectedFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "settlementReward",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "trackingCode",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "settler",
                type: "address",
            },
        ],
        name: "OrderSettled",
        type: "event",
    },
    {
        inputs: [],
        name: "PRECISION",
        outputs: [
            {
                internalType: "int256",
                name: "",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "settle",
        outputs: [],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "result",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "extraData",
                type: "bytes",
            },
        ],
        name: "settlePythOrder",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "allowAll",
                type: "bool",
            },
        ],
        name: "FeatureFlagAllowAllSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "FeatureFlagAllowlistAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "FeatureFlagAllowlistRemoved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "address[]",
                name: "deniers",
                type: "address[]",
            },
        ],
        name: "FeatureFlagDeniersReset",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "denyAll",
                type: "bool",
            },
        ],
        name: "FeatureFlagDenyAllSet",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "addToFeatureFlagAllowlist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
        ],
        name: "getDeniers",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
        ],
        name: "getFeatureFlagAllowAll",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
        ],
        name: "getFeatureFlagAllowlist",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
        ],
        name: "getFeatureFlagDenyAll",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "isFeatureAllowed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "removeFromFeatureFlagAllowlist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                internalType: "address[]",
                name: "deniers",
                type: "address[]",
            },
        ],
        name: "setDeniers",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                internalType: "bool",
                name: "allowAll",
                type: "bool",
            },
        ],
        name: "setFeatureFlagAllowAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "feature",
                type: "bytes32",
            },
            {
                internalType: "bool",
                name: "denyAll",
                type: "bool",
            },
        ],
        name: "setFeatureFlagDenyAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "NotEligibleForLiquidation",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "reward",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "fullLiquidation",
                type: "bool",
            },
        ],
        name: "AccountLiquidated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amountLiquidated",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "int128",
                name: "currentPositionSize",
                type: "int128",
            },
        ],
        name: "PositionLiquidated",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "accountId",
                type: "uint128",
            },
        ],
        name: "liquidate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "liquidateFlagged",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "skewScale",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "maxFundingVelocity",
                type: "uint256",
            },
        ],
        name: "FundingParametersSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "initialMarginRatioD18",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "maintenanceMarginRatioD18",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "liquidationRewardRatioD18",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "maxLiquidationLimitAccumulationMultiplier",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "maxSecondsInLiquidationWindow",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "minimumPositionMargin",
                type: "uint256",
            },
        ],
        name: "LiquidationParametersSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "lockedOiRatioD18",
                type: "uint256",
            },
        ],
        name: "LockedOiRatioSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "feedId",
                type: "bytes32",
            },
        ],
        name: "MarketPriceDataUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "maxMarketSize",
                type: "uint256",
            },
        ],
        name: "MaxMarketSizeSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "makerFeeRatio",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "takerFeeRatio",
                type: "uint256",
            },
        ],
        name: "OrderFeesSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                components: [
                    {
                        internalType: "enum SettlementStrategy.Type",
                        name: "strategyType",
                        type: "uint8",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementDelay",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementWindowDuration",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "priceWindowDuration",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "priceVerificationContract",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "feedId",
                        type: "bytes32",
                    },
                    {
                        internalType: "string",
                        name: "url",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementReward",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "priceDeviationTolerance",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "disabled",
                        type: "bool",
                    },
                ],
                indexed: false,
                internalType: "struct SettlementStrategy.Data",
                name: "strategy",
                type: "tuple",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "strategyId",
                type: "uint256",
            },
        ],
        name: "SettlementStrategyAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "strategyId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
        ],
        name: "SettlementStrategyEnabled",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                components: [
                    {
                        internalType: "enum SettlementStrategy.Type",
                        name: "strategyType",
                        type: "uint8",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementDelay",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementWindowDuration",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "priceWindowDuration",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "priceVerificationContract",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "feedId",
                        type: "bytes32",
                    },
                    {
                        internalType: "string",
                        name: "url",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementReward",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "priceDeviationTolerance",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "disabled",
                        type: "bool",
                    },
                ],
                internalType: "struct SettlementStrategy.Data",
                name: "strategy",
                type: "tuple",
            },
        ],
        name: "addSettlementStrategy",
        outputs: [
            {
                internalType: "uint256",
                name: "strategyId",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getFundingParameters",
        outputs: [
            {
                internalType: "uint256",
                name: "skewScale",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxFundingVelocity",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getLiquidationParameters",
        outputs: [
            {
                internalType: "uint256",
                name: "initialMarginRatioD18",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maintenanceMarginRatioD18",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "liquidationRewardRatioD18",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxLiquidationLimitAccumulationMultiplier",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxSecondsInLiquidationWindow",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getLockedOiRatio",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getMaxMarketSize",
        outputs: [
            {
                internalType: "uint256",
                name: "maxMarketSize",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
        ],
        name: "getOrderFees",
        outputs: [
            {
                internalType: "uint256",
                name: "makerFee",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "takerFee",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "strategyId",
                type: "uint256",
            },
        ],
        name: "getSettlementStrategy",
        outputs: [
            {
                components: [
                    {
                        internalType: "enum SettlementStrategy.Type",
                        name: "strategyType",
                        type: "uint8",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementDelay",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementWindowDuration",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "priceWindowDuration",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "priceVerificationContract",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "feedId",
                        type: "bytes32",
                    },
                    {
                        internalType: "string",
                        name: "url",
                        type: "string",
                    },
                    {
                        internalType: "uint256",
                        name: "settlementReward",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "priceDeviationTolerance",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "disabled",
                        type: "bool",
                    },
                ],
                internalType: "struct SettlementStrategy.Data",
                name: "settlementStrategy",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "skewScale",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxFundingVelocity",
                type: "uint256",
            },
        ],
        name: "setFundingParameters",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "initialMarginRatioD18",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maintenanceMarginRatioD18",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "liquidationRewardRatioD18",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxLiquidationLimitAccumulationMultiplier",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxSecondsInLiquidationWindow",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minimumPositionMargin",
                type: "uint256",
            },
        ],
        name: "setLiquidationParameters",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "lockedOiRatioD18",
                type: "uint256",
            },
        ],
        name: "setLockedOiRatio",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "maxMarketSize",
                type: "uint256",
            },
        ],
        name: "setMaxMarketSize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "makerFeeRatio",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "takerFeeRatio",
                type: "uint256",
            },
        ],
        name: "setOrderFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "marketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "strategyId",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "enabled",
                type: "bool",
            },
        ],
        name: "setSettlementStrategyEnabled",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "perpsMarketId",
                type: "uint128",
            },
            {
                internalType: "bytes32",
                name: "feedId",
                type: "bytes32",
            },
        ],
        name: "updatePriceData",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "invalidFeeCollector",
                type: "address",
            },
        ],
        name: "InvalidFeeCollectorInterface",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "shareRatioD18",
                type: "uint256",
            },
        ],
        name: "InvalidReferrerShareRatio",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "feeCollector",
                type: "address",
            },
        ],
        name: "FeeCollectorSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "minLiquidationRewardUsd",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "maxLiquidationRewardUsd",
                type: "uint256",
            },
        ],
        name: "LiquidationRewardGuardsSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "collateralAmount",
                type: "uint256",
            },
        ],
        name: "MaxCollateralAmountSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "referrer",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "shareRatioD18",
                type: "uint256",
            },
        ],
        name: "ReferrerShareUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint128[]",
                name: "newSynthDeductionPriority",
                type: "uint128[]",
            },
        ],
        name: "SynthDeductionPrioritySet",
        type: "event",
    },
    {
        inputs: [],
        name: "getFeeCollector",
        outputs: [
            {
                internalType: "address",
                name: "feeCollector",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getLiquidationRewardGuards",
        outputs: [
            {
                internalType: "uint256",
                name: "minLiquidationRewardUsd",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxLiquidationRewardUsd",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
        ],
        name: "getMaxCollateralAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "referrer",
                type: "address",
            },
        ],
        name: "getReferrerShare",
        outputs: [
            {
                internalType: "uint256",
                name: "shareRatioD18",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getSynthDeductionPriority",
        outputs: [
            {
                internalType: "uint128[]",
                name: "",
                type: "uint128[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "feeCollector",
                type: "address",
            },
        ],
        name: "setFeeCollector",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "minLiquidationRewardUsd",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxLiquidationRewardUsd",
                type: "uint256",
            },
        ],
        name: "setLiquidationRewardGuards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128",
                name: "synthMarketId",
                type: "uint128",
            },
            {
                internalType: "uint256",
                name: "collateralAmount",
                type: "uint256",
            },
        ],
        name: "setMaxCollateralAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint128[]",
                name: "newSynthDeductionPriority",
                type: "uint128[]",
            },
        ],
        name: "setSynthDeductionPriority",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "totalGlobalCollateralValue",
        outputs: [
            {
                internalType: "uint256",
                name: "totalCollateralValue",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "referrer",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "shareRatioD18",
                type: "uint256",
            },
        ],
        name: "updateReferrerShare",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
export class PerpsV3MarketProxy__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
PerpsV3MarketProxy__factory.abi = _abi;
