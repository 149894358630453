var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as sdkErrors from '../common/errors';
import { encodeReferralCode, getAffiliateRewardsByAccount, getReferralStatisticsByAccount, getReferralsGqlEndpoint, getTradeGqlEndpoint, getTradingDataByAccount, getClaimHistoriesByAccount, } from '../utils';
export default class ReferralService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    get referralGqlEndpoint() {
        const { networkId } = this.sdk.context;
        return getReferralsGqlEndpoint(networkId);
    }
    get tradeGqlEndpoint() {
        const { networkId } = this.sdk.context;
        return getTradeGqlEndpoint(networkId);
    }
    getReferralCodeOwner(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!code)
                return;
            const { ReferralStorage } = this.sdk.context.contracts;
            const codeByte32 = encodeReferralCode(code);
            if (!ReferralStorage) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const owner = yield ReferralStorage.codeOwners(codeByte32);
            return owner;
        });
    }
    registerReferralCode(code) {
        const { ReferralStorage } = this.sdk.context.contracts;
        const codeByte32 = encodeReferralCode(code);
        if (!ReferralStorage) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(ReferralStorage, 'registerCode', [codeByte32]);
    }
    setTraderReferralCodeByUser(code) {
        const { ReferralStorage } = this.sdk.context.contracts;
        const codeByte32 = encodeReferralCode(code);
        if (!ReferralStorage) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(ReferralStorage, 'setTraderReferralCodeByUser', [
            codeByte32,
        ]);
    }
    getReferralsData(account) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.ReferralStorage) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return getReferralStatisticsByAccount(this.sdk, account);
        });
    }
    getTradingDataForAccount(account, start, end) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.ReferralStorage) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return getTradingDataByAccount(this.sdk, account, start, end);
        });
    }
    getAffiliateUSDTEarned(account, epochPeriod) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorReferral } = this.sdk.context.multicallContracts;
            if (!MultipleMerkleDistributorReferral) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return getAffiliateRewardsByAccount(this.sdk, account, epochPeriod);
        });
    }
    claimAffiliateRewards(claimableRewards) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorReferral } = this.sdk.context.contracts;
            if (!MultipleMerkleDistributorReferral) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(MultipleMerkleDistributorReferral, 'claimMultiple', [claimableRewards]);
        });
    }
    getClaimHistories(account) {
        return __awaiter(this, void 0, void 0, function* () {
            return getClaimHistoriesByAccount(this.sdk, account);
        });
    }
}
