import { useState, useEffect } from 'react'

export const Timer = ({ validUntil }: { validUntil: string }) => {
	const now = Math.floor(Date.now() / 1000)

	const [timeLeft, setTimeLeft] = useState(0)

	useEffect(() => {
		if (validUntil === '0' || Number(validUntil) < now) return
		setTimeLeft(Number(validUntil) - now)
	}, [validUntil, now])

	useEffect(() => {
		if (!timeLeft) return

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1)
		}, 1000)

		return () => clearInterval(intervalId)
	}, [timeLeft])

	const hours = Math.floor((timeLeft - 1) / 3600)
	const minutes = Math.floor(timeLeft / 60)
	const seconds = Math.floor(timeLeft % 60)
	const formatedHours = hours < 10 ? '0' + hours : hours
	const formatedMinutes = minutes < 10 ? '0' + minutes : minutes
	const formatedSeconds = seconds < 10 ? '0' + seconds : seconds

	return validUntil !== '0' && Number(validUntil) > now
		? formatedHours + ':' + formatedMinutes + ':' + formatedSeconds
		: '00:00:00'
}
