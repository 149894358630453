import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { formatCurrency, getDisplayAsset } from '@dextoroprotocol/sdk/utils'
import { FuturesMarketAsset, FuturesMarketKey, PositionSide } from '@dextoroprotocol/sdk/types'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import Wei from '@synthetixio/wei'

import { FlexDivCentered } from 'components/layout/flex'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import PositionType from 'sections/futures/PositionType'
import {
	Icon_LONG,
	Icon_SHORT,
	MessageTitle,
	PositionDetail,
	Ticker,
} from 'sections/shared/Layout/AppLayout/Header/Notifications'
import { Container, NotificationContent } from 'constants/NotificationContainer'

type tnsDetailsProps = {
	key: FuturesMarketKey
	asset: FuturesMarketAsset
	positionType: PositionSide
	price: string
	blockExplorerLink?: string
	size: Wei
}

function ToastContent({ message, tnsDetails }: { message: string; tnsDetails?: tnsDetailsProps }) {
	return (
		<Container onClick={() => window.open(tnsDetails?.blockExplorerLink, '_blank')}>
			<NotificationContent>
				<MessageTitle style={{ textTransform: 'capitalize' }}>
					<FlexDivCentered>
						<StyledCurrencyIcon currencyKey={tnsDetails?.key ?? ''} width={25} height={25} />
						{message}
					</FlexDivCentered>
					<OrderTypeBox>
						Filled
						{tnsDetails?.positionType === PositionSide.SHORT ? <Icon_SHORT /> : <Icon_LONG />}
					</OrderTypeBox>
				</MessageTitle>
				<PositionDetail>
					<Key>
						Size
						<PositionType
							side={
								tnsDetails?.positionType === PositionSide.LONG
									? PositionSide.LONG
									: PositionSide.SHORT
							}
						/>
					</Key>
					<Value>
						{formatCurrency(
							getDisplayAsset(tnsDetails?.key ?? '') || '',
							tnsDetails?.size.abs() ?? ZERO_WEI
						)}
						<Ticker>{getDisplayAsset(tnsDetails?.asset ?? '')}</Ticker>
					</Value>
				</PositionDetail>
				<PositionDetail>
					<Key>Price</Key>
					<Value>{tnsDetails?.price}</Value>
				</PositionDetail>
			</NotificationContent>
		</Container>
	)
}

export const notifyMarketOrder = (message: string, tnsDetails?: tnsDetailsProps) => {
	// const truncated = truncateString(message)
	toast.info(<ToastContent message={message} tnsDetails={tnsDetails} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'notifications',
	})
}

const StyledCurrencyIcon = styled(CurrencyIcon)`
	margin-left: -2px;
	margin-right: 4px;
`

const Key = styled.span`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	gap: 6px;
`

const Value = styled.span`
	display: flex;
	align-items: center;
	text-transform: capitalize;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	gap: 6px;
`

const OrderTypeBox = styled.div`
	display: flex;
	align-items: center;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`
