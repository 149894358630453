import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useState, FC, useCallback, useMemo } from 'react'
import { truncateAddress, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'
import media from 'styles/media'

import LinkIcon from 'assets/svg/app/link-new.svg'
import { Body } from 'components/Text'
import Button from 'components/Button'
import Select, { DropdownIndicator, IndicatorSeparator } from 'components/Select'
import { EXTERNAL_LINKS } from 'constants/links'
import ProgressSteps from 'components/ProgressSteps'
import { notifyError } from 'components/ErrorNotifier'
import { DEFAULT_TX_FEE } from 'constants/defaults'
import { durationOptions } from 'constants/oneclick'
import { Timer } from 'components/Timer'
import Loader from 'components/Loader'
import { selectCurrentTheme } from 'state/preferences/selectors'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectSessionActivated,
	selectSessionValidUntil,
	selectSmartAccountAddress,
	selectSmartAccountBalance,
} from 'state/oneClickTrading/selectors'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import {
	setSessionActivated,
	setSessionValidUntil,
	setUsingBiconomy,
} from 'state/oneClickTrading/reducer'
import { activeSession } from 'state/oneClickTrading/helper'
import { monitorTransaction } from 'contexts/RelayerContext'
import { FetchStatus } from 'state/types'
import useWindowSize from 'hooks/useWindowSize'

import { ManageProps, OneClickTradingTabs } from './types'
import { Step } from 'sections/app/Walkthrough'
import { useWalkthroughContext } from 'contexts/WalkthroughContext'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'
import { css } from 'styled-components'

const ManageSession: FC<ManageProps> = ({ onChangeTab, isModal, isMenu, onDismiss }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const isSessionActivated = useAppSelector(selectSessionActivated)
	const sessionValidUntil = useAppSelector(selectSessionValidUntil)
	const { setCurrentStep } = useWalkthroughContext()
	const { deviceType } = useWindowSize()
	const currentTheme = useAppSelector(selectCurrentTheme)

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const isLowBalance = wei(smartAccountBalance).lt(DEFAULT_TX_FEE)
	const estimatedTxCount = Math.floor(wei(smartAccountBalance).div(DEFAULT_TX_FEE).toNumber())
	const handleManage = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('oneclick-trading'))
		setCurrentStep(Step.OneClickTrading)
	}
	const [duration, setDuration] = useState({ label: '1H', value: 1 })
	const [processing, setProcessing] = useState(false)

	const createSession = async () => {
		setProcessing(true)
		try {
			if (smartMarginAccount) {
				const hash = await activeSession(
					smartMarginAccount,
					isSessionActivated ? 0 : duration.value
				)
				monitorTransaction({
					txHash: hash,
					onTxConfirmed: () => {
						isSessionActivated
							? dispatch(setSessionActivated(false))
							: dispatch(setSessionActivated(true))
						isSessionActivated
							? dispatch(setUsingBiconomy(false))
							: dispatch(setUsingBiconomy(true))
						const now = Math.floor(Date.now() / 1000)
						// if session already actived, inactive session by setting validUntil now
						const validUntil = isSessionActivated ? now : now + duration.value * 3600
						dispatch(setSessionValidUntil(validUntil))
					},
					onTxFailed: () => {
						dispatch({ type: 'oneClick/activeSession', payload: FetchStatus.Error })
					},
				})
			}
		} catch (err: any) {
			const error: any = {
				message: 'Error: One-Click Trading balance is low. Please, fund your wallet and try again!',
			}
			if (err.code !== 4001) notifyError('Transaction failed', error)
			throw err
		} finally {
			setProcessing(false)
		}
	}

	const handleSession = async () => {
		await createSession()
	}

	const getText = () => {
		if (isSessionActivated) {
			if (processing) return <Loader />
			if (isModal || isMenu) return t('dashboard.oneclick.manage.stop-session')
			return t('dashboard.oneclick.manage.disable')
		}
		if (processing) return <Loader />
		if (isModal || isMenu) return t('dashboard.oneclick.manage.start-session')
		return t('dashboard.oneclick.manage.enable')
	}

	const formatOptionLabel = useCallback(
		(option: { label: string; value: number }) => (
			<SelectLabel onClick={() => setDuration(option)}>
				<Body fontSize={14}>{option.label}</Body>
			</SelectLabel>
		),
		[]
	)

	return (
		<Container>
			<StyledText isMenu={isMenu ? true : false}>
				{isMenu
					? t('dashboard.oneclick.manage.manage-session')
					: t('dashboard.oneclick.manage.current-session')}
			</StyledText>
			{isModal && (
				<>
					<Body fontSize={13} color="secondary">
						{t('dashboard.oneclick.manage.duration')}
					</Body>
					<DurationContainer>
						{durationOptions.map((dur, index) => (
							<div
								key={index}
								className={`${duration.value === dur.value ? 'active' : ''}`}
								onClick={() => setDuration(dur)}
							>
								{dur.label}
							</div>
						))}
					</DurationContainer>
				</>
			)}
			{isMenu ? (
				<MenuCard>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.status')}</StyledLabeltext>
						<StatusDiv fontSize={14} active={isSessionActivated}>
							<div></div>
							<StyledValueSpan>{isSessionActivated ? 'Active' : 'Inactive'}</StyledValueSpan>
						</StatusDiv>
					</div>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.address')}</StyledLabeltext>
						<Link
							href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
							target="_blank"
						>
							<StyledBody fontSize={14}>
								<StyledValueSpan>{truncateAddress(smartAccountAddress)}</StyledValueSpan>
								<LinkIcon />
							</StyledBody>
						</Link>
					</div>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.eth-balance')}</StyledLabeltext>
						<StyledBody fontSize={14}>
							<StyledValueSpan>{Number(truncateNumbers(smartAccountBalance, 5))}</StyledValueSpan>
							{!isModal && !isMenu && (
								<LinkButton fontSize={11} onClick={onChangeTab?.(OneClickTradingTabs.Fund)}>
									{t('dashboard.oneclick.manage.fund')}
								</LinkButton>
							)}
						</StyledBody>
					</div>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.expires-in')}</StyledLabeltext>
						<StyledValuetext>
							<Timer validUntil={sessionValidUntil} />
						</StyledValuetext>
					</div>
				</MenuCard>
			) : (
				<Card isModal={isModal} mobile={isMobile}>
					{!isModal && (
						<>
							<div>
								<StyledLabeltext>{t('dashboard.oneclick.manage.status')}</StyledLabeltext>
								<StatusDiv fontSize={14} active={isSessionActivated}>
									<div></div>
									<StyledValueSpan>{isSessionActivated ? 'Active' : 'Inactive'}</StyledValueSpan>
								</StatusDiv>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<StyledLabeltext>{t('dashboard.oneclick.manage.duration')}</StyledLabeltext>
								<DurationSelect
									formatOptionLabel={formatOptionLabel}
									controlHeight={27}
									options={durationOptions}
									optionPadding="2px"
									value={duration}
									menuWidth={75}
									components={{ IndicatorSeparator, DropdownIndicator }}
									isSearchable={false}
									variant="flat"
									isDisabled={isSessionActivated}
								/>
							</div>
						</>
					)}
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.est-tx-nums')}</StyledLabeltext>
						<StyledValuetext>{estimatedTxCount}</StyledValuetext>
					</div>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.address')}</StyledLabeltext>
						<Link
							href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
							target="_blank"
						>
							<StyledBody fontSize={14}>
								<StyledValueSpan>{truncateAddress(smartAccountAddress)}</StyledValueSpan>
								<LinkIcon />
							</StyledBody>
						</Link>
					</div>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.eth-balance')}</StyledLabeltext>
						<StyledBody fontSize={14}>
							<StyledValueSpan>{Number(truncateNumbers(smartAccountBalance, 5))}</StyledValueSpan>
							{!isModal && !isMenu && (
								<LinkButton fontSize={12} onClick={onChangeTab?.(OneClickTradingTabs.Fund)}>
									{t('dashboard.oneclick.manage.fund')}
								</LinkButton>
							)}
						</StyledBody>
					</div>
					<div>
						<StyledLabeltext>{t('dashboard.oneclick.manage.expires-in')}</StyledLabeltext>
						<StyledValuetext>
							<Timer validUntil={sessionValidUntil} />
						</StyledValuetext>
					</div>
				</Card>
			)}
			{isModal && <ProgressSteps step={3} totalSteps={3} complete={false} />}
			{isMenu && (
				<TabButtonsContainer>
					{durationOptions.map((tab, index) => {
						return (
							<StyledTabButton
								key={index}
								active={duration.value === tab.value}
								onClick={() => setDuration(tab)}
							>
								{tab.label}
							</StyledTabButton>
						)
					})}
				</TabButtonsContainer>
			)}
			{isMenu && isLowBalance && (
				<LowBalance color="secondary">{t('dashboard.oneclick.manage.low-balance')}</LowBalance>
			)}

			<StyledDiv>
				{isMenu && !isLowBalance && (
					<ManageButtonStyle
						currentTheme={currentTheme}
						onClick={() => {
							if (onDismiss) onDismiss()
							handleManage()
						}}
					>
						Manage 1CT
					</ManageButtonStyle>
				)}
				{!(isMenu && isLowBalance) && (
					<StyledButton
						fullWidth
						onClick={handleSession}
						disabled={processing}
						isLoading={processing}
					>
						{getText()}
					</StyledButton>
				)}
			</StyledDiv>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const DurationContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 4px;

	& > div {
		width: 100%;
		color: ${(props) => props.theme.colors.selectedTheme.primary};
		font-size: 13px;
		border-right: ${(props) => props.theme.colors.selectedTheme.border};
		padding: 2px;
		text-align: center;
		cursor: pointer;
		user-select: none;
	}

	& > div.active {
		background: ${(props) => props.theme.colors.selectedTheme.table.fill};
		border-radius: 4px;
	}
`

const Card = styled.div<{ isModal?: boolean; mobile?: boolean }>`
	& > div {
		width: ${(props) => (props.isModal || props.mobile ? '50%' : '33.33%')};
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: ${(props) => props.theme.colors.selectedTheme.modal.backgroundColor.dark};
	border-radius: 12px;
	padding: 12px;
	border: none;
	width: 100%;
`

const StyledLabeltext = styled.p`
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-weight: 400;
	font-size: 13px;
	margin: 0px;
`
const StyledValuetext = styled.p`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-size: 14px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-feature-settings: 'zero' 0;
	margin: 0px;
`
const StyledValueSpan = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	font-size: 14px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-feature-settings: 'zero' 0;
	margin: 0px;
	line-height: normal;
`

const DurationSelect = styled(Select)`
	.react-select__control {
		background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
		border-radius: 10px;
		border-width: 0px;
		width: 75px;
		// position: absolute;
	}

	.react-select__menu,
	.react-select__menu-list {
		background-color: ${({ theme }) => theme.colors.selectedTheme.modal.input.background};
		border-width: 0px;
		position: absolute;
		left: 0;
		top: 10px;
		width: 80px;
	}

	.react-select__value-container,
	.react-select__indicator {
		padding: 0;
	}
	.react-select__indicator path {
		fill: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	}
	.react-select__single-value > div > div {
		font-size: 12px;
		color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
	}

	.react-select__dropdown-indicator {
		margin-right: 10px;
	}
`

const SelectLabel = styled.div`
	padding-left: 10px;
`

const StyledBody = styled(Body)`
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		width: 16px;
		height: 16px;
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
		}
	}
`

const LinkButton = styled(Button)`
	height: 20px;
	border-radius: 4px;
	background: ${(props) => props.theme.colors.selectedTheme.modal.button.background.active};
	color: ${(props) => props.theme.colors.selectedTheme.modal.button.text};
	padding: 2px 4px;
`

const MenuCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: ${(props) => props.theme.colors.selectedTheme.modal.backgroundColor.dark};
	border-radius: 12px;
	padding: 12px;
	border: none;
	width: 400px;

	${media.lessThan('md')`
	width: 100%;
	`}

	& > div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`

const LowBalance = styled(Body)`
	color: ${(props) => props.theme.colors.selectedTheme.modal.manage.tabText};
	background-color: ${(props) => props.theme.colors.selectedTheme.modal.manage.buttonFill};
	width: 100%;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 13px;
`

const StatusDiv = styled(Body)<{ active: boolean }>`
	display: flex;
	align-items: center;
	gap: 8px;

	& > div {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: ${({ theme, active }) =>
			active ? theme.colors.selectedTheme.green : theme.colors.selectedTheme.red};
	}
`

const StyledDiv = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
	// margin-top: 16px;
`
const ManageButtonStyle = styled(Button)<{ currentTheme: string }>`
	width: 100%;
	height: 40px;
	padding: 14px 26px;
	border: none;
	background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.2)' : 'brightness(0.9)')};
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`
const StyledText = styled.p<{ isMenu: boolean }>`
	font-size: ${(props) => (props.isMenu ? '20px' : '14px')};
	font-weight: 400;
	font-feature-settings: 'zero' 0;
	color: ${(props) =>
		props.isMenu
			? props.theme.colors.selectedTheme.newTheme.text.primary
			: props.theme.colors.selectedTheme.modal.text.primary};
	margin: 0px;
	margin-bottom: ${(props) => (props.isMenu ? '0px' : '-8px')};
`
export const StyledButton = styled(Button)<{ isLoading?: boolean }>`
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	${(props) =>
		props.disabled
			? css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.disabled} !important;
					border: 0 !important;
					color: #ffffff80 !important;
			  `
			: css`
					background-color: ${({ theme }) =>
						theme.colors.selectedTheme.modal.button.background.active} !important;
					border: 0 !important;
					color: #ffffff !important;
			  `};
	${(props) =>
		props.isLoading &&
		css`
			background-color: transparent !important;
			border: ${(props) => props.theme.colors.selectedTheme.border}!important;
		`};
`

const TabButtonsContainer = styled.div`
	height: 40px;
	width: 100%;
	display: flex;
	gap: 4px;
	background-color: ${({ theme }) => theme.colors.selectedTheme.modal.manage.oneClick.tabFill};
	padding: 4px;
	border-radius: 10px;
`

const StyledTabButton = styled.button<{ active?: boolean; currentTheme?: string }>`
	width: 100%;
	padding: 6px 12px;
	font-size: 13px;
	background-color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.modal.manage.oneClick.buttonFill
			: 'transparent'};
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.modal.manage.tabText
			: props.theme.colors.selectedTheme.modal.manage.disabledTabText};
	font-family: Relative-Medium-Pro;
	font-weight: 500;

	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
`

export default ManageSession
