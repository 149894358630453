import { BigNumber } from 'ethers'

export const GWEI_UNIT = 1000000000
export const GWEI_DECIMALS = 9
export const ETH_UNIT = 1000000000000000000
export const STALL_TIMEOUT = 5000
export type GasLimitEstimate = BigNumber | null

export const SUPPORTED_NETWORKS = [
	1, // Ethereum (mainnet)
	5, // Goerli (testnet)
	10, // Optimism (mainnet)
	420, // Optimism Goerli (testnet)
]

export enum AlchemyNetwork {
	ETHEREUM_MAINNET = 'eth-mainnet',
	OPTIMISM_MAINNET = 'opt-mainnet',
	ETHEREUM_GOERLI = 'eth-goerli',
	OPTIMISM_GOERLI = 'optimism-goerli',
}

export const ALCHEMY_NETWORK_LOOKUP: Record<number, AlchemyNetwork> = {
	1: AlchemyNetwork.ETHEREUM_MAINNET,
	5: AlchemyNetwork.ETHEREUM_GOERLI,
	10: AlchemyNetwork.OPTIMISM_MAINNET,
	420: AlchemyNetwork.OPTIMISM_GOERLI,
}
