import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from 'state/types'

import {
	claimMultipleDextoroRewards,
	claimMultipleAllRewards,
	// claimMultipleOpRewards,
	// claimMultipleSnxOpRewards,
	fetchClaimableRewards,
	fetchEscrowData,
	fetchStakingData,
	claimStakingRewards,
	stakeEscrow,
	stakeDextoro,
	unstakeEscrow,
	unstakeDextoro,
	vestEscrowedRewards,
	fetchStakingV2Data,
	fetchEscrowV2Data,
	fetchEstimatedRewards,
	vestEscrowedRewardsV2,
	stakeDextoroV2,
	unstakeDextoroV2,
	unstakeEscrowV2,
	stakeEscrowV2,
	claimStakingRewardsV2,
	approveDextoroToken,
	compoundRewards,
} from './actions'
import { StakingState } from './types'

const ZERO_STAKE_BALANCE = {
	escrowedDextoroBalance: '0',
	stakedDextoroBalance: '0',
	totalStakedBalance: '0',
	claimableBalance: '0',
	stakedEscrowedDextoroBalance: '0',
}

export const ZERO_ESCROW_BALANCE = {
	totalVestable: '0',
	escrowData: [],
}

const ZERO_VERSIONED_STAKE_DATA = {
	...ZERO_STAKE_BALANCE,
	...ZERO_ESCROW_BALANCE,
}

const INITIAL_STAKE_INFO = {
	dextoroBalance: '0',
	dextoroAllowance: '0',
	epochPeriod: 0,
	weekCounter: 1,
}

const INITIAL_STAKE_V2_INFO = {
	stakedResetTime: 0,
	dextoroStakingV2Allowance: '0',
}

export const ZERO_ESTIMATED_REWARDS = {
	estimatedDextoroRewards: '0',
	estimatedOpRewards: '0',
}

export const ZERO_STAKING_DATA = {
	...ZERO_STAKE_BALANCE,
	...INITIAL_STAKE_INFO,
}

export const ZERO_STAKING_V2_DATA = {
	...ZERO_STAKE_BALANCE,
	...INITIAL_STAKE_V2_INFO,
}

export const ZERO_CLAIMABLE_REWARDS = {
	dextoroRewards: '0',
	opRewards: '0',
	snxOpRewards: '0',
	claimableDextoroRewards: [],
	claimableDextoroRewardsV2: [],
	claimableOpRewards: [],
	claimableSnxOpRewards: [],
	periodsRewards: [],
}

export const STAKING_INITIAL_STATE: StakingState = {
	v1: {
		...ZERO_VERSIONED_STAKE_DATA,
	},
	v2: {
		...ZERO_VERSIONED_STAKE_DATA,
	},
	...INITIAL_STAKE_INFO,
	...INITIAL_STAKE_V2_INFO,
	...ZERO_ESTIMATED_REWARDS,
	...ZERO_CLAIMABLE_REWARDS,
	selectedEscrowVersion: 1,
	stakingMigrationCompleted: true,
	stakeStatus: FetchStatus.Idle,
	unstakeStatus: FetchStatus.Idle,
	stakeEscrowedStatus: FetchStatus.Idle,
	unstakeEscrowedStatus: FetchStatus.Idle,
	getRewardStatus: FetchStatus.Idle,
	claimDextoroRewardsStatus: FetchStatus.Idle,
	claimOpRewardsStatus: FetchStatus.Idle,
	claimSnxOpRewardsStatus: FetchStatus.Idle,
	claimAllRewardsStatus: FetchStatus.Idle,
	vestEscrowedRewardsStatus: FetchStatus.Idle,
	approveDextoroStatus: FetchStatus.Idle,
	compoundRewardsStatus: FetchStatus.Idle,
}

const stakingSlice = createSlice({
	name: 'staking',
	initialState: STAKING_INITIAL_STATE,
	reducers: {
		setStakeStatus: (state, action) => {
			state.stakeStatus = action.payload
		},
		setUnstakeStatus: (state, action) => {
			state.unstakeStatus = action.payload
		},
		setStakeEscrowedStatus: (state, action) => {
			state.stakeEscrowedStatus = action.payload
		},
		setUnstakeEscrowedStatus: (state, action) => {
			state.unstakeEscrowedStatus = action.payload
		},
		setGetRewardStatus: (state, action) => {
			state.getRewardStatus = action.payload
		},
		setClaimAllRewardsStatus: (state, action) => {
			state.claimAllRewardsStatus = action.payload
		},
		setClaimDextoroRewardsStatus: (state, action) => {
			state.claimDextoroRewardsStatus = action.payload
		},
		setClaimOpRewardsStatus: (state, action) => {
			state.claimOpRewardsStatus = action.payload
		},
		setClaimSnxOpRewardsStatus: (state, action) => {
			state.claimSnxOpRewardsStatus = action.payload
		},
		setVestEscrowedRewardsStatus: (state, action) => {
			state.vestEscrowedRewardsStatus = action.payload
		},
		setApproveDextoroStatus: (state, action) => {
			state.approveDextoroStatus = action.payload
		},
		setCompoundRewardsStatus: (state, action) => {
			state.compoundRewardsStatus = action.payload
		},
		setSelectedEpoch: (state, action) => {
			state.selectedEpoch = action.payload
		},
		setStakingMigrationCompleted: (state, action) => {
			state.stakingMigrationCompleted = action.payload
		},
		setSelectedEscrowVersion: (state, action) => {
			state.selectedEscrowVersion = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchStakingData.fulfilled, (state, action) => {
			state.v1.escrowedDextoroBalance = action.payload.escrowedDextoroBalance
			state.v1.stakedDextoroBalance = action.payload.stakedDextoroBalance
			state.v1.stakedEscrowedDextoroBalance = action.payload.stakedEscrowedDextoroBalance
			state.v1.claimableBalance = action.payload.claimableBalance
			state.v1.totalStakedBalance = action.payload.totalStakedBalance
			state.dextoroBalance = action.payload.dextoroBalance
			state.weekCounter = action.payload.weekCounter
			state.dextoroAllowance = action.payload.dextoroAllowance
			state.epochPeriod = action.payload.epochPeriod
			state.stakeStatus = FetchStatus.Idle
			state.unstakeStatus = FetchStatus.Idle
			state.stakeEscrowedStatus = FetchStatus.Idle
			state.unstakeEscrowedStatus = FetchStatus.Idle
			state.vestEscrowedRewardsStatus = FetchStatus.Idle
			state.getRewardStatus = FetchStatus.Idle
			state.compoundRewardsStatus = FetchStatus.Idle
			state.approveDextoroStatus = FetchStatus.Idle
		})
		builder.addCase(fetchStakingV2Data.fulfilled, (state, action) => {
			state.v2.escrowedDextoroBalance = action.payload.escrowedDextoroBalance
			state.v2.stakedDextoroBalance = action.payload.stakedDextoroBalance
			state.v2.stakedEscrowedDextoroBalance = action.payload.stakedEscrowedDextoroBalance
			state.v2.claimableBalance = action.payload.claimableBalance
			state.v2.totalStakedBalance = action.payload.totalStakedBalance
			state.dextoroStakingV2Allowance = action.payload.dextoroStakingV2Allowance
			state.stakedResetTime = action.payload.stakedResetTime
		})
		builder.addCase(fetchEscrowData.fulfilled, (state, action) => {
			state.v1.totalVestable = action.payload.totalVestable
			state.v1.escrowData = action.payload.escrowData
		})
		builder.addCase(fetchEscrowV2Data.fulfilled, (state, action) => {
			state.v2.totalVestable = action.payload.totalVestable
			state.v2.escrowData = action.payload.escrowData
		})
		builder.addCase(fetchClaimableRewards.fulfilled, (state, action) => {
			// state.claimableDextoroRewards = action.payload.claimableDextoroRewards
			state.claimableDextoroRewardsV2 = action.payload.claimableDextoroRewardsV2
			// state.claimableOpRewards = action.payload.claimableOpRewards
			// state.claimableSnxOpRewards = action.payload.claimableSnxOpRewards
			state.dextoroRewards = action.payload.dextoroRewards
			// state.opRewards = action.payload.opRewards
			// state.snxOpRewards = action.payload.snxOpRewards
			state.periodsRewards = action.payload.periodsRewards
			state.claimDextoroRewardsStatus = FetchStatus.Idle
			state.claimAllRewardsStatus = FetchStatus.Idle
			state.claimOpRewardsStatus = FetchStatus.Idle
			state.claimSnxOpRewardsStatus = FetchStatus.Idle
		})
		builder.addCase(fetchEstimatedRewards.fulfilled, (state, action) => {
			state.estimatedDextoroRewards = action.payload.estimatedDextoroRewards
			state.estimatedOpRewards = action.payload.estimatedOpRewards
		})
		builder.addCase(approveDextoroToken.pending, (state) => {
			state.approveDextoroStatus = FetchStatus.Loading
		})
		builder.addCase(approveDextoroToken.rejected, (state) => {
			state.approveDextoroStatus = FetchStatus.Error
		})
		builder.addCase(compoundRewards.pending, (state) => {
			state.compoundRewardsStatus = FetchStatus.Loading
		})
		builder.addCase(compoundRewards.rejected, (state) => {
			state.compoundRewardsStatus = FetchStatus.Error
		})
		builder.addCase(stakeDextoro.pending, (state) => {
			state.stakeStatus = FetchStatus.Loading
		})
		builder.addCase(stakeDextoro.rejected, (state) => {
			state.stakeStatus = FetchStatus.Error
		})
		builder.addCase(unstakeDextoro.pending, (state) => {
			state.unstakeStatus = FetchStatus.Loading
		})
		builder.addCase(unstakeDextoro.rejected, (state) => {
			state.unstakeStatus = FetchStatus.Error
		})
		builder.addCase(stakeEscrow.pending, (state) => {
			state.stakeEscrowedStatus = FetchStatus.Loading
		})
		builder.addCase(stakeEscrow.rejected, (state) => {
			state.stakeEscrowedStatus = FetchStatus.Error
		})
		builder.addCase(unstakeEscrow.pending, (state) => {
			state.unstakeEscrowedStatus = FetchStatus.Loading
		})
		builder.addCase(unstakeEscrow.rejected, (state) => {
			state.unstakeEscrowedStatus = FetchStatus.Error
		})
		builder.addCase(claimStakingRewards.pending, (state) => {
			state.getRewardStatus = FetchStatus.Loading
		})
		builder.addCase(claimStakingRewards.rejected, (state) => {
			state.getRewardStatus = FetchStatus.Error
		})
		builder.addCase(claimMultipleAllRewards.pending, (state) => {
			state.claimAllRewardsStatus = FetchStatus.Loading
		})
		builder.addCase(claimMultipleAllRewards.rejected, (state) => {
			state.claimAllRewardsStatus = FetchStatus.Error
		})
		builder.addCase(claimMultipleDextoroRewards.pending, (state) => {
			state.claimDextoroRewardsStatus = FetchStatus.Loading
		})
		// builder.addCase(claimMultipleOpRewards.pending, (state) => {
		// 	state.claimOpRewardsStatus = FetchStatus.Loading
		// })
		// builder.addCase(claimMultipleSnxOpRewards.pending, (state) => {
		// 	state.claimSnxOpRewardsStatus = FetchStatus.Loading
		// })
		builder.addCase(vestEscrowedRewards.pending, (state) => {
			state.vestEscrowedRewardsStatus = FetchStatus.Loading
		})
		builder.addCase(vestEscrowedRewards.rejected, (state) => {
			state.vestEscrowedRewardsStatus = FetchStatus.Error
		})
		builder.addCase(vestEscrowedRewardsV2.pending, (state) => {
			state.vestEscrowedRewardsStatus = FetchStatus.Loading
		})
		builder.addCase(vestEscrowedRewardsV2.rejected, (state) => {
			state.vestEscrowedRewardsStatus = FetchStatus.Error
		})
		builder.addCase(stakeDextoroV2.pending, (state) => {
			state.stakeStatus = FetchStatus.Loading
		})
		builder.addCase(stakeDextoroV2.rejected, (state) => {
			state.stakeStatus = FetchStatus.Error
		})
		builder.addCase(unstakeDextoroV2.pending, (state) => {
			state.unstakeStatus = FetchStatus.Loading
		})
		builder.addCase(unstakeDextoroV2.rejected, (state) => {
			state.unstakeStatus = FetchStatus.Error
		})
		builder.addCase(stakeEscrowV2.pending, (state) => {
			state.stakeEscrowedStatus = FetchStatus.Loading
		})
		builder.addCase(stakeEscrowV2.rejected, (state) => {
			state.stakeEscrowedStatus = FetchStatus.Error
		})
		builder.addCase(unstakeEscrowV2.pending, (state) => {
			state.unstakeEscrowedStatus = FetchStatus.Loading
		})
		builder.addCase(unstakeEscrowV2.rejected, (state) => {
			state.unstakeEscrowedStatus = FetchStatus.Error
		})
		builder.addCase(claimStakingRewardsV2.pending, (state) => {
			state.getRewardStatus = FetchStatus.Loading
		})
		builder.addCase(claimStakingRewardsV2.rejected, (state) => {
			state.getRewardStatus = FetchStatus.Error
		})
	},
})

export default stakingSlice.reducer
export const { setSelectedEpoch, setStakingMigrationCompleted, setSelectedEscrowVersion } =
	stakingSlice.actions
