import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { UserData } from './types'
import logError from 'utils/logError'
export const USER_DATA_URI = `${process.env.NEXT_PUBLIC_STAKING_API_URL}/userData`

// Save user data (POST request)
export const saveUserData = createAsyncThunk(
	'userData/saveUserData',
	async (newUserData: UserData) => {
		try {
			const response = await axios.post(`${USER_DATA_URI}`, newUserData)
			return response.data // Return the user data
		} catch (error) {
			logError(error)
		}
	}
)
