import React from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from 'assets/svg/app/spinner.svg'
import SuccessIcon from 'assets/svg/app/success-new.svg'
import {
	Container,
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'

type NotificationProps = {
	closeToast?: Function
	failureReason?: string
}

const NotificationPending = () => {
	const { t } = useTranslation()

	return (
		<Container>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv type="pending">
						<Spinner />
					</NotificationIconDiv>
					{t('common.transaction.transaction-sent')}
				</NotificationTitle>
			</NotificationContent>
		</Container>
	)
}

const NotificationSuccess = () => {
	const { t } = useTranslation()

	return (
		<Container>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv type="success">
						<SuccessIcon />
					</NotificationIconDiv>
					{t('common.transaction.transaction-confirmed')}
				</NotificationTitle>
			</NotificationContent>
		</Container>
	)
}

const NotificationError = ({ failureReason }: NotificationProps) => {
	const { t } = useTranslation()

	return (
		<Container>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv type="error">
						<Spinner />
					</NotificationIconDiv>
					{t('common.transaction.transaction-failed')}
				</NotificationTitle>
				<NotificationDetails text={failureReason ?? ''} />
			</NotificationContent>
		</Container>
	)
}

export { NotificationPending, NotificationSuccess, NotificationError }
