import { createSelector } from '@reduxjs/toolkit'
import { wei } from '@synthetixio/wei'

import { RootState } from 'state/store'

export const selectReferralSupportedNetwork = (state: RootState) => state.wallet.networkId === 10

export const selectReferralsData = (state: RootState) => state.referrals

export const selectTraderReferralCodeUpdated = (state: RootState) =>
	state.referrals.traderReferralCodeUpdated

export const selectReferralsTradingData = (state: RootState) => state.referrals.tradingData

export const selectAffiliateReward = createSelector(
	(state: RootState) => state.referrals.affiliateTotalRewards,
	wei
)

export const selectAffiliateClaimableRewards = (state: RootState) =>
	state.referrals.affiliateClaimableRewards

export const selectClaimHistories = (state: RootState) => state.referrals.claimHistories
