import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { wei } from '@synthetixio/wei'

import CheckedIcon from 'assets/svg/app/checked.svg'
import BaseModal from 'components/BaseModal'
import ProgressSteps from 'components/ProgressSteps'
import ManageActivate from 'sections/futures/ManageSmartMarginAccount/OneClickTrading/ManageActivate'
import Fund from 'sections/futures/ManageSmartMarginAccount/OneClickTrading/Fund'
import ManageSession from 'sections/futures/ManageSmartMarginAccount/OneClickTrading/ManageSession'
import Button from 'components/Button'

import { useAppSelector } from 'state/hooks'
import {
	selectDelegated,
	selectSessionActivated,
	selectSmartAccountBalance,
} from 'state/oneClickTrading/selectors'
import { DEFAULT_TX_FEE } from 'constants/defaults'

type OneClickTradingModalProps = {
	isOpenModal: boolean
	onDismiss: () => void
}

const OneClickTradingModal: React.FC<OneClickTradingModalProps> = ({ isOpenModal, onDismiss }) => {
	const { t } = useTranslation()
	const delegated = useAppSelector(selectDelegated)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const sessionActivated = useAppSelector(selectSessionActivated)
	const deposited = wei(smartAccountBalance).gte(DEFAULT_TX_FEE)

	return (
		<StyledBaseModal
			title={t('dashboard.oneclick.title')}
			isOpen={isOpenModal}
			onDismiss={onDismiss}
		>
			{!delegated && <ManageActivate isModal onDismiss={onDismiss} />}
			{delegated && !deposited && <Fund isModal />}
			{delegated && deposited && !sessionActivated && <ManageSession isModal />}
			{sessionActivated && (
				<CompletedContainer>
					<CheckedIcon />
					<ProgressSteps step={3} totalSteps={3} complete={true} />
					<Button onClick={onDismiss}>{t('dashboard.oneclick.manage.done')}</Button>
				</CompletedContainer>
			)}
		</StyledBaseModal>
	)
}

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		width: 500px;
	}
`

const CompletedContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 20px;

	svg {
		width: 50px;
		height: 50px;
		margin: 50px 0;
	}

	& > div {
		width: 100%;
	}

	button {
		width: 100%;
	}
`

export default OneClickTradingModal
