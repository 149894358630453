import { RootState } from 'state/store'

import { unserializeGasPrice } from './helpers'

export const selectShowModal = (state: RootState) => state.app.showModal

export const showDefaultTab = (state: RootState) => state.app.defaultTab

export const selectShowPositionModal = (state: RootState) => state.app.showPositionModal

export const selectGasSpeed = (state: RootState) => state.app.gasSpeed

export const selectGasPrice = (state: RootState) => unserializeGasPrice(state.app.gasPrice)

export const selectTransaction = (state: RootState) => state.app.transaction

export const selectAckedOrdersWarning = (state: RootState) => state.app.acknowledgedOrdersWarning

export const selectShowBanner = (state: RootState) => state.app.showBanner

export const selectShowWalkthrough = (state: RootState) => state.app.showWalkthrough

export const selectSettings = (state: RootState) => state.app.settings

export const selectMobileTradePanel = (state: RootState) => state.app.showMobileTradePanel

export const selectWithdrawModalTab = (state: RootState) => state.app.withdrawModalTab

export const selectNotification = (state: RootState) => state.app.showNotification
export const selectedPositionsData = (state: RootState) => state.app.selectedPositionsData

export const selectActiveFilter = (state: RootState) => state.app.activeFilter
export const selectSortMethod = (state: RootState) => state.app.sortMethod
export const selectOpDirection = (state: RootState) => state.app.opDirection
export const selectFrDirection = (state: RootState) => state.app.frDirection
export const selectPriceDirection = (state: RootState) => state.app.priceDirection
export const selectChangeDirection = (state: RootState) => state.app.changeDirection
export const selectVolumeDirection = (state: RootState) => state.app.volumeDirection
export const selectDetailsCard = (state: RootState) => state.app.detailsCard
