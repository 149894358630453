import { FC } from 'react'

import { useAppSelector } from 'state/hooks'
import { selectDelegated } from 'state/oneClickTrading/selectors'

import { ManageProps } from './types'
import ManageSession from './ManageSession'
import ManageActivate from './ManageActivate'

const Manage: FC<ManageProps> = ({ onChangeTab }) => {
	const delegated = useAppSelector(selectDelegated)

	return <>{delegated ? <ManageSession onChangeTab={onChangeTab} /> : <ManageActivate />}</>
}

export default Manage
