import { FC, ReactNode, memo } from 'react'
import styled, { css } from 'styled-components'

import { ButtonLoader } from 'components/Loader'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

// TODO: Clean up these styles
export type ButtonVariant =
	| 'primary'
	| 'secondary'
	| 'flat'
	| 'alt'
	| 'success'
	| 'danger'
	| 'text'
	| 'select'
	| 'yellow'
	| 'long'
	| 'short'
	| 'staking-button'

type BaseButtonProps = {
	$size: 'xsmall' | 'small' | 'medium' | 'large'
	$variant: ButtonVariant
	isActive?: boolean
	isRounded?: boolean
	fullWidth?: boolean
	noOutline?: boolean
	textColor?: 'yellow'
	outlineColor?: 'yellow'
	textTransform?: 'none' | 'uppercase' | 'capitalize' | 'lowercase'
	$active?: boolean
	$mono?: boolean
	$fontSize?: number
	$capitalized?: boolean
	$bold?: boolean
	currentTheme: string
}

export const border = css`
	background: ${(props) => props.theme.colors.selectedTheme.button.dextoroPrimary.background};
	border: none;
`

const sizeMap = {
	xsmall: {
		paddingVertical: 6,
		paddingHorizontal: 12,
		height: 32,
		fontSize: 12,
	},
	dextroSize: {
		paddingVertical: 6,
		paddingHorizontal: 12,
		height: 32,
		fontSize: 14,
	},
	small: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		height: 40,
		fontSize: 13,
	},
	medium: {
		paddingVertical: 14,
		paddingHorizontal: 26,
		height: 47,
		fontSize: 15,
	},
	large: {
		paddingVertical: 16,
		paddingHorizontal: 36,
		height: 55,
		fontSize: 16,
	},
} as const

const BaseButton = styled.button<BaseButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	cursor: pointer;
	position: relative;
	border-radius: ${(props) => (props.isRounded ? '10px' : '8px')};
	box-sizing: border-box;
	text-transform: ${(props) => props.textTransform ?? 'capitalize'};
	outline: none;
	white-space: nowrap;
	color: ${(props) => props.theme.colors.selectedTheme.button.dextoroPrimary.color};
    border:${(props) => props.theme.colors.selectedTheme.newBorder};
	transition: all 0.1s ease-in-out;
	filter: brightness(0.9)
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}

	${(props) =>
		props.$variant === 'primary' &&
		css`
			&:hover {
				filter: ${props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)'};
			}
		`}

	${(props) =>
		(props.noOutline || props.$variant === 'flat') &&
		css`
			background: #6966ff;
			&:hover {
				background: #6966ff;
				filter: ${props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)'};
			}
			&::before {
				display: none;
			}
		`}

	${(props) =>
		props.$variant === 'long' &&
		css`
			color: ${(props) =>
				props.theme.colors.selectedTheme.newTheme.button.position.long.active.color};
			background: ${(props) =>
				props.theme.colors.selectedTheme.newTheme.button.position.long.active.background};
			&:hover {
				filter: ${props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)'};
				background: ${(props) =>
					props.theme.colors.selectedTheme.newTheme.button.position.long.active.background};
			}
		`}

	${(props) =>
		props.$variant === 'short' &&
		css`
			color: ${(props) =>
				props.theme.colors.selectedTheme.newTheme.button.position.short.active.color};
			background: ${(props) =>
				props.theme.colors.selectedTheme.newTheme.button.position.short.active.background};
			&:hover {
				filter: ${props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)'};
				background: ${(props) =>
					props.theme.colors.selectedTheme.newTheme.button.position.short.active.background};
			}
		`}
	
	${(props) =>
		(props.$variant === 'yellow' || props.$variant === 'staking-button') &&
		css`
			border: ${props.theme.colors.selectedTheme.border};
			color: ${props.theme.colors.selectedTheme.button.text.primary};
			background: ${props.theme.colors.selectedTheme.button.fillHover};

			box-shadow: none;
			&:hover {
				background: ${props.theme.colors.selectedTheme.button.fillHover};
			}
			&::before {
				display: none;
			}

			${props.$variant === 'staking-button' &&
			css`
				border: ${props.theme.colors.selectedTheme.border};
			`}
		`}

	font-family: ${(props) =>
		props.theme.fonts[props.$mono ? 'mono' : props.$bold ? 'bold' : 'regular']};

	${(props) =>
		props.$mono &&
		css`
			font-feature-settings: 'zero' 1;
		`}

	${(props) =>
		props.$capitalized &&
		css`
			font-variant: all-small-caps;
		`}

	${(props) =>
		props.$variant === 'secondary' &&
		css`
			color: ${props.theme.colors.selectedTheme.button.secondary.text};
		`}

	${(props) =>
		props.$variant === 'danger' &&
		css`
			color: ${props.theme.colors.selectedTheme.red};
		`}

	${(props) => css`
		height: ${sizeMap[props.$size].height}px;
		padding: ${sizeMap[props.$size].paddingVertical}px ${sizeMap[props.$size].paddingHorizontal}px;
		font-size: ${sizeMap[props.$size].fontSize}px;
	`}

	${(props) =>
		props.fullWidth &&
		css`
			width: 100%;
		`};

	${(props) =>
		props.$fontSize &&
		css`
			font-size: ${props.$fontSize}px;
		`}

	&:disabled {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
		background: transparent;
		box-shadow: none;
		text-shadow: none;
		border: ${(props) => props.theme.colors.selectedTheme.border};
		cursor: not-allowed;
		&::before {
			display: none;
		}
	}
`

type ButtonProps = {
	children?: ReactNode
	loading?: boolean
	active?: boolean
	mono?: boolean
	className?: string
	left?: ReactNode
	right?: ReactNode
	size?: 'xsmall' | 'small' | 'medium' | 'large'
	variant?: ButtonVariant
	fullWidth?: boolean
	noOutline?: boolean
	textColor?: 'yellow'
	outlineColor?: 'yellow'
	textTransform?: 'none' | 'uppercase' | 'capitalize' | 'lowercase'
	style?: React.CSSProperties
	disabled?: boolean
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
	isRounded?: boolean
	fontSize?: number
	capitalized?: boolean
	bold?: boolean
}

const Button: FC<ButtonProps> = memo(
	({
		loading,
		children,
		mono,
		left,
		right,
		fontSize,
		active = true,
		noOutline = true,
		size = 'medium',
		variant = 'flat',
		capitalized,
		bold,
		...props
	}) => {
		const currentTheme = useAppSelector(selectCurrentTheme)
		return (
			<BaseButton
				$active={active}
				$mono={mono}
				$size={size}
				$variant={variant}
				$fontSize={fontSize}
				noOutline={noOutline}
				$capitalized={capitalized}
				$bold={bold}
				{...props}
				currentTheme={currentTheme}
			>
				{loading ? (
					<ButtonLoader />
				) : (
					<>
						{left}
						<>{children}</>
						{right}
					</>
				)}
			</BaseButton>
		)
	}
)

export default Button
