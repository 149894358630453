import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import { DialogOverlay } from '@reach/dialog'
import Connector from 'containers/Connector'
import { useWalkthroughContext } from 'contexts/WalkthroughContext'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectShowWalkthrough } from 'state/app/selectors'
import { setShowWalkthrough } from 'state/app/reducer'
import { Body } from 'components/Text'
import { notifyError } from 'components/ErrorNotifier'
import Expand from 'assets/svg/app/expand.svg'
import Checked from 'assets/svg/app/circle-checked.svg'
import media from 'styles/media'
import { zIndex } from 'constants/ui'
import { selectCurrentTheme } from 'state/preferences/selectors'
import ConnectWallet from './sections/ConnectWallet'
import CreateSmartWallet from './sections/CreateSmartWallet'
import DepositFunds from './sections/DepositFunds'
import OneClickTrading from './sections/OneClickTrading'
import TradePreference from './sections/TradePreference'

export enum Step {
	ConnectWallet = 'Connect wallet',
	CreateSmartWallet = 'Create smart wallet',
	DepositFunds = 'Deposit funds',
	OneClickTrading = 'Enable one-click trading',
	TradePreference = 'Set trading preferences',
}

export const BASE_URL = 'https://walkthrough-api-fe3eb33a6928.herokuapp.com/api/newtrader'

export const fetchStatus = async (walletAddress: `0x${string}` | null) => {
	try {
		const response = await axios.get(`${BASE_URL}?walletAddress=${walletAddress?.toLowerCase()}`)
		return response.data
	} catch (error) {
		notifyError(error.message)
	}
}

export default function Walkthrough() {
	const dispatch = useAppDispatch()
	const showWalkthrough = useAppSelector(selectShowWalkthrough)
	const { walletAddress } = Connector.useContainer()
	const { isWalletConnected } = Connector.useContainer()
	const { newTrader, currentStep, setNewTrader, setCurrentStep } = useWalkthroughContext()
	const [open, setOpen] = useState<string | undefined>()
	const [stepCompleted, setStepCompleted] = useState<any>([])
	const [CSW, setCSW] = useState<boolean>()
	const [DF, setDF] = useState<boolean>()
	const [OCT, setOCT] = useState<boolean>()
	const [TP, setTP] = useState<boolean>()
	const currentTheme = useAppSelector(selectCurrentTheme)

	const handleOpen = useCallback((i: any) => setOpen((prev) => (prev === i ? null : i)), [])
	const handleClick = useCallback(() => dispatch(setShowWalkthrough('hide')), [dispatch])
	const handleClose = useCallback(async () => {
		try {
			const response = await axios.delete(
				`${BASE_URL}?walletAddress=${walletAddress?.toLowerCase()}`
			)
			setNewTrader(null)
			return response.data
		} catch (error) {
			notifyError(error.message)
		}
	}, [walletAddress, newTrader, setNewTrader])

	useEffect(() => {
		fetchStatus(walletAddress).then((res) => {
			setNewTrader(res?.walletAddress)
			const remainingStep = res?.steps.find((step: { completed: boolean }) => !step.completed)
			if (remainingStep) {
				setOpen(remainingStep.name)
			}
			const checkStepsComplition = res?.steps?.map((step: { completed: boolean }) => step.completed)
			const filterStepsComplition = checkStepsComplition
				?.filter((t: boolean) => t === true)
				?.concat(checkStepsComplition?.filter((t: boolean) => t === false))
			setStepCompleted(filterStepsComplition)

			const CSW_done = res?.steps?.find(
				(step: { name: Step }) => step.name === Step.CreateSmartWallet
			)
			const DF_done = res?.steps?.find((step: { name: Step }) => step.name === Step.DepositFunds)
			const OCT_done = res?.steps?.find(
				(step: { name: Step }) => step.name === Step.OneClickTrading
			)
			const TP_done = res?.steps?.find((step: { name: Step }) => step.name === Step.TradePreference)
			setCSW(CSW_done?.completed)
			setDF(DF_done?.completed)
			setOCT(OCT_done?.completed)
			setTP(TP_done?.completed)
		})
	}, [walletAddress, newTrader, currentStep, setNewTrader, setCurrentStep])

	if (!walletAddress || newTrader === null || newTrader === undefined || newTrader === '')
		return null

	if (CSW && DF && OCT && TP && showWalkthrough === 'show') {
		return (
			<StyledDialogOverlay>
				<Container>
					<Box>
						<ButtonDiv>
							<StartButton onClick={handleClick}>
								Get started
								<span>
									<ExpandIcon />
								</span>
							</StartButton>
							<StepsDiv>
								<Steps isCompleted />
								<Steps isCompleted={CSW} />
								<Steps isCompleted={DF} />
								<Steps isCompleted={OCT} />
								<Steps isCompleted={TP} />
							</StepsDiv>
						</ButtonDiv>
						<SetupDiv>
							<LargeCircle>
								<StyledChecked width={72} height={72} />
							</LargeCircle>
							<Title style={{ fontSize: '20px', marginTop: '12px' }}>You're all setup!</Title>
							<ParaText>Enjoy the seamless trading experience</ParaText>
							<ActionButton onClick={handleClose}>Close</ActionButton>
						</SetupDiv>
					</Box>
				</Container>
			</StyledDialogOverlay>
		)
	}

	return (
		<>
			{isWalletConnected && showWalkthrough === 'show' && (
				<StyledDialogOverlay>
					<Container>
						<Box>
							<ButtonDiv>
								<StartButton onClick={handleClick}>
									Get started
									<span>
										<ExpandIcon />
									</span>
								</StartButton>
								<StepsDiv>
									<Steps isCompleted />
									{stepCompleted?.map((s: any, i: any) => (
										<Steps key={i} isCompleted={s} />
									))}
								</StepsDiv>
							</ButtonDiv>
							<SurfaceContainer>
								<Surface>
									<ConnectWallet />
									<Option isCompleted={CSW} onClick={() => handleOpen(Step.CreateSmartWallet)}>
										<Circle isCompleted={CSW} isOpen={open === Step.CreateSmartWallet && !CSW}>
											{CSW && <Checked />}
										</Circle>
										<Title isCompleted={CSW}>{Step.CreateSmartWallet}</Title>
										<Space />
										<ExpandIcon $flip={open === Step.CreateSmartWallet && !CSW} />
									</Option>
									{open === Step.CreateSmartWallet && !CSW && <CreateSmartWallet />}
									<Option isCompleted={DF} onClick={() => handleOpen(Step.DepositFunds)}>
										<Circle isCompleted={DF} isOpen={open === Step.DepositFunds && !DF}>
											{DF && <Checked />}
										</Circle>
										<Title isCompleted={DF}>{Step.DepositFunds}</Title>
										<Space />
										<ExpandIcon $flip={open === Step.DepositFunds && !DF} />
									</Option>
									{open === Step.DepositFunds && !DF && <DepositFunds />}
									<Option isCompleted={OCT} onClick={() => handleOpen(Step.OneClickTrading)}>
										<Circle isCompleted={OCT} isOpen={open === Step.OneClickTrading && !OCT}>
											{OCT && <Checked />}
										</Circle>
										<Title isCompleted={OCT}>{Step.OneClickTrading}</Title>
										<Space />
										<ExpandIcon $flip={open === Step.OneClickTrading && !OCT} />
									</Option>
									{open === Step.OneClickTrading && !OCT && <OneClickTrading />}
									<Option isCompleted={TP} onClick={() => handleOpen(Step.TradePreference)}>
										<Circle isCompleted={TP} isOpen={open === Step.TradePreference && !TP}>
											{TP && <Checked />}
										</Circle>
										<Title isCompleted={TP}>{Step.TradePreference}</Title>
										<Space />
										<ExpandIcon $flip={open === Step.TradePreference && !TP} />
									</Option>
									{open === Step.TradePreference && !TP && <TradePreference />}
								</Surface>
							</SurfaceContainer>
							<Warning>
								<StyledBody color="secondary" onClick={handleClose} currentTheme={currentTheme}>
									Don't show me this again
								</StyledBody>
							</Warning>
						</Box>
					</Container>
				</StyledDialogOverlay>
			)}
		</>
	)
}

const StyledDialogOverlay = styled(DialogOverlay)`
	backdrop-filter: blur(5px);
	z-index: ${zIndex.DIALOG_OVERLAY};

	${media.lessThan('md')`
		z-index: ${zIndex.MOBILE_FOOTER};
		overflow: scroll;
		display: flex;
		align-items: flex-end;
		max-width: 100%;
		justify-content: center;
	`}
`

const Container = styled.div`
	position: fixed;
	bottom: 2.5rem;
	min-width: max-content;
	min-height: max-content;
	z-index: 9999;
	${media.lessThan('md')`
		bottom: 1px;
	`}
`

const Box = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	margin-left: 1rem;
	width: 22rem;
	background: ${(props) => props.theme.colors.selectedTheme.gray3};
	border-radius: 1rem;
	font-size: 1rem;
	isolation: isolate;

	${media.lessThan('md')`
		max-width: 100%;
		margin: 0 auto;
	`}
`

const ButtonDiv = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	row-gap: 0.75rem;
`

const StartButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	cursor: pointer;
	white-space: nowrap;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
`

const ExpandIcon = styled(Expand)`
	${(props) => css`
		transform: ${props.$flip ? 'rotate(180deg)' : 'none'};
		color: ${props.theme.colors.selectedTheme.gray};
	`}
`

const StepsDiv = styled.div`
	display: flex;
	column-gap: 0.25rem;
`

const Steps = styled.div<{ isCompleted?: boolean | undefined }>`
	flex: 1 1 0%;
	height: 0.5rem;
	border-radius: 0.5rem;
	background: ${(props) => props.theme.colors.selectedTheme.walkthrough.step.color};
	opacity: ${(props) => (props.isCompleted ? 1 : 0.1)};
`

const SurfaceContainer = styled.div`
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	border-radius: 0.5rem;
	background: ${(props) => props.theme.colors.selectedTheme.segmentedControl.card.background};
`

const Surface = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
`

const Title = styled.span<{ isCompleted?: boolean | undefined }>`
	font-size: 18px;
	text-decoration-line: ${(props) => (props.isCompleted ? 'line-through' : 'none')};
	color: ${(props) =>
		props.isCompleted
			? props.theme.colors.selectedTheme.text.body
			: props.theme.colors.selectedTheme.text.value};
`

const SetupDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 16px 0;
`

const LargeCircle = styled.circle`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 72px;
	height: 72px;
	padding: 10px;
	background-color: ${(props) => props.theme.colors.selectedTheme.walkthrough.complete.background};
	border-radius: 9999px;
	aspect-ratio: 1/1;
	border: ${(props) => props.theme.colors.selectedTheme.walkthrough.border};
`

const Circle = styled.circle<{ isOpen?: boolean | undefined; isCompleted?: boolean | undefined }>`
	display: flex;
	width: 21px;
	height: 21px;
	background-color: rgba(110, 95, 180, 0.2);
	border-radius: 9999px;
	aspect-ratio: 1/1;
	border: ${(props) =>
		props.isOpen
			? props.theme.colors.selectedTheme.walkthrough.border
			: props.theme.colors.selectedTheme.walkthrough.outlineBorder};
`

const Option = styled.div<{ isCompleted: boolean | undefined }>`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	white-space: nowrap;
	padding-top: 0.5rem;
	cursor: pointer;

	${(props) =>
		props.isCompleted &&
		css`
			cursor: not-allowed;
			:disabled {
				cursor: default;
			}
		`}
`

const Space = styled.div`
	flex: 1 1 0%;
`

const StyledChecked = styled(Checked)`
	fill: #ffffff;
`

const ParaText = styled.p`
	font-size: 14px;
	text-align: center;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
`

const ActionButton = styled.div`
	justify-content: center;
	align-items: center;
	text-align: center;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	border-radius: 0.25rem;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	padding-left: 1rem;
	padding-right: 1rem;
	width: 85%;
	cursor: pointer;
	&:hover {
		outline-color: ${(props) => props.theme.colors.selectedTheme.walkthrough.outline};
		outline-style: solid;
		outline-width: thin;
	}
`

const Warning = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	padding: 0.5rem;
	white-space: nowrap;
`

const StyledBody = styled(Body)<{ currentTheme: string }>`
	font-size: 14px;
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.5)' : 'brightness(0.9)')};
	}
`
