export const durationOptions = [
	{
		label: '1H',
		value: 1,
	},
	{
		label: '6H',
		value: 6,
	},
	{
		label: '12H',
		value: 12,
	},
	{
		label: '1D',
		value: 24,
	},
	{
		label: '1W',
		value: 168,
	},
	{
		label: '1M',
		value: 720,
	},
]

export const txFees = [
	{
		count: 15,
		eth: 0.0075,
	},
	{
		count: 30,
		eth: 0.015,
	},
	{
		count: 50,
		eth: 0.025,
	},
	{
		count: 100,
		eth: 0.05,
	},
]
