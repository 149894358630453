import { useState, useEffect, memo } from 'react'
import styled, { css } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/ReactToastify.css'
import { createPortal } from 'react-dom'
import media from 'styles/media'

import { FlexDivCentered } from 'components/layout/flex'
import CloseIcon from 'assets/svg/app/close.svg'
import Link from 'next/link'

const NotificationContainer = memo(() => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
		return () => setMounted(false)
	}, [])

	return mounted
		? createPortal(
				<StyledToastContainer
					icon={() => null}
					enableMultiContainer
					containerId="notifications"
					autoClose={10000}
					position="top-right"
					newestOnTop
					hideProgressBar
					pauseOnFocusLoss
					pauseOnHover
					closeOnClick={false}
					closeButton={({ closeToast }: any) => <CloseButton closeToast={closeToast} />}
				/>,
				document.body
		  )
		: null
})

const StyledToastContainer = styled(ToastContainer)`
	.Toastify__toast-container {
		display: grid;
		width: 272px;
		top: 48px;
		right: 0;
		overflow-y: auto;
		padding: 16px 16px 0 0;
		max-height: calc(100vh - 48px);
	}
	.Toastify__toast {
		padding: 0;
		width: 272px;
		border-radius: 8px;
		margin-bottom: 16px;
		cursor: pointer;
		overflow: initial;
		overflow: visible;
		position: relative;
		font-feature-settings: 'zero' 0;
		font-family: ${(props) => props.theme.fonts.regular};
		border: ${(props) => props.theme.colors.selectedTheme.border};
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		background: ${(props) => props.theme.colors.selectedTheme.notification.background};

		.notification-close-button {
			display: none !important;
			${media.lessThan('lg')`
      			display: flex !important;
			`}
		}
		&:hover {
			.notification-close-button {
				display: flex !important;
			}
		}

		${media.lessThan('md')`
			width: 90%;
			margin: 12px auto;
		`}
	}
	.Toastify__toast-body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}
	.Toastify__progress-bar {
		background: ${(props) => props.theme.colors.gold};
		box-shadow: 0px 0px 15px rgb(228 179 120 / 60%);
	}
`

export default NotificationContainer

interface CloseButtonProps {
	closeToast: () => void
}

export const CloseButton: React.FC<CloseButtonProps> = ({ closeToast }) => (
	<CloseIconDiv className="notification-close-button" onClick={closeToast}>
		<CloseIcon />
	</CloseIconDiv>
)

const CloseIconDiv = styled.button`
	display: none !important;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
	border: none;
	z-index: 100;
	right: -10px;
	top: -10px;
	width: 24px;
	height: 24px;
	background: ${(props) => props.theme.colors.selectedTheme.notification.button.background};

	svg {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
		}
		overflow: visible;
		width: 12px;
		height: 12px;
	}
`

export const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 14px;
	cursor: pointer;
	position: relative;
`

export const NotificationContent = styled.div`
	display: grid;
	position: relative;
	gap: 8px;
`

export const NotificationTitle = styled.div`
	display: grid;
	grid-auto-flow: column;
	justify-content: start;
	align-items: center;
	line-height: 20px;
	font-size: 15px;
	gap: 8px;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`

export const NotificationIconDiv = styled(FlexDivCentered)<{
	type?: 'success' | 'error' | 'pending'
}>`
	justify-content: center;
	border-radius: 50%;
	height: 20px;
	width: 20px;

	svg {
		height: 20px;
		width: 20px;
		fill: ${(props) =>
			props.type === 'error'
				? props.theme.colors.selectedTheme.red
				: props.type === 'success'
				? 'transparent'
				: props.theme.colors.selectedTheme.primary};
		${(props) =>
			props.type === 'success' &&
			css`
				rect {
					stroke: ${(props) => props.theme.colors.selectedTheme.green};
				}
				path {
					stroke: ${(props) => props.theme.colors.selectedTheme.green};
				}
			`}
	}
`

export const Paragraph = styled.span<{ underline?: boolean }>`
	font-size: 13px;
	line-height: 16px;
	justify-content: flex-start;
	background-color: transparent;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`

export const Stats = styled.span`
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`

type NotificationDetailsProps = {
	text: string
	underline?: boolean
	linkText?: string
	path?: string
}

export const NotificationDetails: React.FC<NotificationDetailsProps> = ({
	text,
	underline,
	linkText,
	path,
}) => {
	// Use regex to find the dollar amount
	const parts = text.split(/(\$[\d,.]+)/) // Split on dollar amounts

	return (
		<Paragraph underline={underline}>
			{parts.map((part, index) =>
				// Check if the part is a dollar amount
				/^\$[\d,.]+$/.test(part) ? <Stats key={index}>{part}</Stats> : part
			)}
			&nbsp;
			{linkText && path && (
				<StyledLink href={path} target="_blank">
					{linkText}
				</StyledLink>
			)}
		</Paragraph>
	)
}

const StyledLink = styled(Link)`
	cursor: pointer;
	text-decoration: underline;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
	&:hover {
		text-decoration: underline;
	}
`
