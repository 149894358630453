import React, { FC, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import CheckIcon from 'assets/svg/app/checked.svg'
import CopyIcon from 'assets/svg/app/copy.svg'
import { Body } from 'components/Text'

type Props = {
	text: string
	label?: string
}

const CopyToClipboardButton: FC<Props> = ({ text, label }) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const [copied, setCopied] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setCopied(false)
		}, 1000)
		return () => clearTimeout(timer)
	}, [copied])

	const copyToClipboard = () => {
		if (textareaRef.current) {
			// Copy text to clipboard
			textareaRef.current.select()
			document.execCommand('copy')
			setCopied(true)
		}
	}

	return (
		<>
			<textarea
				ref={textareaRef}
				defaultValue={text}
				style={{ position: 'absolute', left: '-9999px' }}
			/>
			<Button onClick={copyToClipboard}>
				{copied ? (
					label ? (
						<>
							<CheckIcon /> <Body>Copied!</Body>
						</>
					) : (
						<CheckIcon />
					)
				) : label ? (
					<>
						<CopyIcon /> <Body>{label}</Body>
					</>
				) : (
					<CopyIcon />
				)}
			</Button>
		</>
	)
}

const Button = styled.button`
	background: transparent;
	border: none;
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;

	svg {
		width: 13px;
		height: 13px;
	}

	p {
		white-space: nowrap;
		font-size: 15px;
	}
`

export default CopyToClipboardButton
