import { ReferralsData, ClaimHistories } from '@dextoroprotocol/sdk/types'
import { createSlice } from '@reduxjs/toolkit'

import {
	fetchReferralsData,
	fetchReferralsTradingData,
	fetchAffiliateUSDTEarned,
	fetchClaimHistories,
} from './actions'
import { AffiliateReward, ReferralsTradingData } from './types'

export const ZERO_REFERRAL_DATA = {
	referralCodes: [],
	traderToReferralCodes: [],
	registeredReferrals: [],
	feesPaidByCodes: [],
	traderReferralCodeUpdated: false,
}

export const ZERO_AFFILIATE_REWARD_DATA = {
	affiliateTotalRewards: '0',
	affiliateClaimableRewards: [],
}

type ReferralsState = ReferralsData & ReferralsTradingData & AffiliateReward & ClaimHistories

const REFERRAL_INITIAL_STATE: ReferralsState = {
	...ZERO_REFERRAL_DATA,
	tradingData: [],
	...ZERO_AFFILIATE_REWARD_DATA,
	claimHistories: [],
}

const referralsSlice = createSlice({
	name: 'referral',
	initialState: REFERRAL_INITIAL_STATE,
	reducers: {
		setTraderReferralCodeUpdated: (state, action) => {
			state.traderReferralCodeUpdated = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchReferralsData.fulfilled, (state, action) => {
			state.referralCodes = action.payload.referralCodes
			state.traderToReferralCodes = action.payload.traderToReferralCodes
			state.registeredReferrals = action.payload.registeredReferrals
			state.feesPaidByCodes = action.payload.feesPaidByCodes
		})
		builder.addCase(fetchReferralsTradingData.fulfilled, (state, action) => {
			state.tradingData = action.payload.tradingData
		})
		builder.addCase(fetchAffiliateUSDTEarned.fulfilled, (state, action) => {
			state.affiliateTotalRewards = action.payload.affiliateTotalRewards
			state.affiliateClaimableRewards = action.payload.affiliateClaimableRewards
		})
		builder.addCase(fetchClaimHistories.fulfilled, (state, action) => {
			state.claimHistories = action.payload.claimHistories
		})
	},
})

export default referralsSlice.reducer
export const { setTraderReferralCodeUpdated } = referralsSlice.actions
